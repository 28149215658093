import Modal from "react-bootstrap/Modal";
import {IoIosClose} from "react-icons/io";
import Select from "react-select";
import {
    createOptionListForSelectTag,
    handleApiResponse,
    isUnauthorized
} from "../../../helpers/utils";
import {hasPermission, Permissions} from "../../../helpers/accessControl";
import {TailSpinLoader} from "../../layout/Loader";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {assignedReflectionsToSubOrg, getPublishSurveys} from "../../../reduxStore/surveySlices/surveySlices";

const AssignReflectionModal = ({show, setShow, teamId, callback}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [selectedReflectionsToAssign, setSelectedReflectionsToAssign] = useState([])

    const getPublishedSurveysData = useSelector(state => state.survey.getPublishSurveysReducer)
    const assignedReflectionsData = useSelector(state => state.survey.assignedReflectionsToSubOrgReducer)

    useEffect(() => {
        if (hasPermission([Permissions.REFLECTION_READ], true)) {
            dispatch(getPublishSurveys()).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }
    }, []);

    const handleClose = () => {
        setShow(false);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(assignedReflectionsToSubOrg({
            surveyIds: selectedReflectionsToAssign?.map(cur => cur?.value),
            subOrgId: teamId
        })).then(res => {
            if (isUnauthorized(res)) {
                navigate("/")
            }
            const onSuccess = () => {
                callback()
                handleClose()
            }
            handleApiResponse(res, onSuccess)
        })
    }

    return (
        <>
            <Modal className='mt-4' show={show} centered>
                <Modal.Header className={"d-flex"}>
                    <Modal.Title className='add_member_heading mediumFontSize'>Assign Reflection</Modal.Title>
                    <IoIosClose className={"close_btn"} onClick={handleClose} size={24}/>
                </Modal.Header>
                <form onSubmit={handleSubmit}>
                    <Modal.Body className="modal_outer">
                        <div className="reset_form">
                            <div className={"form-group mt-1"}>
                                <label className={"userInfo_label mb-2"}
                                       htmlFor="exampleFormControlSelect1"> Select Reflections </label>
                                <Select
                                    className="members-filter-role react-select-container"
                                    classNamePrefix="react-select"
                                    isDisabled={getPublishedSurveysData?.loading}
                                    isMulti
                                    isClearable={true}
                                    value={selectedReflectionsToAssign}
                                    options={createOptionListForSelectTag(getPublishedSurveysData?.data?.filter(cur=>!cur.assignTo.includes(teamId)), "name", "surveyId")}
                                    onChange={(selectedValue) => {
                                        setSelectedReflectionsToAssign(selectedValue)
                                    }}
                                    placeholder={"Select..."}/>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="modal_footer">
                        <div className={"add-member-modal-btns"}>
                            <button
                                style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                type="submit"
                                disabled={selectedReflectionsToAssign?.length === 0}
                                className="ms-2 modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize ">Assign
                                {
                                    assignedReflectionsData?.loading && <TailSpinLoader/>
                                }
                            </button>
                            <button className="modal_cancel_btn cmn_modal_btn mediumFontSize"
                                    disabled={assignedReflectionsData?.loading}
                                    onClick={handleClose}>Cancel
                            </button>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}
export default AssignReflectionModal