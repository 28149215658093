import {Card} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    attemptSurveyComplete,
    attemptSurveySaveAsDraft, getPreviousSurveyAttemptBySurveyIdAndSurveyeeId,
    getSurveyAttemptBySurveyIdAndSurveyeeId,
    getSurveyById, updateSurveyAttemptById
} from "../../reduxStore/surveySlices/surveySlices";
import {
    compareAndSaveAttemptedQuestionData,
    groupQuestionsBySegmentIndex,
    isUnauthorized, showToast,
    trim
} from "../../helpers/utils";
import {Loader, TailSpinLoader} from "../layout/Loader";
import "../members/AnswerSurvey.css"
import ErrorPage from "../errorPage/ErrorPage";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {hasPermission, Permissions} from "../../helpers/accessControl";
import {
    AccessDenied,
    AccessDeniedMsg,
    AnswerRequiredQuestions, AttemptBeforeSavingToDraft,
    DisabledSaveAsDraftAttemptReflectionBTNText,
    DisabledSubmitAttemptReflectionBTNText,
    InvalidAnswerForNumberQuestion,
    QuestionType,
    Survey, SurveyAttemptStatus
} from "../../helpers/constants";
import {toast} from "react-toastify";

const AnswerSurvey = () => {

    const isMenteeAttempting = window.location.pathname.startsWith("/mentee/attempt")
    const isTeamAttempting = window.location.pathname.startsWith("/teams")
    const {surveyId} = useParams();
    const {memberId} = useParams();
    const {teamId} = useParams();

    const dispatch = useDispatch();
    const [surveyAlreadyAttemptedData, setSurveyAlreadyAttemptedData] = useState({})
    const [isSurveyAlreadyAttempted, setIsSurveyAlreadyAttempted] = useState(false)
    const [segmentType, setSegmentType] = useState(null)
    const [refCollection, setRefCollection] = useState([])
    const [error, setError] = useState({hasError: false, value: "", description: "", detailDesc: ""});
    const [surveyData, setSurveyData] = useState({})
    const [answerQuestionDetail, setAnswerQuestionDetail] = useState({});
    const [surveyPreviousData, setSurveyPreviousData] = useState({})
    const navigate = useNavigate();
    const survey = useSelector(state => state.survey)

    const [disableSaveAsDraftButton, setDisableSaveAsDraftButton] = useState(true)
    const [disableSubmitButton, setDisableSubmitButton] = useState(true)
    const [answerSurveyData, setAnswerSurveyData] = useState({
        id: "",
        surveyName: "",
        teamId: "",
        surveyId: "", surveyeeId: "", attemptQuestions: [{
            questionId: "", questionType: "", selectedOption: {optionId: "", optionText: "", optionValue: ""},
            answerText: "",
            answerNumber: null
        }]
    })


    const checkSurveyAnswersValidity = (questions, attemptQuestions) => {
        let message;
        // Check if given answer is between given range
        const attemptedNumberQuestions = attemptQuestions.filter(attemptQuestion => attemptQuestion?.questionType === QuestionType.NUMBER)
        outerLoop: for (let index = 0; index < questions.length; index++) {
            for (const attemptQuestion of attemptedNumberQuestions) {
                if (questions[index].type === QuestionType.NUMBER && questions[index].questionId === attemptQuestion.questionId && attemptQuestion?.answerNumber !== null && !(attemptQuestion?.answerNumber >= questions[index].number.min && attemptQuestion?.answerNumber <= questions[index].number.max)) {
                    message = InvalidAnswerForNumberQuestion + ` ${index + 1}`;
                    break outerLoop; // Break both the outer and inner loop.
                }
            }
        }
        return message
    }

    useEffect(() => {
        if (segmentType !== null && segmentType === "TABULAR") {
            setRefCollection(groupQuestionsBySegmentIndex(surveyData?.segments, surveyData?.questions))
        }
    }, [segmentType])

    const handleSaveAsDraft = () => {
        let message = checkSurveyAnswersValidity(surveyData.questions, answerSurveyData.attemptQuestions)
        if (message) {
            showToast(message, "error")
        } else if (!disableSaveAsDraftButton) {
            const updatedAnswerSurveyData = eliminateNotAttemptedQuestions(answerSurveyData)
            if (updatedAnswerSurveyData.attemptQuestions.length === 0) {
                setDisableSaveAsDraftButton(true)
                setDisableSubmitButton(true)
                showToast(AttemptBeforeSavingToDraft, "error")
            } else if (updatedAnswerSurveyData.attemptQuestions.length > 0) {
                dispatch(isSurveyAlreadyAttempted ? updateSurveyAttemptById({
                    attemptSurveyId: surveyAlreadyAttemptedData.id,
                    updatedAnswerSurveyData: {
                        ...updatedAnswerSurveyData,
                        surveyId: surveyId,
                        surveyeeId: memberId,
                        surveyName: surveyData.name,
                        surveyAttemptStatus: survey.getSurveyAttemptBySurveyIdAndSurveyeeIdDataReducer?.data?.surveyAttemptStatus !== undefined ? survey.getSurveyAttemptBySurveyIdAndSurveyeeIdDataReducer?.data?.surveyAttemptStatus : SurveyAttemptStatus.DRAFT,
                        ...(isMenteeAttempting ? {surveyorId: survey.getSurveyAttemptBySurveyIdAndSurveyeeIdDataReducer?.data?.surveyorId} : {})
                    }
                }) : attemptSurveySaveAsDraft({
                    ...updatedAnswerSurveyData,
                    surveyId: surveyId,
                    surveyeeId: memberId,
                    teamId: teamId,
                    surveyAttemptStatus: survey.getSurveyAttemptBySurveyIdAndSurveyeeIdDataReducer?.data?.surveyAttemptStatus !== undefined ? survey.getSurveyAttemptBySurveyIdAndSurveyeeIdDataReducer?.data?.surveyAttemptStatus : SurveyAttemptStatus.DRAFT,
                    ...(isMenteeAttempting ? {surveyorId: survey.getSurveyAttemptBySurveyIdAndSurveyeeIdDataReducer?.data?.surveyorId} : {})
                })).then(res => {
                    if (isUnauthorized(res)) {
                        navigate("/")
                    } else if (res.meta.requestStatus === "fulfilled") {
                        navigate(isMenteeAttempting ? "/mentee/reflections" : isTeamAttempting ? `/teams/${teamId}` : `/members/${memberId}`)
                    }
                })
            }
        }

    }

    const handleSubmitAttemptSurvey = () => {

        let message = checkSurveyAnswersValidity(surveyData.questions, answerSurveyData.attemptQuestions)
        if (message) {
            showToast(message, "error")
        } else if (!disableSubmitButton) {
            const requiredQuestions = surveyData.questions.filter(question => question.isRequired);
            const areAllRequiredQuestionsAnswered = requiredQuestions.every(requiredQuestion => answerSurveyData.attemptQuestions.some(attemptQuestion => attemptQuestion?.questionId === requiredQuestion.questionId && (requiredQuestion.type === QuestionType.TEXT ? attemptQuestion?.answerText.trim() !== "" : true) && (requiredQuestion.type === QuestionType.NUMBER ? attemptQuestion?.answerNumber !== null : true)));
            if (!areAllRequiredQuestionsAnswered) {
                showToast(AnswerRequiredQuestions, "error")
            } else {
                const updatedAnswerSurveyData = eliminateNotAttemptedQuestions(answerSurveyData)
                dispatch(attemptSurveyComplete({
                    ...updatedAnswerSurveyData,
                    surveyName: surveyData.name,
                    id: surveyAlreadyAttemptedData.id,
                    surveyId: surveyId,
                    surveyeeId: memberId,
                    teamId: teamId,
                    ...(isMenteeAttempting ? {surveyorId: survey.getSurveyAttemptBySurveyIdAndSurveyeeIdDataReducer?.data?.surveyorId} : {})
                })).then(res => {
                    if (isUnauthorized(res)) {
                        navigate("/")
                    } else if (res.meta.requestStatus === "fulfilled") {
                        navigate(isMenteeAttempting ? "/mentee/reflections" : isTeamAttempting ? `/teams/${teamId}` : `/members/${memberId}`)
                    }
                })
            }
        }


    }

    const eliminateNotAttemptedQuestions = (answerSurveyData = {}) => {
        const updatedAttemptQuestions = answerSurveyData.attemptQuestions.filter(attemptQuestion =>
            (attemptQuestion !== undefined && attemptQuestion !== null) && attemptQuestion.questionId.trim() !== "" && (attemptQuestion.questionType === QuestionType.TEXT ? attemptQuestion?.answerText.trim() !== "" : true) && (attemptQuestion.questionType === QuestionType.NUMBER ? attemptQuestion?.answerNumber !== null && typeof attemptQuestion?.answerNumber === 'number' : true))
        return {...answerSurveyData, attemptQuestions: updatedAttemptQuestions};
    }

    const handleAttemptQuestions = (index, question, answer) => {
        let attemptQuestions = {
            questionId: question.questionId,
            questionType: question.type,
            selectedOption: question.type === QuestionType.OPTION ? {
                optionId: answer.optionId, optionText: answer.optionText, optionValue: answer.optionValue
            } : null,
            answerText: question.type === QuestionType.TEXT ? answer : null,
            answerNumber: question.type === QuestionType.NUMBER ? answer === "" ? null : parseInt(answer) : null
        }
        const updatedAttemptQuestions = [...answerSurveyData.attemptQuestions];
        updatedAttemptQuestions[index] = attemptQuestions;

        setAnswerSurveyData({
            ...answerSurveyData, attemptQuestions: updatedAttemptQuestions,
        });
    }

    useEffect(() => {
        if (hasPermission([Permissions.REFLECTION_READ], true)) {
            dispatch(getSurveyById(surveyId)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                if (res.meta.requestStatus === "fulfilled") {
                    setSurveyData(res.payload.data)
                    setSegmentType(res.payload.data?.segmentType)
                } else if (res.meta.requestStatus === "rejected") {
                    setError({
                        hasError: true,
                        value: res.payload.errorCode.value,
                        description: res.payload.errorCode.description,
                        detailDesc: res.payload.detailDesc
                    })
                }
            })
        }

    }, [surveyId, memberId])

    useEffect(() => {
        if (!isMenteeAttempting) {
            dispatch(getPreviousSurveyAttemptBySurveyIdAndSurveyeeId({
                surveyId: surveyId,
                surveyeeId: memberId
            })).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                if (res.meta.requestStatus === "fulfilled") {
                    if (res.payload.data === undefined) {
                        setSurveyPreviousData({})
                        setAnswerQuestionDetail({})
                    } else {
                        setSurveyPreviousData(res.payload.data)
                    }


                }
            })
        }
    }, [surveyId, memberId])

    useEffect(() => {
        if (Object.keys(surveyData).length > 0 && surveyPreviousData !== undefined && Object.keys(surveyPreviousData).length > 0) {

            let data = compareAndSaveAttemptedQuestionData(surveyPreviousData.attemptQuestions, surveyData.questions)
            setAnswerQuestionDetail(data)
        }

    }, [surveyData, surveyPreviousData])

    useEffect(() => {
        if (hasPermission([Permissions.ATTEMPT_REFLECTION_READ]) && Object.keys(surveyData).length > 0) {
            dispatch(getSurveyAttemptBySurveyIdAndSurveyeeId({surveyId: surveyId, surveyeeId: memberId})).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                if (res.meta.requestStatus === "fulfilled" && res.payload.errorMessage === "NOT_FOUND") {
                    setSurveyAlreadyAttemptedData({})
                    setIsSurveyAlreadyAttempted(false)
                    setDisableSubmitButton(true)
                    setAnswerSurveyData({
                        id: "",
                        surveyName: "",
                        surveyId: surveyId, surveyeeId: memberId, attemptQuestions: [{
                            questionId: "",
                            questionType: "",
                            selectedOption: {optionId: "", optionText: "", optionValue: ""},
                            answerText: ""
                        }]
                    })
                }
                if (res.meta.requestStatus === "fulfilled" && res.payload.data && (res.payload.data.surveyAttemptStatus === Survey.SURVEY_STATUS_DRAFT || res.payload.data.surveyAttemptStatus === SurveyAttemptStatus.ASSIGNED)) {
                    setIsSurveyAlreadyAttempted(true)
                    setDisableSubmitButton(false)
                    setSurveyAlreadyAttemptedData(res.payload.data)
                    const updatedAttemptQuestions = compareAndAddToAnswers(surveyData.questions, res.payload.data.attemptQuestions)
                    setAnswerSurveyData({...answerSurveyData, attemptQuestions: updatedAttemptQuestions})
                } else if (res.meta.requestStatus === "rejected") {
                    setError({
                        hasError: true,
                        value: res.payload.errorCode.value,
                        description: res.payload.errorCode.description,
                        detailDesc: res.payload.detailDesc
                    })
                }
            })
        }
    }, [surveyId, memberId, surveyData])

    const compareAndAddToAnswers = (questions, attemptedQuestions) => {
        let updatedData = [];
        attemptedQuestions.forEach((attempted) => {
            for (let i = 0; i < questions.length; i++) {
                if (questions[i].questionId === attempted.questionId) {
                    updatedData[i] = attempted
                    break;
                }
            }

        });
        return updatedData

    };

    return !hasPermission([Permissions.REFLECTION_READ, Permissions.ATTEMPT_REFLECTION_READ, Permissions.ATTEMPT_REFLECTION_CREATE], true) ?
        <ErrorPage value={403} description={AccessDenied}
                   detailDesc={AccessDeniedMsg}/> : (survey?.getSurveyAttemptBySurveyIdAndSurveyeeIdDataReducer?.loading || survey?.getSurveyByIdDataReducer?.loading || Object.keys(surveyData).length === 0) ? ((error.hasError) ?
                <ErrorPage value={error.value} detailDesc={error.detailDesc} description={error.description}/> :
                <Loader/>) :

            (<div className="row main_content box_design1">
                <div className="member-detail-buttons mt-2 mb-3">
                    <button
                        style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                        onClick={() => {
                            navigate(-1)
                        }} className="modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize ">
                        <i className="fa fa-solid fa-arrow-left"/>&nbsp;&nbsp; Go Back
                    </button>
                    <div>

                        <button
                            style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                            data-tooltip-id="SaveAsDraftButton"
                            data-tooltip-content={disableSaveAsDraftButton ? DisabledSaveAsDraftAttemptReflectionBTNText : ""}
                            onClick={handleSaveAsDraft}
                            className={"modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize " + (disableSaveAsDraftButton ? "disable-btn" : "")}
                            disabled={survey?.attemptSurveyCompleteDataReducer?.loading || survey?.attemptSurveySaveAsDraftDataReducer?.loading || survey?.updateSurveyAttemptByIdDataReducer?.loading}>Save
                            as Draft
                            {(survey?.attemptSurveySaveAsDraftDataReducer?.loading || survey?.updateSurveyAttemptByIdDataReducer?.loading) &&
                                <TailSpinLoader/>}
                        </button>
                        <ReactTooltip className={"ToolTip"} place={"bottom"} id="SaveAsDraftButton"/>
                        <button
                            style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                            data-tooltip-id="SubmitReflectionButton"
                            data-tooltip-content={disableSubmitButton ? DisabledSubmitAttemptReflectionBTNText : ""}
                            disabled={survey?.attemptSurveyCompleteDataReducer?.loading || survey?.attemptSurveySaveAsDraftDataReducer?.loading || survey?.updateSurveyAttemptByIdDataReducer?.loading}
                            onClick={handleSubmitAttemptSurvey}
                            className={"modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize margin-left " + (disableSubmitButton ? "disable-btn" : "")}>
                            Submit
                            {survey?.attemptSurveyCompleteDataReducer?.loading && <TailSpinLoader/>}

                        </button>
                        <ReactTooltip className={"ToolTip"} place={"bottom"} id="SubmitReflectionButton"/>
                    </div>

                </div>
                <div className={"p-0"}>


                    <div className={"options-list-heading mt-2"}>
                        <div className={"row m-0"}>
                            <div
                                className={answerQuestionDetail !== undefined && Object.keys(answerQuestionDetail).length > 0 ? "col-xl-6 p-0" : "col-xl-12 p-0"}>
                                <div className={"answer-survey-heading "}
                                     title={surveyData.name}>{trim(surveyData.name, 50)}
                                </div>
                            </div>
                            {answerQuestionDetail !== undefined && Object.keys(answerQuestionDetail).length > 0 &&

                                <>
                                    <div className={"col-xl-6 p-0"}>
                                        <div style={{marginLeft: "5px"}} className={"answer-survey-heading "}
                                        >Previous Attempt
                                        </div>
                                    </div>
                                </>

                            }

                        </div>

                        <ul className="inner_item Member_Wrapper">

                            <div className={"row previousResult m-0"}>

                                {segmentType !== "TABULAR" ?

                                    <>
                                        <div
                                            className={answerQuestionDetail !== undefined && Object.keys(answerQuestionDetail).length > 0 ? "col-xl-6 " : "col-xl-12"}>

                                            {surveyData.questions.map((question, index) => {
                                                return (
                                                    <>
                                                        <li key={index}>
                                                            <Card className='answer-survey-question-card question-list'>
                                                                <Card.Body className="answer_survey_body_content">
                                                                    {question.isRequired && <div
                                                                        className={"required-text-outer text-center"}>Required</div>}
                                                                    <div className="question-container">
                                                                        <div
                                                                            className={"question-numbering"}>{index + 1 + ". "}</div>
                                                                        <div
                                                                            className={"question "}>{question.questionText}</div>

                                                                    </div>
                                                                    {
                                                                        question.type === QuestionType.NUMBER &&
                                                                        <div className={"number-answer-outer mt-2"}>
                                                                            <input type={"number"}
                                                                                   className={"form-control number-answer-input"}
                                                                                   min={question.number.min}
                                                                                   max={question.number.max}
                                                                                   onWheel={(e) => {
                                                                                       document.activeElement.blur();
                                                                                   }}
                                                                                   onKeyDown={(e) => {
                                                                                       if (e.key === 'e' || e.key === 'E' || e.key === '.' || e.key === "-") {
                                                                                           e.preventDefault();
                                                                                       }
                                                                                   }}
                                                                                   value={answerSurveyData.attemptQuestions[index]?.answerNumber}
                                                                                   placeholder={"Answer here..."}
                                                                                   onChange={(e) => {
                                                                                       if (disableSaveAsDraftButton) {
                                                                                           setDisableSaveAsDraftButton(false)
                                                                                       }
                                                                                       if (disableSubmitButton) {
                                                                                           setDisableSubmitButton(false)
                                                                                       }
                                                                                       handleAttemptQuestions(index, question, e.target.value)

                                                                                   }}/>
                                                                            <div
                                                                                className={"number-range ms-3"}>{"Rate between " +question.number.min + " to " + question.number.max}</div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        question.type === QuestionType.TEXT &&
                                                                        <div className={"text-response-answer-outer"}>
                                            <textarea value={answerSurveyData.attemptQuestions[index]?.answerText}
                                                      className={"text-response-answer w-100"} onChange={(e) => {
                                                if (disableSaveAsDraftButton) {
                                                    setDisableSaveAsDraftButton(false)
                                                }
                                                if (disableSubmitButton) {
                                                    setDisableSubmitButton(false)
                                                }
                                                handleAttemptQuestions(index, question, e.target.value)
                                            }} placeholder={"Type answer here..."}/>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        question.type === QuestionType.OPTION &&
                                                                        <div className={"options-list-container mt-2"}>
                                                                            {question.options.map((option, optionIndex) => {
                                                                                return (<div key={optionIndex}
                                                                                             className={"attempt-option-select-container mt-2 "}>
                                                                                    <div
                                                                                        className={"option-text-container me-0"}>
                                                                                        <div
                                                                                            className={"answer-question-option-heading "}>
                                                                                            {
                                                                                                isSurveyAlreadyAttempted === false ?
                                                                                                    <input
                                                                                                        type={"radio"}
                                                                                                        onChange={() => {
                                                                                                            if (disableSaveAsDraftButton) {
                                                                                                                setDisableSaveAsDraftButton(false)
                                                                                                            }
                                                                                                            if (disableSubmitButton) {
                                                                                                                setDisableSubmitButton(false)
                                                                                                            }
                                                                                                            handleAttemptQuestions(index, question, option)
                                                                                                        }}
                                                                                                        name={"answer" + (index)}/> :
                                                                                                    <input
                                                                                                        type={"radio"}
                                                                                                        checked={answerSurveyData.attemptQuestions[index] ? option.optionId === answerSurveyData.attemptQuestions[index].selectedOption.optionId : false}
                                                                                                        onChange={() => {
                                                                                                            if (disableSaveAsDraftButton) {
                                                                                                                setDisableSaveAsDraftButton(false)
                                                                                                            }
                                                                                                            if (disableSubmitButton) {
                                                                                                                setDisableSubmitButton(false)
                                                                                                            }
                                                                                                            handleAttemptQuestions(index, question, option)
                                                                                                        }}
                                                                                                        name={"answer" + (index)}/>
                                                                                            }

                                                                                            <div
                                                                                                className={"options"}>{option.optionText}</div>
                                                                                        </div>

                                                                                    </div>


                                                                                </div>);
                                                                            })}
                                                                        </div>
                                                                    }

                                                                </Card.Body>
                                                            </Card>
                                                        </li>
                                                    </>
                                                )


                                            })}
                                        </div>

                                    </>
                                    :
                                    <>

                                        <div
                                            className={answerQuestionDetail !== undefined && Object.keys(answerQuestionDetail).length > 0 ? "col-xl-6 " : "col-xl-12"}>

                                            {Object.keys(refCollection).length > 0 && Array.isArray(refCollection) && refCollection?.map((ref, Index) => {
                                                return (
                                                    <>
                                                        <div className={"section-dev mediumFontSize"}>
                                                            <h5>{ref.segmentName || "Section " + (Index + 1)}</h5>

                                                            {surveyData.questions?.map((question, index) => {
                                                                if (question.segmentIndex === Index) {
                                                                    return (
                                                                        <>
                                                                            <li key={index}>
                                                                                <Card
                                                                                    className='answer-survey-question-card question-list'>
                                                                                    <Card.Body>
                                                                                        {question.isRequired && <div
                                                                                            className={"required-text-outer text-center"}>Required</div>}
                                                                                        <div
                                                                                            className="question-container">
                                                                                            <div
                                                                                                className={"question-numbering"}>{index + 1 + ". "}</div>
                                                                                            <div
                                                                                                className={"question "}>{question.questionText}</div>

                                                                                        </div>
                                                                                        {
                                                                                            question.type === QuestionType.NUMBER &&
                                                                                            <div
                                                                                                className={"number-answer-outer mt-2"}>
                                                                                                <input type={"number"}
                                                                                                       className={"form-control number-answer-input"}
                                                                                                       min={question.number.min}
                                                                                                       max={question.number.max}
                                                                                                       onWheel={(e) => {
                                                                                                           document.activeElement.blur();
                                                                                                       }}
                                                                                                       onKeyPress={(e) => {
                                                                                                           if (e.key === 'e' || e.key === 'E' || e.key === '.' || e.key === "-") {

                                                                                                               e.preventDefault();
                                                                                                           }
                                                                                                       }}
                                                                                                       value={answerSurveyData.attemptQuestions[index]?.answerNumber}
                                                                                                       placeholder={"Answer here..."}
                                                                                                       onChange={(e) => {
                                                                                                           if (disableSaveAsDraftButton) {
                                                                                                               setDisableSaveAsDraftButton(false)
                                                                                                           }
                                                                                                           if (disableSubmitButton) {
                                                                                                               setDisableSubmitButton(false)
                                                                                                           }
                                                                                                           handleAttemptQuestions(index, question, e.target.value)

                                                                                                       }}/>
                                                                                                <div
                                                                                                    className={"number-range ms-3"}>{"Rate between " +question.number.min + " to " + question.number.max}</div>
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            question.type === QuestionType.TEXT &&
                                                                                            <div
                                                                                                className={"text-response-answer-outer"}>
                                            <textarea value={answerSurveyData.attemptQuestions[index]?.answerText}
                                                      className={"text-response-answer w-100"} onChange={(e) => {
                                                if (disableSaveAsDraftButton) {
                                                    setDisableSaveAsDraftButton(false)
                                                }
                                                if (disableSubmitButton) {
                                                    setDisableSubmitButton(false)
                                                }

                                                handleAttemptQuestions(index, question, e.target.value)
                                            }} placeholder={"Type answer here..."}/>
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            question.type === QuestionType.OPTION &&
                                                                                            <div
                                                                                                className={"options-list-container mt-2"}>
                                                                                                {question.options.map((option, optionIndex) => {
                                                                                                    return (<div
                                                                                                        key={optionIndex}
                                                                                                        className={"attempt-option-select-container mt-2 "}>
                                                                                                        <div
                                                                                                            className={"option-text-container me-0"}>
                                                                                                            <div
                                                                                                                className={"answer-question-option-heading "}>
                                                                                                                {
                                                                                                                    isSurveyAlreadyAttempted === false ?
                                                                                                                        <input
                                                                                                                            type={"radio"}
                                                                                                                            onChange={() => {
                                                                                                                                if (disableSaveAsDraftButton) {
                                                                                                                                    setDisableSaveAsDraftButton(false)
                                                                                                                                }
                                                                                                                                if (disableSubmitButton) {
                                                                                                                                    setDisableSubmitButton(false)
                                                                                                                                }
                                                                                                                                handleAttemptQuestions(index, question, option)
                                                                                                                            }}
                                                                                                                            name={"answer" + (index)}/> :
                                                                                                                        <input
                                                                                                                            type={"radio"}
                                                                                                                            checked={answerSurveyData.attemptQuestions[index] ? option.optionId === answerSurveyData.attemptQuestions[index].selectedOption.optionId : false}
                                                                                                                            onChange={() => {
                                                                                                                                if (disableSaveAsDraftButton) {
                                                                                                                                    setDisableSaveAsDraftButton(false)
                                                                                                                                }
                                                                                                                                if (disableSubmitButton) {
                                                                                                                                    setDisableSubmitButton(false)
                                                                                                                                }
                                                                                                                                handleAttemptQuestions(index, question, option)
                                                                                                                            }}
                                                                                                                            name={"answer" + (index)}/>
                                                                                                                }

                                                                                                                <div
                                                                                                                    className={"options"}>{option.optionText}</div>
                                                                                                            </div>

                                                                                                        </div>


                                                                                                    </div>);
                                                                                                })}
                                                                                            </div>
                                                                                        }

                                                                                    </Card.Body>
                                                                                </Card>
                                                                            </li>
                                                                        </>
                                                                    )
                                                                }


                                                            })}

                                                        </div>

                                                    </>
                                                )
                                            })
                                            }

                                        </div>

                                    </>


                                }

                                {segmentType === "TABULAR" && answerQuestionDetail !== undefined && Object.keys(answerQuestionDetail).length > 0 ?
                                    <>
                                        <div className={"col-xl-6"}>
                                            {refCollection.length > 0 && refCollection?.map((ref, Index) => {

                                                return (

                                                    <>

                                                        <div className={"attempted-details mediumFontSize"}>

                                                            <h5>{ref.segmentName || "Section " + (Index + 1)}</h5>

                                                            {
                                                                answerQuestionDetail !== undefined && Object.keys(answerQuestionDetail).length > 0 && answerQuestionDetail?.map((attemptQuestion, index) => {

                                                                    if (attemptQuestion?.question.segmentIndex === Index) {
                                                                        return (
                                                                            <>
                                                                                <div
                                                                                    className={"answer-details-outer ms-0 mt-3"}
                                                                                    key={index}>
                                                                                    <div
                                                                                        className={"answer-details-question mt-1 mb-2"}>
                                                                                        <div
                                                                                            className={"question-number"}>{index + 1}.
                                                                                        </div>
                                                                                        <div
                                                                                            className={"question-text"}>{attemptQuestion.question.questionText}
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        attemptQuestion.question.type === QuestionType.NUMBER &&
                                                                                        <div
                                                                                            className={"text-response-answer-result-outer number-answer-container"}>
                                                                                            <div
                                                                                                className={"text-response-answer-result w-100 " + (attemptQuestion.question === undefined ? "disable-text" : "")}>{attemptQuestion.question === undefined ? "Not Attempted" : attemptQuestion.attemptQuestion?.answerNumber}</div>
                                                                                            <div
                                                                                                className={"question-range text-start"}>{`[ Min : ${attemptQuestion.question.number.min} - Max : ${attemptQuestion.question.number.max} ]`}</div>
                                                                                        </div>

                                                                                    }
                                                                                    {
                                                                                        attemptQuestion.question.type === QuestionType.TEXT &&
                                                                                        <div
                                                                                            className={"text-response-answer-result-outer"}>
                                                                                            <p className={"text-response-answer-result w-100 " + (attemptQuestion.attemptQuestion === undefined ? "disable-text" : "")}>{attemptQuestion.attemptQuestion === undefined ? "Not Attempted" : attemptQuestion.attemptQuestion.answerText}</p>
                                                                                        </div>

                                                                                    }

                                                                                    {
                                                                                        attemptQuestion.question.type === QuestionType.OPTION &&
                                                                                        <div
                                                                                            className={"answer-details-options"}>
                                                                                            {
                                                                                                attemptQuestion.question.options.map((option, index) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <div
                                                                                                                key={index}

                                                                                                                className={"answer-details-option-outer mb-1 " + (attemptQuestion?.attemptQuestion?.selectedOption?.optionId === option.optionId ? "selected-option" : "")}>
                                                                                                                <div
                                                                                                                    className={"option-container d-flex"}>
                                                                                                                    <div
                                                                                                                        data-tooltip-id="DeleteReflectionBTN"
                                                                                                                        className={"option-value-icon " + (parseInt(option?.optionValue) <= 33 ? "bg-red" : (parseInt(option?.optionValue) > 33 && parseInt(option?.optionValue) <= 66) ? "bg-orange" : "bg-green")}/>
                                                                                                                    <div
                                                                                                                        className={"option-text-numbering me-3"}>{(index + 1)}</div>
                                                                                                                    <div
                                                                                                                        className={"answer-details-option-text me-3"}>{option.optionText}</div>

                                                                                                                    <ReactTooltip
                                                                                                                        className={"ToolTip"}
                                                                                                                        place={"bottom"}
                                                                                                                        id="DeleteReflectionBTN"/>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    );
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                        );
                                                                    }

                                                                })
                                                            }
                                                        </div>

                                                    </>
                                                )

                                            })}

                                        </div>
                                    </>
                                    :
                                    <>


                                        <div className={"col-xl-6"}>
                                            <div className={"answer-details mediumFontSize"}>

                                                {
                                                    answerQuestionDetail !== undefined && Object.keys(answerQuestionDetail).length > 0 && answerQuestionDetail?.map((attemptQuestion, index) => {

                                                        return (
                                                            <>
                                                                <div
                                                                    className={"answer-details-outer ms-0"}
                                                                    key={index}>
                                                                    <div
                                                                        className={"answer-details-question mt-1 mb-2"}>
                                                                        <div
                                                                            className={"question-number"}>{index + 1}.
                                                                        </div>
                                                                        <div
                                                                            className={"question-text"}>{attemptQuestion.question.questionText}
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        attemptQuestion.question.type === QuestionType.NUMBER &&
                                                                        <div
                                                                            className={"text-response-answer-result-outer number-answer-container"}>
                                                                            <div
                                                                                className={"text-response-answer-result w-100 " + (attemptQuestion.question === undefined ? "disable-text" : "")}>{attemptQuestion.question === undefined ? "Not Attempted" : attemptQuestion.attemptQuestion?.answerNumber}</div>
                                                                            <div
                                                                                className={"question-range text-start"}>{`[ Min : ${attemptQuestion.question.number.min} - Max : ${attemptQuestion.question.number.max} ]`}</div>
                                                                        </div>

                                                                    }
                                                                    {
                                                                        attemptQuestion.question.type === QuestionType.TEXT &&
                                                                        <div
                                                                            className={"text-response-answer-result-outer"}>
                                                                            <p className={"text-response-answer-result w-100 " + (attemptQuestion.attemptQuestion === undefined ? "disable-text" : "")}>{attemptQuestion.attemptQuestion === undefined ? "Not Attempted" : attemptQuestion.attemptQuestion.answerText}</p>
                                                                        </div>

                                                                    }

                                                                    {
                                                                        attemptQuestion.question.type === QuestionType.OPTION &&
                                                                        <div
                                                                            className={"answer-details-options"}>
                                                                            {
                                                                                attemptQuestion.question.options.map((option, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <div
                                                                                                key={index}

                                                                                                className={"answer-details-option-outer mb-1 " + (attemptQuestion?.attemptQuestion?.selectedOption?.optionId === option.optionId ? "selected-option" : "")}>
                                                                                                <div
                                                                                                    className={"option-container d-flex"}>
                                                                                                    <div
                                                                                                        data-tooltip-id="DeleteReflectionBTN"
                                                                                                        className={"option-value-icon " + (parseInt(option?.optionValue) <= 33 ? "bg-red" : (parseInt(option?.optionValue) > 33 && parseInt(option?.optionValue) <= 66) ? "bg-orange" : "bg-green")}/>
                                                                                                    <div
                                                                                                        className={"option-text-numbering me-3"}>{(index + 1)}</div>
                                                                                                    <div
                                                                                                        className={"answer-details-option-text me-3"}>{option.optionText}</div>

                                                                                                    <ReactTooltip
                                                                                                        className={"ToolTip"}
                                                                                                        place={"bottom"}
                                                                                                        id="DeleteReflectionBTN"/>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </>
                                                        );


                                                    })
                                                }
                                            </div>
                                        </div>

                                    </>
                                }


                            </div>
                        </ul>

                    </div>

                </div>
            </div>);
}
export default AnswerSurvey;