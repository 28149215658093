import {useNavigate} from 'react-router-dom';
import "./Surveys.css"
import {hasPermission, Permissions, roleHasAccess, Roles} from "../../helpers/accessControl";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useMemo, useState} from "react";
import filter_icon from "../../images/filter_icon.svg";
import search_icon from "../../images/search_icon.svg";
import {
    bulkSurveyDelete,
    deleteSurveyById,
    disableSurveyById,
    isAttemptExistsForSurvey,
    resetGetSearchSurveyDataReducer,
    searchSurveys,
    unAssignReflection,
    unAssignSurveyByOrgAdmin
} from "../../reduxStore/surveySlices/surveySlices";
import {
    BulkDeleteIcon, createOptionListForSelectTag, formatDate, getDataFromLocalStorage, isUnauthorized,
    tableCustomStyling,
    TableSkeletonLoading, trim
} from "../../helpers/utils";
import Pagination from "../commons/Pagination";
import {
    AccessDenied,
    AccessDeniedMsg,
    DisabledModifyReflectionBTNText,
    PulsePlans,
    Survey, UnassignReflectionConfirmationMsg
} from "../../helpers/constants";
import image from "../../images/CrossIcon2.svg"
import ErrorPage from "../errorPage/ErrorPage";
import ConfirmationModel from "../commons/ConfirmationModel";
import DataTable from "react-data-table-component";
import {Tooltip as ReactTooltip} from "react-tooltip";
import jwtDecode from "jwt-decode";
import Select from "react-select";
import AssignReflection from "./assignReflectionModal/AssignReflection";
import {RiUserSharedLine} from "react-icons/ri";
import {getAllOrgAsList} from "../../reduxStore/orgSlices/orgSlices";
import {setGetAllUsersLoadingReducer} from "../../reduxStore/usersSlices/userSlice";
import AssignedReflectionToSubOrg from "./assignReflectionModal/AssignedReflectionToSubOrg";
import {SearchFilterModal} from "../commons/SearchFilterModal";
import UserInfo from "../layout/UserInfo";
import {FiTrash2} from "react-icons/fi";
import {GoArrowSwitch, GoPencil} from "react-icons/go";
import MoveMemberAndOrgModal from "../members/modal/MoveMemberAndOrgModal";

const Surveys = ({showHeader, isSubOrg = false, subOrgId}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [sortBy, setSortBy] = useState("createdAt");
    const [sortOrder, setSortOrder] = useState("desc");
    const [selectCheckBoxItems, setSelectCheckBoxItems] = useState([]);


    const [showAssignReflectionModal, setShowAssignReflectionModal] = useState(false)
    const [showAssignedReflectionModal, setShowAssignedReflectionModal] = useState(false)
    const getAllOrgAsListData = useSelector(state => state.org.getAllOrgAsListReducer);
    // const [tester, setTester] = useState(false);
    const [searchData, setSearchData] = useState({
        surveyName: null,
        tag: "",
        goal: "",
        enable: "",
        orgId: "",
        sortBy: sortBy,
        sortOrder: sortOrder,
        createdBy: null,
        ...(isSubOrg ? {subOrgId: subOrgId} : {}),
    })

    const isSystemOrgAdmin = ((roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER])) && jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === "SYSTEM")

    const [pageNumber, setPageNumber] = useState(isSystemOrgAdmin && !isSubOrg ? 0 : 1);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showBulkDeleteConfirmationModal, setShowBulkDeleteConfirmationModal] = useState(false);
    const [showDisableSurveyModal, setShowDisableSurveyModal] = useState(false);
    const [timer, setTimer] = useState(null)
    const [surveyId, setSurveyId] = useState(null);
    const survey = useSelector(state => state.survey.getSearchSurveyDataReducer)
    const deleteSurveyByIdData = useSelector(state => state.survey.deleteSurveyByIdDataReducer)
    const bulkDeleteSurveysDataReducer = useSelector(state => state.survey.bulkDeleteSurveysDataReducer)
    const disableSurveyByIdData = useSelector(state => state.survey.disableSurveyByIdDataReducer)
    const unAssignReflectionData = useSelector(state => state.survey.unAssignReflectionReducer)
    const [showUnAssignReflectionConfirmationBox, setShowUnAssignReflectionConfirmationBox] = useState(false)
    const [viewAssignReflectionBox, setViewAssignReflectionBox] = useState(false)


    const [showSearchFilterModal, setShowSearchFilterModal] = useState(false)

    const [applyFilter, setApplyFilter] = useState(false)

    const [selectedReflectionToUnAssign, setSelectedReflectionToUnAssign] = useState(null)
    const [unAssignReflectionId, setUnAssignReflectionId] = useState(null)
    const handleSort = (column, sortDirection,) => {
        setSortBy(column.sortField)
        setSortOrder(sortDirection)
        setApplyFilter(true)
    };

    const callApiFunction = () => {

        if (hasPermission([Permissions.REFLECTION_READ], true)) {
            setSelectCheckBoxItems([])
            const updatedSearchData = {
                ...searchData,
                createdBy: searchData?.createdBy?.value ? jwtDecode(getDataFromLocalStorage("token")).userid : null,
                page: pageNumber,
                orgId: searchData?.orgId?.value,
                sortBy: sortBy,
                sortOrder: sortOrder,
                enable: searchData?.enable?.value
            }
            dispatch(searchSurveys(updatedSearchData)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                setApplyFilter(false)
            });
        }

    };

    useEffect(() => {
        if (searchData.surveyName !== null) {
            if (timer) {
                clearTimeout(timer)
            }
            setTimer(setTimeout(() => {
                callApiFunction()
            }, 1000))

        }
    }, [searchData.surveyName])


    useEffect(() => {
        dispatch(resetGetSearchSurveyDataReducer(true))
        if (isSystemOrgAdmin && !isSubOrg) {
            dispatch(getAllOrgAsList()).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                } else if (res.meta.requestStatus === "fulfilled" && !isSubOrg) {
                    dispatch(setGetAllUsersLoadingReducer(true))
                    const orgInfo = res.payload.data?.filter(org => org.orgId === jwtDecode(getDataFromLocalStorage("token")).orgId)[0]
                    setSearchData({...searchData, orgId: {label: orgInfo.name, value: orgInfo.orgId}})
                    setApplyFilter(true)
                    setPageNumber(1)
                }
            })
        }
    }, [])

    // useEffect(() => {
    //     survey?.data?.totalItems && pageNumber * survey?.data?.pageSize > survey?.data?.totalItems && setPageNumber(Math.ceil(survey?.data?.totalItems / survey?.data?.pageSize))
    // }, [survey?.data])


    useEffect(() => {
        // if (jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === "SYSTEM") {
        //     if (typeof searchData?.orgId === 'object' && Object.keys(searchData?.orgId).length > 0 && (searchData?.orgId !== null || searchData?.orgId !== "")) {
        //         callApiFunction()
        //     }
        // } else {
        //     callApiFunction()
        // }
        if (pageNumber > 0) {
            callApiFunction()
        }


    }, [pageNumber])

    useEffect(() => {

        if (applyFilter) {
            callApiFunction()
        }
        // if (isSystemOrgAdmin && searchData.orgId !== "" && !tester) {
        //     console.log("asjhdgvcafjhsgjhdvaksdgv")
        //     callApiFunction()
        //     setTester(true)
        // }

    }, [searchData, applyFilter, sortBy, sortOrder])

    const columns = [
        {
            name: <div className={"d-flex"}>
                <input type={"checkbox"}
                       disabled={!(hasPermission([Permissions.REFLECTION_DELETE], true) && (roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER, Roles.SUB_ORG_ADMIN]) || roleHasAccess([Roles.MENTOR, Roles.REFLECTION_WRITER])))}
                    // checked={Array.isArray(survey?.data?.data) && survey?.data?.data.length > 0 && selectCheckBoxItems.length > 0 && survey?.data?.data?.filter(e=> hasPermission([Permissions.REFLECTION_DELETE], true) && (roleHasAccess([Roles.ORG_ADMIN,Roles.MANAGER,Roles.SUB_ORG_ADMIN]) ? getDataFromLocalStorage("loggedInUserInfo").orgId === e.orgId : roleHasAccess([Roles.MENTOR,Roles.REFLECTION_WRITER]) ?  getDataFromLocalStorage("loggedInUserInfo").userId === e.createdBy : false  ) ).every(id => selectCheckBoxItems.includes(id))}
                       checked={
                           Array.isArray(survey?.data?.data) &&
                           survey?.data?.data.length > 0 &&
                           selectCheckBoxItems.length > 0 &&
                           survey?.data?.data?.filter(e =>
                               hasPermission([Permissions.REFLECTION_DELETE], true) &&
                               (
                                   roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER, Roles.SUB_ORG_ADMIN]) ?
                                       getDataFromLocalStorage("loggedInUserInfo").orgId === e.orgId :
                                       roleHasAccess([Roles.MENTOR, Roles.REFLECTION_WRITER]) ?
                                           getDataFromLocalStorage("loggedInUserInfo").userId === e.createdBy :
                                           false
                               )
                           ).every(id => selectCheckBoxItems.includes(id.surveyId))
                       }
                       onClick={(e) => {
                           e.stopPropagation();
                           if (survey?.data?.data) {
                               let surveyIds = [];

                               if (hasPermission([Permissions.REFLECTION_DELETE], true)) {
                                   if (roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER, Roles.SUB_ORG_ADMIN])) {
                                       surveyIds = survey.data.data.filter(e => getDataFromLocalStorage("loggedInUserInfo").orgId === e.orgId).map(c => c.surveyId);
                                   } else if (roleHasAccess([Roles.MENTOR, Roles.REFLECTION_WRITER])) {
                                       surveyIds = survey.data.data.filter(e => getDataFromLocalStorage("loggedInUserInfo").userId === e.createdBy).map(c => c.surveyId);
                                   }
                               }
                               if (surveyIds.every(id => selectCheckBoxItems.includes(id))) {
                                   setSelectCheckBoxItems([]);
                               } else {
                                   setSelectCheckBoxItems(surveyIds);
                               }
                           }


                       }}/> <span className={"ms-2 bulk_delete_Main_header"}> Name </span></div>,
            selector: row => {
                trim(row.name, 30)
            },
            sortField: 'name',
            sortable: true,
            style: {
                fontFamily: 'General Sans Medium',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "-0.03em",
                color: "#171919",
            },
            width: survey?.loading ? "" : "25%",
            cell: row => (
                <div className={'d-flex '}>

                    <div>
                        <input


                            disabled={!(hasPermission([Permissions.REFLECTION_DELETE], true) &&
                                (roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER, Roles.SUB_ORG_ADMIN]) ? getDataFromLocalStorage("loggedInUserInfo").orgId === row.orgId : (roleHasAccess([Roles.MENTOR, Roles.REFLECTION_WRITER]) ? getDataFromLocalStorage("loggedInUserInfo").userId === row.createdBy : false)))}
                            checked={Array.isArray(selectCheckBoxItems) && selectCheckBoxItems.length > 0 && selectCheckBoxItems.includes(row?.surveyId)}
                            type={"checkbox"}
                            onClick={(e) => {
                                if (!selectCheckBoxItems.includes(row?.surveyId)) {
                                    setSelectCheckBoxItems([...selectCheckBoxItems, row?.surveyId]);
                                } else {
                                    const updatedItems = selectCheckBoxItems.filter(item => item !== row?.surveyId);
                                    setSelectCheckBoxItems(updatedItems);
                                }
                            }}
                        />
                    </div>


                    <div className={""}>
                    <span title={row.name} className={"ms-2 name_title"}>
                       {trim(row.name, 50)}</span>
                    </div>
                </div>


            ),
            // width: survey?.loading ? "" : "25%",

        },
        {
            name: "Created Date",
            selector: row => row.createdAt,
            sortField: 'createdAt',
            sortable: true,
            cell: row => <div>{formatDate(row.createdAt)}</div>,
            style: {
                opacity: ".7"
            },
            // width: survey?.loading ? "" : "18%",

        },
        {
            name: "Owner",
            selector: row => row.owner,
            style: {
                opacity: ".7"
            },
            // width: survey?.loading ? "" : "10%",

        },
        {
            name: "Status",
            selector: row => row.status,
            sortField: 'status',
            sortable: true,
            cell: row => (
                <div
                    className={row.status === Survey.SURVEY_STATUS_DRAFT ? "status-container inviteStatus_disable text-end" : "status-container inviteStatus text-end"}>
                    <span
                        className="badge badge-primary ">{row.status === Survey.SURVEY_STATUS_DRAFT ? "Draft" : "Published"}</span>
                </div>
            ),
            // width: survey?.loading ? "" : "17%",


        },
        {
            name: "Active",
            selector: row => row.enable,
            cell: row => (
                row.enable ?
                    <i title="Enabled"
                       className="fa fa-check-circle enabled-data "/>
                    :
                    <i title="Disabled"
                       className="fa fa-times-circle disabled-data "/>
            ),
            width: survey?.loading ? "" : "10%",

        },
        (hasPermission([Permissions.REFLECTION_WRITE], true) || hasPermission([Permissions.REFLECTION_DELETE], true)) && {
            name: "Action",
            cell: row => (
                <>
                    <div
                        data-tooltip-id="DeleteReflectionBTN"
                        data-tooltip-content={(row.enable && ((roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER, Roles.SUB_ORG_ADMIN, Roles.MENTOR, Roles.REFLECTION_WRITER]) && jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === "SYSTEM") ||
                            (getDataFromLocalStorage("loggedInUserInfo").orgId === row.orgId && (roleHasAccess([Roles.ORG_ADMIN, Roles.SUB_ORG_ADMIN, Roles.MANAGER, Roles.MENTOR]) || getDataFromLocalStorage("loggedInUserInfo").userId === row.createdBy))
                        )) ? "" : (roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER]) && (jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'SYSTEM') || (roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER]) && (jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'CUSTOMER') && (isSubOrg ? (row.orgId === subOrgId ? true : false) : row.orgId === getDataFromLocalStorage("loggedInUserInfo").orgId ? true : false))) ? "" : DisabledModifyReflectionBTNText}
                        className={"status-container inviteStatus_disable text-end me-1 " + (roleHasAccess([Roles.ORG_ADMIN, Roles.SUB_ORG_ADMIN]) && getDataFromLocalStorage("loggedInUserInfo").userId === row.createdBy) ? "cursor-pointer" : " disable-trash-icon"}>

                        {
                            isSubOrg && roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER]) && jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === "SYSTEM" &&
                            <RiUserSharedLine title={"Unassign Reflection "}
                                              onClick={() => {
                                                  if (row.createdBy === getDataFromLocalStorage("loggedInUserInfo").userId) {
                                                      setSelectedReflectionToUnAssign(row.surveyId)
                                                      setShowUnAssignReflectionConfirmationBox(true)
                                                  }
                                              }}
                                              className={"unassign-icon me-2" + (row.createdBy === getDataFromLocalStorage("loggedInUserInfo").userId ? "" : " disable-unassign-icon")}/>
                        }
                        {
                            isSubOrg && roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER]) && jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === "CUSTOMER" &&
                            <RiUserSharedLine title={"Unassign Reflection "}
                                              onClick={() => {
                                                  if (row.assignTo?.length > 0 && row.assignTo?.includes(subOrgId)) {
                                                      setUnAssignReflectionId(row.surveyId)
                                                      setShowUnAssignReflectionConfirmationBox(true)
                                                  }
                                              }}
                                              className={"unassign-icon me-2" + (row.assignTo?.length > 0 && row.assignTo?.includes(subOrgId) ? "" : " disable-unassign-icon")}/>
                        }

                        {
                            roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER, Roles.SUB_ORG_ADMIN]) &&
                            <>
                                {
                                    hasPermission([Permissions.REFLECTION_WRITE], true) &&
                                    <GoPencil size={18}
                                              className={"" + (row.orgId === getDataFromLocalStorage("loggedInUserInfo").orgId ? "" : " disable-unassign-icon")}
                                              title={"Edit Reflection"}
                                              onClick={() => {
                                                  if ((roleHasAccess([Roles.ORG_ADMIN, Roles.SUB_ORG_ADMIN, Roles.MANAGER]) || getDataFromLocalStorage("loggedInUserInfo").orgId === row.orgId)) {
                                                      navigate(`/reflections/${row.surveyId}`)
                                                  }
                                              }}
                                    />
                                }

                                {
                                    roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER]) && (jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'SYSTEM') &&
                                    <GoArrowSwitch size={18} className={"ms-3 me-2"} title={"Move Reflection"}
                                                   onClick={() => {
                                                       setViewAssignReflectionBox(true)
                                                       setSurveyId(row.surveyId)
                                                   }}/>
                                }
                                {
                                    roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER]) && (jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'CUSTOMER') &&
                                    <GoArrowSwitch size={18}
                                                   className={"ms-3 me-2 " + (isSubOrg ? (row.orgId === subOrgId ? "" : "disable-unassign-icon") : row.orgId === getDataFromLocalStorage("loggedInUserInfo").orgId ? "" : "disable-unassign-icon")}
                                                   title={"Move Reflection"}
                                                   onClick={() => {
                                                       setViewAssignReflectionBox(true)
                                                       setSurveyId(row.surveyId)
                                                   }}/>
                                }

                                {
                                    hasPermission([Permissions.REFLECTION_DELETE], true) &&
                                    <>
                                        <FiTrash2 size={18}
                                                  className={"ms-2 " + (row.orgId === getDataFromLocalStorage("loggedInUserInfo").orgId ? "" : " disable-unassign-icon")}
                                                  title={"Delete Reflection"}
                                                  onClick={() => {
                                                      if ((roleHasAccess([Roles.ORG_ADMIN, Roles.SUB_ORG_ADMIN, Roles.MANAGER]) || getDataFromLocalStorage("loggedInUserInfo").orgId === row.orgId)) {
                                                          checkAttemptsForSurvey(row.surveyId)
                                                          setSurveyId(row.surveyId)
                                                      }
                                                  }}
                                        />
                                    </>

                                }
                            </>
                        }

                        {
                            roleHasAccess([Roles.MENTOR]) &&
                            <>
                                {
                                    hasPermission([Permissions.REFLECTION_WRITE], true) &&
                                    <GoPencil size={18}
                                              className={"" + (row.createdBy === getDataFromLocalStorage("loggedInUserInfo")?.userId ? "" : " disable-unassign-icon")}
                                              title={"Edit Reflection"}
                                              onClick={() => {
                                                  if ((getDataFromLocalStorage("loggedInUserInfo").orgId === row.orgId)) {
                                                      navigate(`/reflections/${row.surveyId}`)
                                                  }
                                              }}
                                    />
                                }
                                {
                                    hasPermission([Permissions.REFLECTION_DELETE], true) &&
                                    <FiTrash2 size={18}
                                              className={"ms-2" + (row.createdBy === getDataFromLocalStorage("loggedInUserInfo").userId ? "" : " disable-unassign-icon")}
                                              onClick={() => {
                                                  if ((roleHasAccess([Roles.MENTOR]) || getDataFromLocalStorage("loggedInUserInfo").userId === row.createdBy)) {
                                                      checkAttemptsForSurvey(row.surveyId)
                                                      setSurveyId(row.surveyId)
                                                  }
                                              }}/>
                                }

                            </>
                        }


                    </div>
                    <ReactTooltip className={"ToolTip"} place={"bottom"} id="DeleteReflectionBTN"/>
                </>
            ),

        },

    ].filter(Boolean)

    const handleDisableSurvey = () => {
        if (surveyId != null && surveyId !== "") {
            dispatch(disableSurveyById({surveyId: surveyId, setSurveyId: setSurveyId})).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                } else if (res.meta.requestStatus === "fulfilled") {
                    setShowDisableSurveyModal(false)
                    const updatedSearchData = {...searchData, page: pageNumber, sortBy: sortBy, sortOrder: sortOrder}
                    dispatch(searchSurveys(updatedSearchData)).then(res => {
                        if (isUnauthorized(res)) {
                            navigate("/")
                        }
                    });
                }
            })
        }
    }

    const handleDeleteSurvey = (isBulkDelete = false) => {
        if (surveyId != null && surveyId !== "") {
            dispatch(deleteSurveyById({surveyId, setSurveyId})).then((response) => {
                if (isUnauthorized(response)) {
                    navigate("/")
                }
                if (response.meta.requestStatus === "fulfilled") {
                    setShowConfirmationModal(false)
                    if (survey.getSearchSurveyData?.data?.length === 1 && pageNumber > 1) {
                        setPageNumber(pageNumber - 1)
                    } else {
                        const updatedSearchData = {
                            ...searchData,
                            page: pageNumber,
                            orgId: searchData?.orgId?.value,
                            sortBy: sortBy,
                            sortOrder: sortOrder
                        }
                        dispatch(searchSurveys(updatedSearchData)).then(res => {
                            if (isUnauthorized(res)) {
                                navigate("/")
                            }
                        });
                    }
                }
            });

        } else if (isBulkDelete && selectCheckBoxItems.length > 0) {
            dispatch(bulkSurveyDelete({selectCheckBoxItems, setSelectCheckBoxItems})).then((response) => {
                if (isUnauthorized(response)) {
                    navigate("/")
                }
                if (response.meta.requestStatus === "fulfilled") {
                    setShowBulkDeleteConfirmationModal(false)
                    callApiFunction();
                }
            });

        }
    }
    const handleOnChange = (e) => {
        setSearchData({...searchData, [e.target.name]: e.target.value})
    }
    const handleOnSubmit = (e) => {
        e.preventDefault();
        dispatch(searchSurveys(searchData)).then(res => {
            if (isUnauthorized(res)) {
                navigate("/")
            }
        })
    }


    const checkAttemptsForSurvey = (surveyId) => {
        dispatch(isAttemptExistsForSurvey(surveyId)).then(res => {
            if (isUnauthorized(res)) {
                navigate("/")
            } else if (res.meta.requestStatus === "fulfilled") {
                res.payload.data ? setShowDisableSurveyModal(true) : setShowConfirmationModal(true)
            }
        })

    }
    const handleUnAssignSurvey = () => {
        if (jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === "SYSTEM") {
            dispatch(unAssignReflection(selectedReflectionToUnAssign)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                } else if (res.meta.requestStatus === "fulfilled") {
                    setShowUnAssignReflectionConfirmationBox(false)
                    setSelectedReflectionToUnAssign(null)
                    callApiFunction();
                }
            })
        } else {
            const data = {surveyId: unAssignReflectionId, subOrgId: subOrgId}
            dispatch(unAssignSurveyByOrgAdmin(data)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                } else if (res.meta.requestStatus === "fulfilled") {
                    setShowUnAssignReflectionConfirmationBox(false)
                    setUnAssignReflectionId(null)
                    callApiFunction();
                }
            })
        }

    }


    let searchFiltersTags = useMemo(() => [
        {
            shouldShow: isSystemOrgAdmin && !subOrgId,
            tagView: < Select
                className="ms-0 members-filter-role react-select-container"
                classNamePrefix="ms-0 react-select"
                isDisabled={getAllOrgAsListData?.loading}
                value={searchData?.orgId}
                options={createOptionListForSelectTag(getAllOrgAsListData?.data, "name", "orgId")}
                onChange={(selectedOrg) => {
                    if (searchData?.orgId?.value !== selectedOrg?.value) {
                        setSearchData({...searchData, orgId: selectedOrg})
                    }
                }}
                isClearable={true}
                placeholder={"Organization..."}/>

        },
        {
            tagView: <div className="filter_input_wrapper">
                <input type={"text"} className={"ms-0 form-control search-box-input-goal"}
                       placeholder={"Goal..."} onChange={handleOnChange} value={searchData.goal}
                       name={"goal"}/>
                <div className={"cross-btn-outer"}>
                    <img src={image}
                         className={"survey-search-clr-btn cursor-pointer"}
                         onClick={() => {
                             setSearchData({
                                 ...searchData,
                                 goal: ""
                             })
                         }}/>


                </div>
            </div>

        },
        {
            tagView: <div className={"filter_input_wrapper "}>
                <input type={"text"} className={"ms-0 form-control search-box-input-tag"} name={"tag"}
                       value={searchData.tag}
                       onChange={(e) => {
                           setSearchData({...searchData, [e.target.name]: e.target.value})
                       }}
                       placeholder={"Tag..."}/>
                <div className={"cross-btn-outer"}>
                    <img src={image}
                         className={"survey-search-clr-btn cursor-pointer"}
                         onClick={() => {
                             setSearchData({
                                 ...searchData,
                                 tag: ""
                             })
                         }}/></div>
            </div>

        },

        {
            shouldShow: isSubOrg && roleHasAccess([Roles.SYS_ADMIN]),
            tagView: <Select
                className="search-box-input-assigned-unassigned-outer react-select-container ms-0"
                classNamePrefix="search-box-input-assigned-unassigned react-select"
                value={searchData?.createdBy}
                options={[{value: true, label: "Assigned"}]}
                onChange={(selectedOpt) => {
                    setSearchData({...searchData, createdBy: selectedOpt})
                }}
                isClearable={true}
                placeholder={"Assigned..."}/>

        },
        {
            tagView: <Select
                className="search-box-input-active-inactive ms-0 react-select-container"
                classNamePrefix="survey-search-box-input-active-inactive react-select"
                value={searchData?.enable}
                options={[{value: true, label: "Active"}, {
                    value: false,
                    label: "In Active"
                }]}
                onChange={(selectedOrg) => {
                    setSearchData({...searchData, enable: selectedOrg})
                }}
                isClearable={true}
                placeholder={"Active/In Active..."}/>

        }
    ], [isSystemOrgAdmin, isSubOrg, subOrgId, getAllOrgAsListData, searchData])


    return !hasPermission([Permissions.REFLECTION_READ], true) ?

        <ErrorPage value={403} description={AccessDenied} detailDesc={AccessDeniedMsg}/> :

        (

            <section>

                {
                    !isSubOrg &&
                    <>
                        <div className="cmn_header">
                            <h2 className="mediumFontSize  org-heading m-0">Reflections</h2> <UserInfo/>
                        </div>
                    </>
                }
                <div
                    className={showHeader !== undefined ? "true_background" : "row main_content " + (isSubOrg ? "" : " box_design1")}>
                    <div className={showHeader !== undefined ? "p-2" : "col-md-12 p-0"}>
                        <div>
                            <div className={' ' + (isSubOrg ? " sub-org-survey-outer" : " survey_outer ")}>
                                {showHeader === undefined && <div
                                    className={'  ' + (isSubOrg ? "sub-org-survey_wrapper" : " survey_wrapper  mb-2")}>
                                </div>
                                }
                                <>
                                    {
                                        jwtDecode(getDataFromLocalStorage("token")).planName !== PulsePlans.INDIVIDUAL.value &&
                                        <div className={"search-box ms-1 me-1"}>
                                            <div
                                                className={"search-box-form search-box-form-reflection " + (isSubOrg && roleHasAccess([Roles.SYS_ADMIN]) ? "search-box-form-reflection-org" : "")}
                                                onSubmit={handleOnSubmit}>

                                                <div className="d-flex col-12">
                                                    <div className="reflection_search_wrapper ">
                                                        <img src={search_icon} alt=""/>
                                                        <input type="text"
                                                               disabled={getAllOrgAsListData?.loading}
                                                               className="form-control"
                                                               placeholder="Search Reflection..."
                                                               onChange={handleOnChange}
                                                               value={searchData.surveyName}
                                                               name="surveyName"/>
                                                    </div>

                                                    <div className={"reflection_filters ms-auto"}>
                                                        {
                                                            Array.isArray(selectCheckBoxItems) && selectCheckBoxItems.length > 0 && (

                                                                <BulkDeleteIcon selectCheckBoxItems={selectCheckBoxItems}
                                                                                setShowBulkDeleteConfirmationModal={setShowBulkDeleteConfirmationModal}/>

                                                            )
                                                        }
                                                        <button type={"button"}
                                                                onClick={() => {
                                                                    setShowSearchFilterModal(true)
                                                                }}>
                                                            <img src={filter_icon} alt=""/>Filter
                                                        </button>
                                                    </div>
                                                    <div
                                                        className={"ms-2  " + (isSubOrg ? "text-end" : " search-box-add-btn-outer")}>

                                                        {
                                                            hasPermission([Permissions.REFLECTION_CREATE], true) && (!isSubOrg && jwtDecode(getDataFromLocalStorage("token")).planName !== PulsePlans.INDIVIDUAL.value) &&
                                                            <button
                                                                style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                className={'cmn_background_color survey_addnew_btn mediumFontSize ' + (isSubOrg ? " mb-2" : "")}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    navigate('/creationReflection')
                                                                }}
                                                            >Create Reflection</button>
                                                        }
                                                    </div>

                                                    {
                                                        isSubOrg && roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER]) && jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === "SYSTEM" &&
                                                        <div className={"text-end "}>
                                                            <button
                                                                style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                className={'cmn_background_color survey_addnew_btn mediumFontSize '}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowAssignReflectionModal(true)
                                                                }
                                                                }>Assign Reflection
                                                            </button>
                                                        </div>
                                                    }

                                                    {
                                                        isSubOrg && roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER]) && jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === "CUSTOMER" &&
                                                        <div className={"text-end"}>

                                                            <button
                                                                style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                className={'cmn_background_color survey_addnew_btn mediumFontSize '}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowAssignedReflectionModal(true)
                                                                }
                                                                }>Assign Reflections
                                                            </button>
                                                        </div>
                                                    }

                                                </div>

                                            </div>

                                        </div>
                                    }</>

                                <div className={"custom_table colored_table"}>
                                    <DataTable
                                        className={survey?.data?.totalPages > 1 ? "table_design2" : "table_design1"}
                                        noDataComponent={<div
                                            className={"lightFontSize cmn_fontStyle mt-2 mb-2"}>No Reflection
                                            Found</div>}
                                        persistTableHead={true} responsive={true}
                                        progressPending={survey?.loading || getAllOrgAsListData?.loading}
                                        progressComponent={<div
                                            className={"reflection-skeleton-loader w-100"}>
                                            <TableSkeletonLoading columns={columns.length || 0} rows={5}/></div>}
                                        customStyles={tableCustomStyling}
                                        columns={columns}
                                        data={survey?.data?.data}
                                        onSort={handleSort} sortServer={true}
                                    />
                                    {
                                        !survey?.loading && survey.data && survey?.data?.totalPages > 1 &&
                                        <Pagination className={isSubOrg ? "pagination-border" : ""}
                                                    totalPages={survey?.data?.totalPages}
                                                    pageNumber={survey?.data?.pageNumber}
                                                    setPageNumber={setPageNumber}/>
                                    }
                                </div>

                            </div>
                        </div>

                        {
                            showConfirmationModal &&
                            <ConfirmationModel title={"Delete Reflection"}
                                               btnText={"Delete"}
                                               action={handleDeleteSurvey} show={showConfirmationModal}
                                               bodyMessage={"Are you sure you want to delete this reflection? You cannot view or retrieve the data from this reflection once it is deleted. Are you sure you wish to proceed?"}
                                               setShow={setShowConfirmationModal}
                                               isLoading={deleteSurveyByIdData?.loading}
                                               showUserAcknowledgementCheckBox={true}
                                               userAcknowledgementCheckBoxText={"Yes, I understand"}
                            />
                        }

                        {
                            showBulkDeleteConfirmationModal &&
                            <ConfirmationModel title={"Delete Reflections"}
                                               btnText={"Delete"}
                                               action={() => handleDeleteSurvey(true)}
                                               show={showBulkDeleteConfirmationModal}
                                               bodyMessage={"Are you sure you want to delete all selected reflections? You cannot view or retrieve the data from these reflections once it is deleted. Are you sure you wish to proceed?"}
                                               setShow={setShowBulkDeleteConfirmationModal}
                                               isLoading={bulkDeleteSurveysDataReducer?.loading}
                                               showUserAcknowledgementCheckBox={true}
                                               userAcknowledgementCheckBoxText={"Yes, I understand"}
                            />
                        }
                        {
                            showDisableSurveyModal &&
                            <ConfirmationModel title={"Disable Reflection"}
                                               btnText={"Disable"}
                                               action={handleDisableSurvey} show={showDisableSurveyModal}
                                               bodyMessage={"Deleting the reflection is not possible due to prior participant involvement. However, you can disable the reflection to prevent future attempts while preserving the existing data and insights."}
                                               setShow={setShowDisableSurveyModal}
                                               isLoading={disableSurveyByIdData?.loading}
                                               showUserAcknowledgementCheckBox={true}
                                               userAcknowledgementCheckBoxText={"Yes, I Agree"}
                            />
                        }


                        {viewAssignReflectionBox &&
                            <MoveMemberAndOrgModal show={viewAssignReflectionBox}
                                                   setShow={setViewAssignReflectionBox} rowId={surveyId}
                                                   userData={null} setUserData={null} query={null}
                                                   callApiFunction={callApiFunction} setQuery={null}
                                                   isSubOrg={isSubOrg} subOrgId={subOrgId}
                                                   title={"Move Reflection"}
                            />}

                        {
                            showAssignReflectionModal &&
                            <AssignReflection getUpdatedReflectionList={callApiFunction} orgId={subOrgId}
                                              show={showAssignReflectionModal} setShow={setShowAssignReflectionModal}
                            />
                        }
                        {
                            showAssignedReflectionModal &&
                            <AssignedReflectionToSubOrg show={showAssignedReflectionModal}
                                                        setShow={setShowAssignedReflectionModal} subOrgId={subOrgId}
                            />
                        }
                        {
                            showUnAssignReflectionConfirmationBox &&
                            <ConfirmationModel title={"Unassign Reflection"}
                                               btnText={"Unassign"}
                                               action={handleUnAssignSurvey}
                                               show={showUnAssignReflectionConfirmationBox}
                                               bodyMessage={UnassignReflectionConfirmationMsg}
                                               setShow={setShowUnAssignReflectionConfirmationBox}
                                               isLoading={unAssignReflectionData?.loading}
                            />
                        }


                        {
                            showSearchFilterModal &&
                            <SearchFilterModal title={"Reflection Filters"}
                                               show={showSearchFilterModal}
                                               filtersTag={searchFiltersTags}
                                               applyFilter={applyFilter}
                                               setApplyFilter={setApplyFilter}
                                               resetFilters={() => setSearchData({
                                                   surveyName: "",
                                                   tag: "",
                                                   goal: "",
                                                   enable: "",
                                                   orgId: "",
                                                   sortBy: sortBy,
                                                   sortOrder: sortOrder,
                                                   createdBy: null,
                                                   ...(isSubOrg ? {subOrgId: subOrgId} : {}),
                                               })}
                                               handleClose={() => {
                                                   setShowSearchFilterModal(false);
                                               }}
                                               setShow={setShowSearchFilterModal}

                            />
                        }


                    </div>

                </div>


            </section>

        )
}

export default Surveys;
