import {useNavigate, useParams} from "react-router-dom";
import "../organizationsDetail/OrganizationDetails.css"
import {useEffect, useState} from "react";
import {
    isUnauthorized,
} from "../../../helpers/utils";
import {useDispatch, useSelector} from "react-redux";
import {getOrgById} from "../../../reduxStore/orgSlices/orgSlices";
import {resetGetUserByIdReducer} from "../../../reduxStore/usersSlices/userSlice";
import {resetGetOrgByIdDataReducer} from "../../../reduxStore/orgSlices/orgSlices";
import SubOrganizationDetails from "../../subOrganizations/SubOrganizationDetails";
import Skeleton from "react-loading-skeleton";

const OrganizationsDetails = () => {


    const navigate = useNavigate();
    const {orgId} = useParams();
    const [orgName, setOrgName] = useState(null)
    const org = useSelector(state => state.org)
    const dispatch = useDispatch();
    useEffect(() => {

        if(orgId !== undefined){
            dispatch(getOrgById(orgId)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                if (res.meta.requestStatus === "fulfilled") {
                    setOrgName(res.payload?.data?.name)
                }
            })
        }


        return () => {
            dispatch(resetGetOrgByIdDataReducer())
            dispatch(resetGetUserByIdReducer())
        }
    }, [])

    return (
    <>
        <div className="row main_content box_design1">
            <div className="organization-details mt-2 mb-3">
                <div style={{color : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color"):""} } className={"org-Data mediumFontSize"} onClick={() => {
                    navigate(-1)
                }}>
                    <span className={"sub-org-back-btn "}
                          style={{background: (localStorage.getItem("color") !== "undefined" && localStorage.getItem("color") !== null && localStorage.getItem("color") !== undefined) ? localStorage.getItem("color") : "#fc6620"}}>
                        <i className="fa fa-solid fa-arrow-left"/>&nbsp;&nbsp;
                    </span>

                    {org.getOrgByIdDataReducer.loading ?

                        <>
                            <Skeleton width={120} height={20}/>
                        </>

                        :
                        <>
                            {orgName === null || orgName === undefined ? "":orgName}
                        </>

                    }



                </div>


            </div>
            <div className={"px-0 "}>
                <SubOrganizationDetails orgId={orgId}/>
            </div>
        </div>

    </>)
}
export default OrganizationsDetails;