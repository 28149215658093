import {Form} from "react-bootstrap"
import Modal from 'react-bootstrap/Modal';
import './ResetPassword.css'
import {useState} from "react";
import {getDataFromLocalStorage, isNullOrEmpty, isUnauthorized, showToast} from "../../../helpers/utils";
import {useDispatch, useSelector} from "react-redux";
import {resetPassword} from "../../../reduxStore/usersSlices/userSlice";
import {TailSpinLoader} from "../../layout/Loader";
import {useNavigate} from "react-router-dom";
import {
    ConfirmPassword,
    CurrentPasswordRequired,
    PasswordMisMatched,
    PasswordRequired,
    WeakPassword
} from "../../../helpers/constants";
import {IoIosClose} from "react-icons/io";

const ResetPassword = ({show, setShow}) => {
    const handleClose = () => setShow(false);
    const users = useSelector(state => state.users)
    const [currentPassword, setCurrentPassword] = useState("")
    const [showCurrentPassword, setShowCurrentPassword] = useState(false)

    const [newPassword, setNewPassword] = useState("")
    const [showNewPassword, setShowNewPassword] = useState(false)

    const [confirmPassword, setConfirmPassword] = useState("")
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const handleOnSubmit = (e) => {
        e.preventDefault();
        let message;
        // Validate Form Data
        if (isNullOrEmpty(currentPassword)) {
            message = CurrentPasswordRequired;
        } else if (isNullOrEmpty(newPassword)) {
            message = PasswordRequired;
        } else if (newPassword.length < 8) {
            message = WeakPassword
        } else if (isNullOrEmpty(confirmPassword)) {
            message = ConfirmPassword;
        } else if (newPassword !== confirmPassword) {
            message = PasswordMisMatched;
        }
        if (message) {
            showToast(message,"error")
        } else {
            dispatch(resetPassword({
                userId: getDataFromLocalStorage("loggedInUserInfo").userId,
                oldPassword: currentPassword,
                password: newPassword
            }))
                .then((res) => {
                    if (isUnauthorized(res)) {
                        navigate("/")
                    }
                    // Move to Login Page after password reset successfully
                    if (res.meta.requestStatus === "fulfilled") {
                        setShow(false)
                        localStorage.clear();
                        navigate("/")
                    }
                });
        }


    }
    return (
        <>


            <Modal show={show} centered className="mt-5">
                <Modal.Header>
                    <Modal.Title
                        className="mediumFontSize repeat_pass">Reset Password</Modal.Title>

                        <IoIosClose onClick={handleClose} size={24}/>
                        {/*<img src={close_square} alt="" width={"15"}/>*/}


                </Modal.Header>

                <Modal.Body className="modal_outer">
                    <Form className="reset_form reset-password-form" onSubmit={handleOnSubmit}>
                        <Form.Group className="position-relative relative_path mb-3 "
                                    controlId="exampleForm.ControlInput1 ">
                            <Form.Label className='label mediumFontSize'>Current Password*</Form.Label>
                            <Form.Control type={showCurrentPassword ? "text" : "password"}
                                          className={"reset-password-form-input"}
                                          value={currentPassword} required={true} placeholder="*****"
                                          onChange={(e) => {
                                              setCurrentPassword(e.target.value)
                                          }}/>
                            <div onClick={() => {
                                isNullOrEmpty(currentPassword) ? setShowCurrentPassword(showCurrentPassword) : setShowCurrentPassword(!showCurrentPassword)
                            }}>
                                {
                                    showCurrentPassword ? <i className={"fa fa-eye"}></i>
                                        : <i className={"fa fa-eye-slash"}></i>
                                }
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3 position-relative relative_path"
                                    controlId="exampleForm.ControlInput1">
                            <Form.Label className='label mediumFontSize'>New Password*</Form.Label>
                            <Form.Control value={newPassword} required={true} placeholder="*****"
                                          className={"reset-password-form-input"}
                                          type={showNewPassword ? "text" : "password"} onChange={(e) => {
                                setNewPassword(e.target.value)
                            }}/>
                            <div onClick={() => {
                                isNullOrEmpty(newPassword) ? setShowNewPassword(showNewPassword) : setShowNewPassword(!showNewPassword)
                            }}>
                                {
                                    showNewPassword ? <i className={"fa fa-eye"}></i>
                                        : <i className={"fa fa-eye-slash"}></i>
                                }
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3 position-relative relative_path"
                                    controlId="exampleForm.ControlInput1">
                            <Form.Label className='label mediumFontSize'>Confirm Password*</Form.Label>
                            <Form.Control value={confirmPassword} required={true} placeholder="*****"
                                          className={"reset-password-form-input"}
                                          type={showConfirmPassword ? "text" : "password"} onChange={(e) => {
                                setConfirmPassword(e.target.value)
                            }}/>
                            <div onClick={() => {
                                isNullOrEmpty(confirmPassword) ? setShowConfirmPassword(showConfirmPassword) : setShowConfirmPassword(!showConfirmPassword)
                            }}>
                                {
                                    showConfirmPassword ? <i className={"fa fa-eye "}></i>
                                        : <i className={"fa fa-eye-slash "}></i>
                                }
                            </div>
                        </Form.Group>

                    </Form>


                </Modal.Body>

                <Modal.Footer className="modal_footer">
                    <button className="modal_cancel_btn cmn_modal_btn mediumFontSize" onClick={handleClose}>Cancel
                    </button>
                    <button style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color"):""} } onClick={handleOnSubmit}
                            disabled={users.resetPasswordReducer?.loading}
                            className="modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize">Reset
                        {
                            users.resetPasswordReducer?.loading && <TailSpinLoader></TailSpinLoader>
                        }
                    </button>
                </Modal.Footer>
            </Modal>


        </>
    )


}
export default ResetPassword;



