import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {getDataFromLocalStorage, isUnauthorized} from "../../helpers/utils";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {roleHasAccess} from "../../helpers/accessControl";
import ErrorPage from "../errorPage/ErrorPage";
import {AccessDenied, AccessDeniedMsg} from "../../helpers/constants";
import {Roles} from "../../helpers/accessControl";
import {useDispatch, useSelector} from "react-redux";
import {getTeamById} from "../../reduxStore/teamSlices/teamSlice";
import {Loader} from "../layout/Loader";
import TeamMembers from "./TeamMembers";
import AssignedReflections from "./AssignedReflections";
import TeamAttempts from "./TeamAttempts";
import TeamReport from "./TeamReport";

const TeamDetail = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {teamId} = useParams();
    const getLoggedInUserData = getDataFromLocalStorage("loggedInUserInfo")

    const getTeamByIdData = useSelector(state => state.team.getTeamByIdReducer)
    const [activeTabIndex, setActiveTabIndex] = useState(0)

    useEffect(() => {
        if (teamId !== null && teamId !== undefined && (roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER, Roles.SUB_ORG_ADMIN]) || getLoggedInUserData?.teamIdsUserLeads?.includes(teamId))) {
            dispatch(getTeamById(teamId)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }
    }, [teamId]);

    const handleSelect = (activeIndex) => {
        setActiveTabIndex(activeIndex)
    }

    return getTeamByIdData?.loading ? <Loader/>:

    (roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER, Roles.SUB_ORG_ADMIN]) || getLoggedInUserData?.teamIdsUserLeads?.includes(teamId)) ?
        (
            <>
                <div className={"row main_content box_design1"}>
                    <div className="sub-organization-details mt-2 mb-3">

                        <div
                            style={{color: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                            className={"sub-org-data mediumFontSize "}
                            onClick={() => {
                                navigate(-1)
                            }}>
                        <span className={"sub-org-back-btn "}
                              style={{background: (localStorage.getItem("color") !== "undefined" && localStorage.getItem("color") !== null && localStorage.getItem("color") !== undefined) ? localStorage.getItem("color") : "#fc6620"}}>
                            <i className="fa fa-solid fa-arrow-left"/>&nbsp;&nbsp;
                        </span>{getTeamByIdData?.data?.teamName}


                        </div>

                    </div>
                    <div className={"tabs-container px-0"}>
                        <Tabs className={"tabs-outer"} onSelect={handleSelect}>
                            <TabList>
                                <Tab style={activeTabIndex === 0 ? {backgroundColor: (localStorage.getItem("color") !== "undefined" && localStorage.getItem("color") !== undefined && localStorage.getItem("color") !== null) ? localStorage.getItem("color") : "#fc6620"} : {}}>Team Members</Tab>
                                <Tab style={activeTabIndex === 1 ? {backgroundColor: (localStorage.getItem("color") !== "undefined" && localStorage.getItem("color") !== undefined && localStorage.getItem("color") !== null) ? localStorage.getItem("color") : "#fc6620"} : {}}>Reflections</Tab>
                                <Tab style={activeTabIndex === 2 ? {backgroundColor: (localStorage.getItem("color") !== "undefined" && localStorage.getItem("color") !== undefined && localStorage.getItem("color") !== null) ? localStorage.getItem("color") : "#fc6620"} : {}}>Attempts</Tab>
                                <Tab style={activeTabIndex === 3 ? {backgroundColor: (localStorage.getItem("color") !== "undefined" && localStorage.getItem("color") !== undefined && localStorage.getItem("color") !== null) ? localStorage.getItem("color") : "#fc6620"} : {}}>Reports</Tab>
                            </TabList>

                            <TabPanel>
                                <div style={{borderTop: `2px solid ${(localStorage.getItem("color") !== "undefined" && localStorage.getItem("color") !== undefined && localStorage.getItem("color") !== null) ? localStorage.getItem("color") : "#fc6620"}`}}>
                                    <TeamMembers teamId={teamId} />
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div style={{borderTop: `2px solid ${(localStorage.getItem("color") !== "undefined" && localStorage.getItem("color") !== undefined && localStorage.getItem("color") !== null) ? localStorage.getItem("color") : "#fc6620"}`}}>
                                    <AssignedReflections teamId={teamId} />
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div style={{borderTop: `2px solid ${(localStorage.getItem("color") !== "undefined" && localStorage.getItem("color") !== undefined && localStorage.getItem("color") !== null) ? localStorage.getItem("color") : "#fc6620"}`}}>
                                    <TeamAttempts teamId={teamId} />
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div style={{borderTop: `2px solid ${(localStorage.getItem("color") !== "undefined" && localStorage.getItem("color") !== undefined && localStorage.getItem("color") !== null) ? localStorage.getItem("color") : "#fc6620"}`}}>
                                    <TeamReport teamId={teamId} />
                                </div>
                            </TabPanel>

                        </Tabs>
                    </div>
                </div>
            </>
        ) : <ErrorPage value={403} description={AccessDenied} detailDesc={AccessDeniedMsg}/>;
}
export default TeamDetail