import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {QuestionType, Survey, PulsePlans, defaultQuestionForReflection} from "../../../helpers/constants";
import {
    getDataFromLocalStorage,
    isNullOrEmpty,
    isUnauthorized,
    validatePublishSurvey,
    validateSurveySubmit,
    trim,
    groupQuestionsBySegmentIndex,
    showToast,
    moveArrayElements,
    removeItemFromArrayAtIndex,
    addItemAtIndex,
} from "../../../helpers/utils";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import {
    createSurvey,
    publishSurvey,
    shareSurvey,
    updateSurveyById
} from "../../../reduxStore/surveySlices/surveySlices";
import {Col, Form, Row} from "react-bootstrap";
import {TailSpinLoader} from "../../layout/Loader";
import {HiPencil} from "react-icons/hi";
import {hasPermission, Permissions, Roles, roleHasAccess} from "../../../helpers/accessControl";
import jwtDecode from "jwt-decode";
import ConfirmationModel from "../../commons/ConfirmationModel";
import {Tooltip as ReactTooltip} from "react-tooltip";
import ShareReport from "../../reports/Modal/ShareReport";
import DraggableQuestions from "../DraggableQuestions";
import { FaTrash} from "react-icons/fa";
import {ImPencil} from "react-icons/im";
import SectionUpdate from "../Modal/SectionUpdate";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import UserInfo from "../../layout/UserInfo";

const AddAndUpdateSurvey = ({data = null, formType}) => {

    const [tags, setTags] = useState([]);
    const [email, setEmail] = useState("");
    const [parentId, setParentId] = useState(null);
    const [showModalDeleteSection, setShowModalDeleteSection] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [removedId, setRemovedId] = useState(null);
    const [goals, setGoals] = useState([]);
    const [reports, setReports] = useState([]);
    // Set Tags and goals for Survey
    const [surveyTags, setSurveyTags] = useState("");
    const [surveyGoals, setSurveyGoals] = useState("");
    const [enableReflectionEditing, setEnableReflectionEditing] = useState([]);
    const [selectedMembersToSendEmailTo, setSelectedMembersToSendEmailTo] = useState([]);

    const [editSurveyTitle, setEditSurveyTitle] = useState(false);
    const [sectionToggle, setSectionToggle] = useState(false);
    const surveyData = useSelector(state => state.survey)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showPublishSurveyConfirmationModal, setShowPublishSurveyConfirmationModal] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [segmentType, setSegmentType] = useState("LINEAR")
    const [reportShare, isReportShare] = useState(false)
    const [reflectionSegments, setReflectionSegments] = useState([]);
    const [activeQuestionIndex, setActiveQuestionIndex] = useState({
        rowIndex: formType === "Add" ? 0 : null,
        columnIndex: null
    })
    const [survey, setSurvey] = useState({
        name: "",
        planLevel: "",
        status: Survey.SURVEY_STATUS_DRAFT,
        description: "",
        tags: [],
        goals: [],
        segmentType: '',
        visibility: "TENANCY",
        segments: [],
        questions: [],
    });
    const [questionsList, setQuestionsList] = useState([defaultQuestionForReflection]);

    useEffect(() => {
        if (segmentType === "LINEAR") {
            setActiveQuestionIndex({
                ...activeQuestionIndex,
                rowIndex: activeQuestionIndex.columnIndex,
                columnIndex: null,
            })
            if (tags?.length > 0) {
                setTags(tags.flat(1))
            }
            if (goals?.length > 0) {
                setGoals(tags.flat(1))
            }
        }
        if (segmentType === "TABULAR") {
            setActiveQuestionIndex({
                ...activeQuestionIndex,
                rowIndex: 0,
                columnIndex: activeQuestionIndex.rowIndex,
            })
            if (tags?.length > 0) {
                setTags([tags])
            }
            if (goals?.length > 0) {
                setGoals([goals])
            }
        }
    }, [segmentType]);
    useEffect(() => {
        if (formType === "Update" || formType === "Edit") {
            setSurvey(data)
            setEditSurveyTitle(false)
            setSegmentType(data?.segmentType)
            setReflectionSegments(groupQuestionsBySegmentIndex(data?.segments, data.questions))
            setQuestionsList(data.questions)
            setEnableReflectionEditing(Array(data.questions.length).fill(false))
        }
    }, []);
    useEffect(() => {
        if (reportShare) {
            dispatch(shareSurvey({email: email.map(c => c?.value).concat(selectedMembersToSendEmailTo?.map(c=>c?.value)), surveyId: data.surveyId})).then((res) => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                if (res.meta.requestStatus === "fulfilled") {
                    isReportShare(false)
                    navigate("/reflections")
                }
            })
        }

    }, [reportShare])

    const removeSurveyTags = (index) => {
        const updatedArray = [...survey.tags]
        updatedArray.splice(index, 1);
        setSurvey({...survey, tags: updatedArray})
    }
    const removeSurveyGoals = (index) => {
        const updatedArray = [...survey.goals]
        updatedArray.splice(index, 1);
        setSurvey({...survey, goals: updatedArray})
    }

    const handlePublishSurvey = () => {
        // const updatedSurveyData = {...survey, questions: questionsList, name: survey.name.trim()};

        const updatedSurveyData = {
            ...survey,
            planLevel: survey.planLevel ? survey.planLevel : null,
            segmentType: segmentType,
            segments: reflectionSegments,
            questions: segmentType === "LINEAR" ? questionsList : reflectionSegments?.map(cur => cur.questionsList).flat(),
            name: survey.name.trim()
        };
        dispatch(publishSurvey(updatedSurveyData)).then((res) => {
            if (isUnauthorized(res)) {
                navigate("/")
            }
            if (res.meta.requestStatus === "fulfilled") {
                navigate("/reflections")
            }
        })
    }
    const handlePublishSurveyValidation = () => {
        if (formType === "Update" || formType === "Edit") {
            let message = validatePublishSurvey(survey, segmentType, questionsList, reflectionSegments, roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER]) && jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === "SYSTEM" && getDataFromLocalStorage("loggedInUserInfo").orgId === data.orgId)
            if (message) {
                showToast(message, "error")
            } else {
                setShowPublishSurveyConfirmationModal(true)
            }
        }
    }

    const handleTagChange = (value, index) => {
        const updatedTags = [...tags]
        updatedTags[index] = value;
        setTags(updatedTags)
    };
    const handleGoalChange = (value, index) => {
        const updatedGoals = [...goals]
        updatedGoals[index] = value;
        setGoals(updatedGoals)
    };


    const handleKeyDown = (e, index) => {
        if (e.key === "Enter") {
            if (index === -1) {
                if (!isNullOrEmpty(e.target.value.trim()) && !survey[e.target.name].includes(e.target.value.trim())) {
                    setSurvey({...survey, [e.target.name]: [...survey[e.target.name], e.target.value.trim()]});
                }
                e.target.name === "tags" ? setSurveyTags("") : setSurveyGoals("")
            } else {
                const updatedQuestionsList = [...questionsList];
                const question = {...updatedQuestionsList[index]};

                // Check if the value already exists in the target name array
                if (!isNullOrEmpty(e.target.value.trim()) && !question[e.target.name].includes(e.target.value.trim())) {
                    question[e.target.name] = [...question[e.target.name], e.target.value.trim()];
                }
                updatedQuestionsList[2] = question;

                setQuestionsList(updatedQuestionsList);

                e.target.name === "tags" ? handleTagChange("", index) : handleGoalChange("", index)
            }


        }
    };

    const [isToggled, setIsToggled] = useState(Array.from({length: 5}, () => false));

    const toggle = (index, segmentIndex) => {

        if (segmentIndex !== undefined) {
            setParentId(segmentIndex)
            const updatedReflectionSegments = reflectionSegments.map((ref, refIndex) => {

                const filteredQuestions = ref.questionsList.map((cur, at) => {

                    if (refIndex === activeQuestionIndex?.rowIndex && at === activeQuestionIndex?.columnIndex && tags?.[activeQuestionIndex?.rowIndex]?.[activeQuestionIndex?.columnIndex] !== null && tags?.[activeQuestionIndex?.rowIndex]?.[activeQuestionIndex?.columnIndex] !== undefined && tags?.[activeQuestionIndex?.rowIndex]?.[activeQuestionIndex?.columnIndex] !== "") {
                        // Check if the value already exists in the target name array
                        if (tags?.[activeQuestionIndex?.rowIndex]?.[activeQuestionIndex?.columnIndex] !== null && tags?.[activeQuestionIndex?.rowIndex]?.[activeQuestionIndex?.columnIndex] !== undefined && tags?.[activeQuestionIndex?.rowIndex]?.[activeQuestionIndex?.columnIndex] !== "" && !cur?.tags?.includes(tags?.[activeQuestionIndex?.rowIndex]?.[activeQuestionIndex?.columnIndex])) {
                            cur.tags = [...cur.tags, tags?.[activeQuestionIndex?.rowIndex]?.[activeQuestionIndex?.columnIndex]?.trim()];
                        }
                        let updatedTags = tags?.map(row => [...row]);
                        updatedTags[activeQuestionIndex?.rowIndex][activeQuestionIndex?.columnIndex] = ""
                        setTags(updatedTags)
                    }
                    if (refIndex === activeQuestionIndex?.rowIndex && at === activeQuestionIndex?.columnIndex && goals?.[activeQuestionIndex?.rowIndex]?.[activeQuestionIndex?.columnIndex] !== null && goals?.[activeQuestionIndex?.rowIndex]?.[activeQuestionIndex?.columnIndex] !== undefined && goals?.[activeQuestionIndex?.rowIndex]?.[activeQuestionIndex?.columnIndex] !== "") {
                        // Check if the value already exists in the target name array
                        if (goals?.[activeQuestionIndex?.rowIndex]?.[activeQuestionIndex?.columnIndex] !== null && goals?.[activeQuestionIndex?.rowIndex]?.[activeQuestionIndex?.columnIndex] !== undefined && goals?.[activeQuestionIndex?.rowIndex]?.[activeQuestionIndex?.columnIndex] !== "" && !cur?.goals?.includes(goals?.[activeQuestionIndex?.rowIndex]?.[activeQuestionIndex?.columnIndex])) {
                            cur.goals = [...cur.goals, goals?.[activeQuestionIndex?.rowIndex]?.[activeQuestionIndex?.columnIndex]?.trim()];
                        }
                        let updatedGoals = goals?.map(row => [...row]);
                        updatedGoals[activeQuestionIndex?.rowIndex][activeQuestionIndex?.columnIndex] = ""
                        setGoals(updatedGoals)
                    }
                    if (segmentIndex !== activeQuestionIndex?.rowIndex || index !== activeQuestionIndex?.columnIndex) {
                        setActiveQuestionIndex({
                            ...activeQuestionIndex,
                            rowIndex: segmentIndex,
                            columnIndex: index
                        })
                    }

                    if (at === index && segmentIndex === refIndex) {
                        return {...cur, isShow: true};
                    }
                    return {...cur, isShow: false};
                });
                return {
                    ...ref,
                    questionsList: filteredQuestions
                };

                return ref;
            });
            setReflectionSegments(updatedReflectionSegments);
        } else {

            if (index !== activeQuestionIndex?.rowIndex) {
                setActiveQuestionIndex({
                    ...activeQuestionIndex,
                    rowIndex: index,
                    columnIndex: null
                })
            }
            const filteredQuestions = questionsList.map((cur, at) => {
                if (at === activeQuestionIndex?.rowIndex && tags?.[activeQuestionIndex?.rowIndex] !== null && tags?.[activeQuestionIndex?.rowIndex] !== undefined && tags?.[activeQuestionIndex?.rowIndex] !== "") {
                    // Check if the value already exists in the target name array
                    if (!isNullOrEmpty(tags[activeQuestionIndex?.rowIndex].trim()) && !cur?.tags?.includes(tags[activeQuestionIndex?.rowIndex].trim())) {
                        cur.tags = [...cur.tags, tags[activeQuestionIndex?.rowIndex].trim()];
                    }
                    handleTagChange("", activeQuestionIndex?.rowIndex)
                }
                if (at === activeQuestionIndex?.rowIndex && goals?.[activeQuestionIndex?.rowIndex] !== null && goals?.[activeQuestionIndex?.rowIndex] !== undefined && goals?.[activeQuestionIndex?.rowIndex] !== "") {
                    // Check if the value already exists in the target name array
                    if (!isNullOrEmpty(goals[activeQuestionIndex?.rowIndex].trim()) && !cur?.goals?.includes(goals[activeQuestionIndex?.rowIndex].trim())) {
                        cur.goals = [...cur.goals, goals[activeQuestionIndex?.rowIndex].trim()];
                    }
                    handleGoalChange("", activeQuestionIndex?.rowIndex)
                }
                if (at === index) {
                    return {...cur, isShow: true}
                }
                return {...cur, isShow: false};
            });
            setQuestionsList(filteredQuestions);
        }

        setIsToggled(prevState => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    };

    const handleSelectedParentSection = (queInd, segInd, questionsList) => {
        setParentId(null);
        setQuestionsList(questionsList)
        setSectionToggle(false)
        setRemovedId(null)
    }

    const addSection = () => {
        setReflectionSegments([...reflectionSegments, {
            segmentName: '', segmentDescription: "", questionsList: [{
                questionText: "",
                isTextLimitExceeded: false,
                type: QuestionType.OPTION,
                isRequired: true,
                sortOrder: 0,
                segmentIndex: null,
                options: [{optionText: "", optionValue: null}, {optionText: "", optionValue: null}, {
                    optionText: "",
                    optionValue: null
                }],
                number: {},
                tags: [],
                goals: []
            }]
        }])
    }
    const removeSection = () => {

        if (removedId !== null) {
            const filteredSegments = reflectionSegments.filter((cur, at) => at !== removedId)
            if (filteredSegments.length > 1) {
                setReflectionSegments(filteredSegments)
                setShowModalDeleteSection(false)
                setRemovedId(null)
            } else {
                filteredSegments?.map((cur) => {
                    setQuestionsList(cur.questionsList)
                })
                setSegmentType("LINEAR")
                setParentId(null);
                setShowModalDeleteSection(false)
                setSectionToggle(false)
                setRemovedId(null)
            }
        }
    }

    const callBack = useCallback((index, sectionToggle, segmentType, questionsList) => {

        if (sectionToggle) {
            let defaultQuestion = defaultQuestionForReflection;
            defaultQuestion.isShow = false;
            setReflectionSegments([
                {segmentName: '', segmentDescription: "", questionsList: questionsList, isShow: false},
                {segmentName: '', segmentDescription: "", questionsList: [defaultQuestion], isShow: false}
            ]);
            setParentId(index);
            setSegmentType(segmentType)
        } else {
            setParentId(null);
            setReflectionSegments([]);
            setQuestionsList(questionsList)
            setSegmentType(segmentType)
        }
        setSectionToggle(sectionToggle)
    }, [parentId, reflectionSegments, sectionToggle]);

    const handleSurveyChange = (e) => {
        setSurvey({...survey, [e.target.name]: e.target.value === "" ? null : e.target.value})
    };
    const checkBtnPermission = (Id) => {
        return ((formType === "Add" && hasPermission([Permissions.REFLECTION_CREATE], true)) || ((formType === "Update") && hasPermission([Permissions.REFLECTION_WRITE], true) && (data.orgId === jwtDecode(getDataFromLocalStorage("token"))?.orgId || roleHasAccess([Roles.SYS_ADMIN])))
            || (formType === "Edit" && data.enable && hasPermission([Permissions.REFLECTION_WRITE], true) && (data.orgId === jwtDecode(getDataFromLocalStorage("token"))?.orgId || roleHasAccess([Roles.SYS_ADMIN])) && typeof Id === "undefined")
        )
    };
    const handleSurveySubmit = () => {
        let message = validateSurveySubmit(survey, segmentType, questionsList, reflectionSegments)
        if (message) {
            showToast(message, "error")
        } else {
            const updatedSurveyData = {
                ...survey,
                planLevel: survey.planLevel ? survey.planLevel : null,
                segmentType: segmentType,
                segments: reflectionSegments,
                questions: segmentType === "LINEAR" ? questionsList : reflectionSegments?.map(cur => cur.questionsList).flat(),
                name: survey.name.trim()
            };
            dispatch(formType === "Add" ? createSurvey(updatedSurveyData) : updateSurveyById(updatedSurveyData)).then((res) => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                if (res.meta.requestStatus === "fulfilled") {
                    formType === "Add" ? navigate(`/reflections/${res.payload.data}`) : navigate(`/reflections/${data.surveyId}`)
                }
            })

        }
    }

    const onDragEnd = (result) => {
        const {source, destination} = result;

        if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) {
            return;
        }
        const sourceDroppableIndex = parseInt(source.droppableId.split("-")[1]);
        const destinationDroppableIndex = destination.droppableId ? parseInt(destination.droppableId.split("-")[1]) : 0;
        if (destinationDroppableIndex !== null) {
            if (source.droppableId === destination.droppableId) {

                if (segmentType === "TABULAR") {
                    let updatedTags = tags?.map(row => [...row]);
                    let movedTag = updatedTags[destinationDroppableIndex] || []
                    moveArrayElements([...movedTag], source.index, destination.index)
                    updatedTags[destinationDroppableIndex] = moveArrayElements([...movedTag], source.index, destination.index)
                    setTags(updatedTags)

                    let updatedGoals = goals?.map(row => [...row]);
                    let movedGoal = updatedGoals[destinationDroppableIndex] || []
                    moveArrayElements([...movedGoal], source.index, destination.index)
                    updatedGoals[destinationDroppableIndex] = moveArrayElements([...movedGoal], source.index, destination.index)
                    setGoals(updatedGoals)

                    const updatedQuestions = reflectionSegments[sourceDroppableIndex]?.questionsList ? [...reflectionSegments[sourceDroppableIndex].questionsList] : [];

                    const [removed] = updatedQuestions.splice(source.index, 1);
                    updatedQuestions.splice(destination.index, 0, removed);

                    const updatedSegments = [...reflectionSegments];
                    updatedSegments[sourceDroppableIndex] = {
                        ...updatedSegments[sourceDroppableIndex],
                        questionsList: updatedQuestions
                    };
                    if (activeQuestionIndex.rowIndex === sourceDroppableIndex && activeQuestionIndex.columnIndex === source.index) {
                        setActiveQuestionIndex({
                            ...activeQuestionIndex,
                            rowIndex: destinationDroppableIndex,
                            columnIndex: destination.index,
                        })
                    } else {
                        const originalElement = reflectionSegments[activeQuestionIndex?.rowIndex]?.questionsList[activeQuestionIndex?.columnIndex];
                        const newIndex = updatedQuestions.findIndex(
                            (element) => element?.questionText === originalElement?.questionText && element?.type === originalElement?.type
                        );
                        setActiveQuestionIndex({
                            ...activeQuestionIndex,
                            rowIndex: destinationDroppableIndex,
                            columnIndex: newIndex,
                        })
                    }

                    setReflectionSegments(updatedSegments);

                } else {

                    setTags(moveArrayElements([...tags], result.source.index, result.destination.index))
                    setGoals(moveArrayElements([...goals], result.source.index, result.destination.index))

                    const updatedCharacters = [...questionsList];
                    const [movedCharacter] = updatedCharacters.splice(result.source.index, 1);
                    updatedCharacters.splice(result.destination.index, 0, movedCharacter);

                    if (activeQuestionIndex.rowIndex === result.source.index) {
                        setActiveQuestionIndex({
                            ...activeQuestionIndex,
                            rowIndex: result.destination.index,
                            columnIndex: null,
                        })
                    } else {
                        const originalElement = {
                            ...questionsList[activeQuestionIndex.rowIndex]
                        };
                        const newIndex = updatedCharacters.findIndex(element => element.questionText === originalElement.questionText && element.type === originalElement.type);
                        setActiveQuestionIndex({
                            ...activeQuestionIndex,
                            rowIndex: newIndex,
                            columnIndex: null,
                        })
                    }
                    setQuestionsList(updatedCharacters);
                }
            } else {

                let updatedTags = tags?.map(row => [...row]);
                let removeFromArray = updatedTags[sourceDroppableIndex] || []
                let insertIntoArray = updatedTags[destinationDroppableIndex] || []
                const updatedRemovedArray = removeItemFromArrayAtIndex(removeFromArray, source.index)
                const updatedInsertIntoArray = addItemAtIndex(insertIntoArray, destination.index, updatedTags?.[sourceDroppableIndex]?.[source.index])
                updatedTags[sourceDroppableIndex] = updatedRemovedArray
                updatedTags[destinationDroppableIndex] = updatedInsertIntoArray
                setTags(updatedTags)

                let updatedGoals = goals?.map(row => [...row]);
                let removeFromArrayGoals = updatedGoals[sourceDroppableIndex] || []
                let insertIntoArrayGoals = updatedGoals[destinationDroppableIndex] || []
                const updatedRemovedArrayGoals = removeItemFromArrayAtIndex(removeFromArrayGoals, source.index)
                const updatedInsertIntoArrayGoals = addItemAtIndex(insertIntoArrayGoals, destination.index, updatedGoals?.[sourceDroppableIndex]?.[source.index])
                updatedGoals[sourceDroppableIndex] = updatedRemovedArrayGoals
                updatedGoals[destinationDroppableIndex] = updatedInsertIntoArrayGoals
                setGoals(updatedGoals)

                const sourceQuestions = [...reflectionSegments[sourceDroppableIndex].questionsList];
                const destQuestions = [...reflectionSegments[destinationDroppableIndex].questionsList];

                const [removed] = sourceQuestions.splice(source.index, 1);
                destQuestions.splice(destination.index, 0, removed);

                const updatedSegments = [...reflectionSegments];
                updatedSegments[sourceDroppableIndex] = {
                    ...updatedSegments[sourceDroppableIndex],
                    questionsList: sourceQuestions
                };
                updatedSegments[destinationDroppableIndex] = {
                    ...updatedSegments[destinationDroppableIndex],
                    questionsList: destQuestions
                };
                // If The Dragged Question is Active
                if (activeQuestionIndex.rowIndex === sourceDroppableIndex && activeQuestionIndex.columnIndex === source.index) {
                    setActiveQuestionIndex({
                        ...activeQuestionIndex,
                        rowIndex: destinationDroppableIndex,
                        columnIndex: destination.index,
                    })
                }else{
                    const originalElement = reflectionSegments[activeQuestionIndex?.rowIndex]?.questionsList[activeQuestionIndex?.columnIndex];
                    const index= updatedSegments[activeQuestionIndex?.rowIndex]?.questionsList?.findIndex(element => element?.questionText === originalElement?.questionText && element?.type === originalElement?.type);
                    setActiveQuestionIndex({
                        ...activeQuestionIndex,
                        rowIndex: activeQuestionIndex?.rowIndex,
                        columnIndex: index,
                    })
                }
                setReflectionSegments(updatedSegments);

            }
        }
    };

    return (
        <section className={"reflection_wrapper"}>
            <div className="cmn_header">
                <h2 className="mediumFontSize  org-heading m-0">{formType + ' Reflection'}  </h2> <UserInfo/>
            </div>
            <div className='save-survey-outer main_content'>
                <div className={"save_survey_container mediumFontSize "}>
                    <div className={formType === "Add" ? "survey-name-outer mt-2 ms-2" : "survey-name-outer mt-2"}>

                        {
                            formType === "Add" ? <>
                                <div className={"survey-name-input"}>
                                    <Form.Group className='spiritual_pathways2  mt-1'>
                                        <Form.Control name={"name"} value={survey.name} onChange={(e) => {
                                            if (hasPermission([Permissions.REFLECTION_CREATE], true)) {
                                                handleSurveyChange(e)
                                            }
                                        }}
                                                      className={"survey-name-input"} type="text"
                                                      disabled={!hasPermission([Permissions.REFLECTION_CREATE], true)}
                                                      placeholder="Enter Reflection Name"/>
                                    </Form.Group>
                                </div>
                            </> : <>
                                {
                                    !editSurveyTitle ?
                                        <div className={"survey-title-container"}>
                                            {survey.name.length > 23 ?
                                                <h3 className={"survey-name-heading mediumFontSize"}
                                                    title={survey.name}>{survey.name}</h3>
                                                :

                                                <h3 className={"survey-name-heading-withOut-width mediumFontSize"}
                                                    title={survey.name}>{survey.name}</h3>
                                            }

                                            {
                                                hasPermission([Permissions.REFLECTION_WRITE], true) &&
                                                <HiPencil
                                                    className={"edit-survey-title-btn " + (checkBtnPermission() ? "" : "disable-edit-reflection-name-btn")}
                                                    onClick={() => {
                                                        if (checkBtnPermission()) {
                                                            setEditSurveyTitle(true)
                                                        }

                                                    }}/>
                                            }

                                        </div>

                                        :
                                        <>
                                            <div className={"survey-name-input "}>
                                                <Form.Group className='spiritual_pathways2  mt-1'>
                                                    <Form.Control name={"name"} value={survey.name}
                                                                  onChange={(e) => {
                                                                      if (checkBtnPermission() === true) {
                                                                          handleSurveyChange(e)
                                                                      }
                                                                  }}
                                                                  disabled={checkBtnPermission() === false}
                                                                  className={"survey-name-input"} type="text"
                                                                  placeholder="Enter Reflection Name"/>
                                                </Form.Group>
                                            </div>
                                        </>

                                }
                            </>
                        }
                    </div>

                    <div className={"reflection-type-and-submit-btn"}>
                        {
                            (roleHasAccess([Roles.ORG_ADMIN]) && jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === "SYSTEM" && (formType === "Add" || ((formType === "Update" || formType === "Edit") && getDataFromLocalStorage("loggedInUserInfo").orgId === data.orgId))) &&
                            <div className={"reflection-type-dropdown-outer "}>
                                <select onChange={handleSurveyChange} name={"planLevel"} value={survey.planLevel}
                                        className={"form-select reflection-type-dropdown"}>
                                    <option value={""}>Select Plan Level</option>
                                    {
                                        Object.keys(PulsePlans).map((plan, index) => {
                                            return (
                                                <option key={index}
                                                        value={PulsePlans[plan].ordinal}>{PulsePlans[plan].value}</option>
                                            );
                                        })
                                    }
                                </select>
                            </div>
                        }
                        {
                            checkBtnPermission() === true && survey.status !== Survey.SURVEY_STATUS_PUBLISHED && <>
                                <button
                                    style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                    disabled={(surveyData?.createSurveyDataReducer?.loading || surveyData?.updateSurveyByIdDataReducer?.loading) === true}
                                    className='modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize submit-survey-btn mt-2'
                                    onClick={handleSurveySubmit}>Submit
                                    {
                                        (surveyData?.createSurveyDataReducer?.loading || surveyData?.updateSurveyByIdDataReducer?.loading) &&
                                        <TailSpinLoader/>
                                    }
                                </button>
                            </>
                        }
                        {
                            checkBtnPermission() === true && formType === "Edit" && <>
                                <button
                                    style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                    disabled={(surveyData?.publishSurveyDataReducer?.loading) === true}
                                    className={'modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize publish-survey-btn me-2 mt-2'}
                                    onClick={() => {
                                        navigate("/reflections")
                                    }}>Cancel

                                </button>
                            </>
                        }
                        {
                            checkBtnPermission() && <>
                                <button
                                    style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                    disabled={(surveyData?.publishSurveyDataReducer?.loading) === true}
                                    data-tooltip-id="PublishReflectionBTN"
                                    data-tooltip-content={formType === "Add" ? "Please submit reflection first then publish" : ""}
                                    className={'modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize publish-survey-btn mt-2 ' + (formType === "Add" ? "disable-btn" : "")}
                                    onClick={handlePublishSurveyValidation}>{survey.status === Survey.SURVEY_STATUS_PUBLISHED ? "Save" : "Publish"}

                                </button>
                                <ReactTooltip className={"ToolTip"} place={"bottom"} id="PublishReflectionBTN"/>
                                {survey.status === "PUBLISHED" &&
                                    <div className={"share-btn"}>
                                        <i style={{color: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                           className="fas m-auto cursor ms-2 cmn_color_icon"
                                           onClick={() => setShowModal(true)}>&#xf1e0;</i>
                                    </div>

                                }
                            </>

                        }

                    </div>


                </div>

                <div className={"d-flex"}>
                    <div className={"survey-tag-outer mt-1 ms-3 w-50"}>
                        {
                            survey.tags?.map((tag, index) => {
                                return (
                                    <div key={index} className={"survey-tags"}>
                                        <div className={"tag-text"}
                                             title={tag}>{trim(tag, 12)}
                                        </div>
                                        <div className={"tag-cross"} name={"tags"}
                                             onClick={() => {
                                                 if (checkBtnPermission() === true) {

                                                     removeSurveyTags(index)
                                                 }
                                             }}>
                                            <i className={"fa fa-times remove-tag-icon"}/>
                                        </div>
                                    </div>
                                );
                            })
                        }
                        <div className={"add-tag-outer"}>
                            <i style={{color: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                               className={"fa fa-plus add-tag-icon"}/>
                            <input name={"tags"}
                                   value={surveyTags}
                                   onChange={(e) => {
                                       if (checkBtnPermission() === true) {
                                           setSurveyTags(e.target.value)
                                       }
                                   }}
                                   onKeyDown={(e) => {
                                       if (checkBtnPermission() === true) {
                                           handleKeyDown(e, -1)
                                       }
                                   }}
                                   disabled={checkBtnPermission() === false}
                                   placeholder={"Add Reflection Tag"}
                                   className={"tags-input-field "}
                                   type={"text"}/>
                        </div>
                    </div>

                    <div className={"survey-tag-outer mt-1 ms-2 w-50 "}>
                        {
                            survey.goals?.map((goal, index) => {
                                return (
                                    <div key={index} className={"survey-tags"}>
                                        <div className={"tag-text"}
                                             title={goal}>{trim(goal, 12)}
                                        </div>
                                        <div name={"goals"} className={"tag-cross"} onClick={() => {
                                            if (checkBtnPermission() === true) {

                                                removeSurveyGoals(index)
                                            }
                                        }}>
                                            <i className={"fa fa-times remove-tag-icon"}/>
                                        </div>
                                    </div>
                                );
                            })
                        }
                        <div className={"add-tag-outer"}>
                            <i style={{color: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                               className={"fa fa-plus add-tag-icon"}/>
                            <input type="text" className={"tags-input-field"} name={"goals"}
                                   placeholder={"Add Reflection Goal"}
                                   disabled={checkBtnPermission() === false}
                                   value={surveyGoals}
                                   onChange={(e) => {
                                       if (checkBtnPermission() === true) {

                                           setSurveyGoals(e.target.value)
                                       }
                                   }}
                                   onKeyDown={(e) => {
                                       if (checkBtnPermission() === true) {

                                           handleKeyDown(e, -1)
                                       }
                                   }}
                            />

                        </div>
                    </div>
                </div>
            </div>

            <div className={"mt-2"}>
                <>
                    <Row className={"m-0"}>
                        <Col lg={4} md={4} className={"ps-2 p-0"}>

                            <div className="w-100 ms-3 mt-2">

                                <div className='question_tabs_wrapper p-3'>
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                                        <DragDropContext onDragEnd={onDragEnd}>
                                            {
                                                segmentType === "LINEAR" ?
                                                    <Droppable droppableId="linear-1">
                                                        {(provided) => (
                                                            <Nav variant="pills" className="flex-column"
                                                                 ref={provided.innerRef} {...provided.droppableProps}>
                                                                {questionsList.map((ref, index) => (
                                                                    <Draggable key={index}
                                                                               draggableId={`linear-question-${index}`}
                                                                               index={index}>
                                                                        {
                                                                            (provided) =>
                                                                                <div
                                                                                    ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                    <Nav.Item>
                                                                                        <Nav.Link
                                                                                            className={ref.isShow ? !localStorage.getItem("color") ? "" : "active-link" : ""}
                                                                                            style={{background: (localStorage.getItem("color") && ref.isShow) ? localStorage.getItem("color") : ""}}
                                                                                            onClick={() => toggle(index)}>
                                                                                    <span
                                                                                        className='question_count'>{index + 1}</span>
                                                                                            <span
                                                                                                className={"question_name"}>{ref.questionText}</span>
                                                                                        </Nav.Link>
                                                                                    </Nav.Item>
                                                                                </div>
                                                                        }
                                                                    </Draggable>
                                                                ))}
                                                                {provided.placeholder}
                                                                {!sectionToggle && (
                                                                    <>
                                                                        <div className={"add-section"}>
                                                                            <button
                                                                                style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                                className={"cmn_background_color"}
                                                                                onClick={() => callBack(0, true, "TABULAR", questionsList)}> Add
                                                                                Section
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </Nav>
                                                        )}
                                                    </Droppable>
                                                    :
                                                    <>
                                                        {reflectionSegments.map((refSegment, segmentIndex) => (
                                                            <Droppable key={segmentIndex}
                                                                       droppableId={`segment-${segmentIndex}`}>
                                                                {(provided) => (
                                                                    <div
                                                                        ref={provided.innerRef} {...provided.droppableProps}>
                                                                        <div className={"position-relative mb-3 "}>
                                                                            <div className={"section_title"}>
                                                                                <span>{reflectionSegments[segmentIndex]?.segmentName || "Section " + (segmentIndex + 1)}</span>
                                                                                <div className={"segment-details"}>
                                                                                    <div className={"edit-icon"}>
                                                                                        <ImPencil onClick={() => {
                                                                                            setEditModal(true);
                                                                                            setRemovedId(segmentIndex);
                                                                                        }}/>
                                                                                    </div>
                                                                                    {reflectionSegments.length > 1 && (
                                                                                        <div>
                                                                                            <FaTrash onClick={() => {
                                                                                                setShowModalDeleteSection(true);
                                                                                                setRemovedId(segmentIndex);
                                                                                            }}/>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                                {refSegment.questionsList.map((ref, index) => (
                                                                                    <Draggable
                                                                                        key={`question-${segmentIndex}-${index}`}
                                                                                        draggableId={`question-${segmentIndex}-${index}`}
                                                                                        index={index}>
                                                                                        {(provided) => (
                                                                                            <div
                                                                                                ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                                <Nav.Item>
                                                                                                    <Nav.Link
                                                                                                        className={ref.isShow ? !localStorage.getItem("color") ? "" : "active-link" : ""}
                                                                                                        style={{background: (localStorage.getItem("color") && ref.isShow) ? localStorage.getItem("color") : ""}}
                                                                                                        onClick={() => toggle(index, segmentIndex)}>
                                                                                                    <span
                                                                                                        className='question_count'>{index + 1}</span>
                                                                                                        <span
                                                                                                            className={"question_name"}>{ref.questionText}</span>
                                                                                                    </Nav.Link>
                                                                                                </Nav.Item>
                                                                                            </div>
                                                                                        )}
                                                                                    </Draggable>
                                                                                ))}
                                                                                {provided.placeholder}
                                                                                {/* Add section button */}
                                                                                {
                                                                                    reflectionSegments?.length - 1 === segmentIndex && (
                                                                                    <div className={"add-section"}>
                                                                                        <button
                                                                                            className={"cmn_background_color"}
                                                                                            onClick={addSection}
                                                                                            style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}> Add Section
                                                                                        </button>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        ))}
                                                    </>
                                            }
                                        </DragDropContext>
                                    </Tab.Container>
                                </div>
                            </div>

                        </Col>
                        <Col lg={8} md={8} className={"ps-3"}>

                            <div className='SurveyName_container'>
                                <div className={formType === "Add" ? "details_wrapper ms-2" : "details_wrapper ms-2"}>
                                    <DraggableQuestions goals={goals} setGoals={setGoals} tags={tags} setTags={setTags}
                                                        data={data} questionsList={questionsList}
                                                        setQuestionsList={setQuestionsList} formType={formType}
                                                        segmentType={segmentType} setSegmentType={setSegmentType}
                                                        parentId={parentId} reflectionSegments={reflectionSegments}
                                                        setReflectionSegments={setReflectionSegments}
                                                        activeQuestionIndex={activeQuestionIndex}
                                                        setActiveQuestionIndex={setActiveQuestionIndex}
                                                        handleSelectedParentSection={handleSelectedParentSection}/>
                                </div>


                            </div>

                        </Col>
                    </Row>
                </>
            </div>
            {
                showPublishSurveyConfirmationModal &&
                <ConfirmationModel
                    title={survey.status === Survey.SURVEY_STATUS_PUBLISHED ? "Update Reflection" : "Publish Reflection"}
                    btnText={survey.status === Survey.SURVEY_STATUS_PUBLISHED ? "Update" : "Publish"}
                    action={handlePublishSurvey} show={showPublishSurveyConfirmationModal}
                    bodyMessage={survey.status === Survey.SURVEY_STATUS_PUBLISHED ? "Before proceeding, please confirm that you want to update your reflection. Once updated, all changes will be visible to all the active users." : "Before proceeding, please confirm that you want to save your reflection. Once published, all changes will be visible to all the active users."}
                    setShow={setShowPublishSurveyConfirmationModal}
                    isLoading={surveyData?.publishSurveyDataReducer?.loading}
                    showUserAcknowledgementCheckBox={true}
                    userAcknowledgementCheckBoxText={"Yes, I understand"}
                />
            }
            {
                showModalDeleteSection &&
                <ConfirmationModel title={"Delete Section"}
                                   btnText={"Delete"}
                                   action={removeSection} show={showModalDeleteSection}
                                   bodyMessage={"Are you sure you want to delete this section and all its questions?"}
                                   setShow={setShowModalDeleteSection}
                                   isLoading={surveyData?.publishSurveyDataReducer?.loading}
                />
            }
            {
                editModal &&
                <SectionUpdate show={editModal} setShow={setEditModal} indexId={removedId}
                               reflectionSegments={reflectionSegments} setReflectionSegments={setReflectionSegments}/>
            }

            {
                showModal &&
                <ShareReport show={showModal} setShow={setShowModal} setReports={setReports} title={"Share Reflection"}
                             reportShare={reportShare} isReportShare={isReportShare} setEmail={setEmail} email={email} selectedMembersToSendEmailTo={selectedMembersToSendEmailTo} setSelectedMembersToSendEmailTo={setSelectedMembersToSendEmailTo}/>

            }
        </section>
    )
        ;
}
export default AddAndUpdateSurvey
