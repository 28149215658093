import './App.css'
import "react-datepicker/dist/react-datepicker.css";
import "./features/reports/Reports.css"
import {BrowserRouter as Router, Routes, Route, Outlet, Navigate} from 'react-router-dom';
import SignUp from './features/authentication/signUp/SignUp';
import Result from './features/results/Result';
import ResultDetails from './features/results/ResultDetails';
import Member from './features/members/Member';
import Surveys from './features/surveys/Surveys';
import {RoutesUtil} from './helpers/routes';
import Login from './features/authentication/login/Login';
import Dashboard from './features/dashboard/Dashboard';
import ForgetPassword from './features/authentication/forgetPassword/ForgetPassword';
import SurveysDetails from './features/surveys/serveysDetailsPage/SurveysDetails';
import Setting from './features/settings/Setting';
import Sidebar from './features/layout/Sidebar';
import AddSurvey from './features/surveys/AddSurveyPage/AddSurvey';
import {Provider} from 'react-redux';
import store from './reduxStore/store'
import SavedReflection from './features/reflections/SavedReflection';
import NewSurvey from "./features/surveys/newSurveyPages/NewSurvey";
import SurveyName from "./features/surveys/newSurveyPages/ServeyName";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import SignedUpSuccessfully from "./features/authentication/signUp/SignedUpSuccessfully";
import CreatePassword from "./features/authentication/signUp/CreatePassword";
import Roles from "./features/roles/Roles";
import MemberDetails from "./features/members/MemberDetails";
import RolesDetail from "./features/roles/RolesDetail";
import ErrorPage from "./features/errorPage/ErrorPage";
import Organizations from "./features/organizations/organizations/Organizations";
import OrganizationsDetails from "./features/organizations/organizationsDetail/OrganizationsDetails";
import UpdateSurvey from "./features/surveys/updateSurveyPage/UpdateSurvey";
import AnswerSurvey from "./features/members/AnswerSurvey";
import SurveyResultDetail from "./features/members/SurveyResultDetail";
import jwtDecode from "jwt-decode";
import {getDataFromLocalStorage} from "./helpers/utils";
import CreateOrganizationForm from "./features/subOrganizations/CreateOrganizationForm";
import SubOrganizationDetails from "./features/subOrganizations/SubOrganizationDetails";
import UpdateOrgLevel from "./features/organizations/updateOrgLevel/UpdateOrgLevel";
import PublicSurveyAnswer from "./features/members/PublicSurveyAnswer";
import CustomCriteria from "./features/CustomCriteria/CustomCriteria";
import CustomReport from "./features/customReport/CustomReport";
import ReportDetails from "./features/customReport/ReportDetails";
import AttemptAssignedReflection from "./features/assignedReflections/AttemptAssignedReflection";
import {Roles as Role} from "../src/helpers/accessControl"
import AnswerSurveyMentee from "./features/assignedReflections/AnswerSurveyMentee";
import {menteeRoutes} from "./helpers/constants";
import AttemptSurveyResultMentee from "./features/assignedReflections/AttemptSurveyResultMentee";
import Attempts from "./features/attempts/Attempts";
import Teams from "./features/teams/Teams";
import TeamDetail from "./features/teams/TeamDetail";


function App() {


    //RoutesWithToken
    const RoutesWithToken = () => {
        let token = localStorage.getItem('token');
        let subscriptionStatus;
        let location = window.location.pathname
        if (token !== null) {
            subscriptionStatus = jwtDecode(getDataFromLocalStorage("token")).subscriptionStatus
        }
        // Routes with no sidebar
        const onlyOutletRequired = menteeRoutes?.some(route => location.startsWith(route))
        return (
            <>
                {token !== null
                    ?
                    // subscriptionStatus === SubscriptionPlans.SUBSCRIBED || subscriptionStatus === SubscriptionPlans.TRIAL || subscriptionStatus === SubscriptionPlans.CANCELED ?
                    <>
                        {
                            jwtDecode(getDataFromLocalStorage("token"))?.roles[0] === Role.MENTEE && !menteeRoutes?.some(route => location.startsWith(route)) ? window.location.assign("/mentee/reflections") :
                                onlyOutletRequired ? <Outlet/> :
                                    // jwtDecode(getDataFromLocalStorage("token"))?.roles[0] === Role.MENTEE ? :
                                    <>
                                        <Sidebar/>
                                        <div className="component_content innerComponents">
                                            <div className={'main_wrapper'}>
                                                <Outlet/>
                                            </div>
                                        </div>
                                    </>
                        }
                    </>
                    // : <Navigate to={"/plans"}/>
                    :
                    <Navigate to={"/"}/>
                }
                {/*<Header /> <Sidebar/> <div className="component_content innerComponents">  <Outlet /> </div>*/
                }
            </>
        )
    }


    //RoutesWithoutToken
    const RoutesWithoutToken = ({children}) => {
        const loggedInUserInfo = localStorage.getItem("loggedInUserInfo");
        const token = localStorage.getItem("token");
        if (loggedInUserInfo !== null && token !== null && jwtDecode(getDataFromLocalStorage("token"))?.exp > Math.floor(Date.now() / 1000)) {
            return jwtDecode(getDataFromLocalStorage("token"))?.roles[0] === Role.MENTEE ? <Navigate to={"/mentee/reflections"}/> :  <Navigate to={"/members"}/>
        } else {
            return children
        }
    }


    return (
        <>
            <div className={"App"}>
                <ToastContainer position={"bottom-right"} className={"toast_data"}/>
                <Router>
                    <Provider store={store}>
                        <Routes>
                            <Route element={<RoutesWithToken/>}>
                                <Route path={RoutesUtil.result.path} element={<Result/>}/>
                                <Route path={RoutesUtil.roles.path} element={<Roles/>}/>
                                {/*<Route path={RoutesUtil.customReport.path} element={<CustomReport/>}/>*/}
                                <Route path={RoutesUtil.organizations.path} element={<Organizations/>}/>
                                <Route path={RoutesUtil.organizationDetails.path} element={<OrganizationsDetails/>}/>
                                <Route path={RoutesUtil.admin.path} element={<Dashboard/>}/>
                                <Route path={RoutesUtil.details.path} element={<ResultDetails/>}/>
                                <Route path={RoutesUtil.members.path} element={<Member/>}/>
                                <Route path={RoutesUtil.answerReflection.path} element={<AnswerSurvey/>}/>
                                <Route path={RoutesUtil.answerReflectionTeam.path} element={<AnswerSurvey/>}/>
                                <Route path={RoutesUtil.reflectionResult.path} element={<SurveyResultDetail/>}/>
                                <Route path={RoutesUtil.reflectionResultTeam.path} element={<SurveyResultDetail/>}/>
                                <Route path={RoutesUtil.reflectionResultMentee.path} element={<AttemptSurveyResultMentee/>}/>
                                <Route path={RoutesUtil.reflections.path} element={<Surveys/>}/>
                                <Route path={RoutesUtil.surveyDetails.path} element={<SurveysDetails/>}/>
                                <Route path={RoutesUtil.setting.path} element={<Setting/>}/>
                                <Route path={RoutesUtil.creationReflection.path} element={<AddSurvey/>}/>
                                <Route path={RoutesUtil.updateReflection.path} element={<UpdateSurvey/>}/>
                                <Route path={RoutesUtil.savedReflection.path} element={<SavedReflection/>}/>
                                <Route path={RoutesUtil.newSurvey.path} element={<NewSurvey/>}/>
                                <Route path={RoutesUtil.rolesDetail.path} element={<RolesDetail/>}/>
                                <Route path={RoutesUtil.surveyName.path} element={<SurveyName/>}/>
                                <Route path={RoutesUtil.memberDetails.path} element={<MemberDetails/>}/>
                                <Route path={RoutesUtil.reports.path} element={<CustomReport/>}/>
                                <Route path={RoutesUtil.reportDetails.path} element={<ReportDetails/>}/>
                                <Route path={RoutesUtil.subOrganizations.path} element={<Organizations/>}/>
                                <Route path={RoutesUtil.subOrganizationsDetails.path} element={<SubOrganizationDetails/>}/>
                                <Route path={RoutesUtil.createSubOrganizations.path} element={<CreateOrganizationForm/>}/>
                                <Route path={RoutesUtil.customCriteria.path} element={<CustomCriteria/>}/>
                                <Route path={RoutesUtil.createOrganizations.path} element={<CreateOrganizationForm/>}/>
                                <Route path={RoutesUtil.assignedReflectionsMentee.path} element={<AttemptAssignedReflection/>}/>
                                <Route path={RoutesUtil.menteeAnswerReflection.path} element={<AnswerSurveyMentee/>}/>
                                <Route path={RoutesUtil.attempts.path} element={<Attempts/>}/>
                                <Route path={RoutesUtil.teams.path} element={<Teams/>}/>
                                <Route path={RoutesUtil.teamDetail.path} element={<TeamDetail/>}/>
                            </Route>
                            {/*<Route path={RoutesUtil.plans.path} element={<Plans/>}/>*/}
                            <Route path={RoutesUtil.login.path} element={<RoutesWithoutToken children={<Login/>}/>}/>
                            {/*<Route path={RoutesUtil.signUp.path} element={<SignUp/>}/>*/}
                            <Route path={RoutesUtil.forgetPassword.path} element={<ForgetPassword/>}/>

                            {/*<Route path={RoutesUtil.subscribedSuccessfully.path} element={<SubscribedSuccessfully/>}/>*/}
                            <Route path={RoutesUtil.signUpSuccess.path} element={<SignedUpSuccessfully/>}/>
                            <Route path={RoutesUtil.updateOrgLevel.path} element={<UpdateOrgLevel/>}/>
                            <Route path={RoutesUtil.createPassword.path} element={<CreatePassword/>}/>
                            <Route path={RoutesUtil.publicSurveyAnswer.path} element={<PublicSurveyAnswer/>}/>
                            <Route path={RoutesUtil.pageNotFound.path} element={<ErrorPage/>}/>

                        </Routes>
                    </Provider>
                </Router>
            </div>
        </>


    );
}


export default App;
