import {Form } from "react-bootstrap"
import './SurveysDetails.css'
import croosIcon from '../../../images/croosIcon.png'
import line from '../../../images/sort.png'
const SurveysDetails = () => {
    return (
        <>
  <section>
        <div className="row main_content">
                <div className="col-md-12 p-0">

                <div className="details_outer">
                <div className="pathways_outer">
                    <h2 className="spiritual_h1 mediumFontSize">Spiritual Pathways</h2>
                    <div>
                    
                        <button className="detail_cancelBtn mediumFontSize">Cancel</button>
                        <button className="detail_saveBtn cmn_background_color mediumFontSize">Save</button>

                    </div>

                </div>
                <div className="details_wrapper">

                    {/* <div className="details_wrapper_button">
                    <button className="mediumFontSize">High Risk</button>
                    </div> */}

                    

                    

                 <div className="positive_Outer ">
                        <h2 className="mediumFontSize">Negative</h2>
                         <h2 className="mediumFontSize" >Positive</h2>
                  
                 </div>
                 {/* <div className="positive_outer_alt cmn_fontStyle">
                        <h2>Negative</h2>
                         <h2>Positive</h2>
                  
                 </div> */}
                    <ul className="inner_item">
                        <li>
                            <div className="detail_list_item">
                            <div className="detail_para">
                                    <input type="text" className="cmn_fontStyle textFiled form-control" placeholder="I enjoy attending a high church  service with incence and formal communication or Eucharist."/>
                                </div>
                                <div >
                                    <Form className="form_content">
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div className="form_btn">

                                            <button className="xmark cmn_mark"><img src={croosIcon} alt="nothing" width="10px" /></button>
                                            <button className="equal cmn_mark"> <img src={line} alt="nothing" width={"10px"} /></button>
                                        </div>
                                    </Form>
                                </div>

                            </div>
                        </li>
                        <li>
                            <div className="detail_list_item">
                                <div className="detail_para">
                                    <input type="text" className="cmn_fontStyle textFiled form-control" placeholder="I enjoy attending a high church  service with incence and formal communication or Eucharist."/>
                                </div>
                                <div >
                                    <Form className="form_content">
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div className="form_btn">

                                        <button className="xmark cmn_mark"><img src={croosIcon} alt="nothing" width="10px" /></button>
                                            <button className="equal cmn_mark"> <img src={line} alt="nothing" width={"10px"} /></button>
                                        </div>
                                    </Form>
                                </div>

                            </div>
                        </li>
                        <li>
                            <div className="detail_list_item">
                            <div className="detail_para">
                                    <input type="text" className="cmn_fontStyle textFiled form-control" placeholder="I enjoy attending a high church  service with incence and formal communication or Eucharist."/>
                                </div>
                                <div >
                                    <Form className="form_content">
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div className="form_btn">

                                        <button className="xmark cmn_mark"><img src={croosIcon} alt="nothing" width="10px" /></button>
                                            <button className="equal cmn_mark"> <img src={line} alt="nothing" width={"10px"} /></button>
                                        </div>
                                    </Form>
                                </div>

                            </div>
                        </li>
                        <li>
                            <div className="detail_list_item">
                            <div className="detail_para">
                                    <input type="text" className="cmn_fontStyle textFiled form-control" placeholder="I enjoy attending a high church  service with incence and formal communication or Eucharist."/>
                                </div>
                                <div >
                                    <Form className="form_content">
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div className="form_btn">

                                        <button className="xmark cmn_mark"><img src={croosIcon} alt="nothing" width="10px" /></button>
                                            <button className="equal cmn_mark"> <img src={line} alt="nothing" width={"10px"} /></button>
                                        </div>
                                    </Form>
                                </div>

                            </div>
                        </li>
                        <li>
                            <div className="detail_list_item">
                            <div className="detail_para">
                                    <input type="text" className="cmn_fontStyle textFiled form-control" placeholder="I enjoy attending a high church  service with incence and formal communication or Eucharist."/>
                                </div>
                                <div >
                                    <Form className="form_content">
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div className="form_btn">

                                        <button className="xmark cmn_mark"><img src={croosIcon} alt="nothing" width="10px" /></button>
                                            <button className="equal cmn_mark"> <img src={line} alt="nothing" width={"10px"} /></button>
                                        </div>
                                    </Form>
                                </div>

                            </div>
                        </li>
                        <li>
                            <div className="detail_list_item">
                                <div className="detail_para">
                                    <input type="text" className="cmn_fontStyle textFiled form-control" placeholder="I enjoy attending a high church  service with incence and formal communication or Eucharist."/>
                                </div>
                                <div >
                                    <Form className="form_content">
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div>
                                            <input type="radio" />
                                        </div>
                                        <div className="form_btn">

                                        <button className="xmark cmn_mark"><img src={croosIcon} alt="nothing" width="10px" /></button>
                                            <button className="equal cmn_mark"> <img src={line} alt="nothing" width={"10px"} /></button>
                                        </div>
                                    </Form>
                                </div>

                            </div>
                        </li>
                    </ul>
                    <button className="addQues_btn cmn_background_color  mediumFontSize">Add Question</button>

                </div>
            </div>

                </div>
        </div>
  </section>

        </>
    )
}
export default SurveysDetails;