import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {toast} from "react-toastify";
import {formatDateToString, getAxiosConfig, objectToQueryString, serialize, showToast} from "../../helpers/utils";
import {
    AttemptSurveyAssignedToMentee,
    AttemptSurveySaveAsDraft, AttemptSurveyShareReport,
    ReflectionCreatedSuccessfully, SomethingWentWrong
} from "../../helpers/constants";


export const createSurvey = createAsyncThunk('survey/createSurvey', async (data, thunkAPI) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/survey`, data, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})
export const getAllSurveys = createAsyncThunk('survey/getAllSurveys', async (pageNumber, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/survey?page=${pageNumber}`, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})
export const getAllSurveysWithoutPaginationForAddReflectionBtn = createAsyncThunk('survey/getAllSurveysWithoutPaginationForAddReflectionBtn', async (data = null, thunkAPI) => {
    const serializedData = data === null ? "" : serialize(data)
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/survey?${serializedData}`, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})
export const getAllSurveysWithoutPagination = createAsyncThunk('survey/getAllSurveysWithoutPagination', async (data = null, thunkAPI) => {
    const serializedData = objectToQueryString(data)
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/survey?${serializedData}`, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})
export const searchSurveys = createAsyncThunk('survey/searchSurveys', async (data, thunkAPI) => {
    const serializedData = serialize({subOrgId: data.subOrgId, orgId: data.orgId})
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/survey/search?${serializedData}`,
        headers: getAxiosConfig().headers,
        data: data
    };
    return await axios.request(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return thunkAPI.rejectWithValue(error.response);
        });
})
export const searchSurveysToAssignToOrg = createAsyncThunk('survey/searchSurveysToAssignToOrg', async (data, thunkAPI) => {
    const serializedData = serialize({subOrgId: data.subOrgId})
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/survey/search?${serializedData}`,
        headers: getAxiosConfig().headers,
        data: data
    };
    return await axios.request(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return thunkAPI.rejectWithValue(error.response);
        });
})
export const getSurveyById = createAsyncThunk('survey/getSurveyById', async (surveyId, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/survey/${surveyId}`).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})

export const getPublishSurveys = createAsyncThunk('survey/get-publish-survey-org-Id', async (surveyId, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/survey/get-publish-survey-org-Id`, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})

export const assignedReflectionsToSubOrg = createAsyncThunk('survey/assignedReflectionsToSubOrg', async (data, thunkAPI) => {
    const serializedData = serialize({surveyIds: data?.surveyIds, subOrgId: data?.subOrgId})
    return await axios.put(`${process.env.REACT_APP_BASE_URL}/survey/share-reflection-to-sub-org?${serializedData}`, data, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})


export const updateSurveyById = createAsyncThunk('survey/updateSurveyById', async (data, thunkAPI) => {
    return await axios.put(`${process.env.REACT_APP_BASE_URL}/survey/${data.surveyId}`, data, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})
export const publishSurvey = createAsyncThunk('survey/publishSurvey', async (data, thunkAPI) => {

    return await axios.put(`${process.env.REACT_APP_BASE_URL}/survey/publish/${data.surveyId}`, data, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})

export const shareSurvey = createAsyncThunk('survey/shareSurvey', async ({email, surveyId}, thunkAPI) => {
    const serializedData = serialize({email: email, surveyId: surveyId})
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/survey/share-survey?${serializedData}`,
        headers: getAxiosConfig().headers,
        data: serializedData
    };
    return await axios.request(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return thunkAPI.rejectWithValue(error.response);
        });
})

export const disableSurveyById = createAsyncThunk('survey/disableSurveyById', async ({
                                                                                         surveyId,
                                                                                         setSurveyId
                                                                                     }, thunkAPI) => {
    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/survey/disable/${surveyId}`,
        headers: getAxiosConfig().headers
    };
    return await axios.request(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return thunkAPI.rejectWithValue(error.response.data);
        });
})
export const deleteSurveyById = createAsyncThunk('survey/deleteSurveyById', async ({
                                                                                       surveyId,
                                                                                       setSurveyId
                                                                                   }, thunkAPI) => {
    return await axios.delete(`${process.env.REACT_APP_BASE_URL}/survey/${surveyId}`, getAxiosConfig()).then((res) => {
        setSurveyId(null)
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})


export const bulkSurveyDelete = createAsyncThunk(
    'survey/bulkSurveyDelete',
    async ({selectCheckBoxItems, setSelectCheckBoxItems}, thunkAPI) => {
        try {
            console.log("selectCheckBoxItems==>", selectCheckBoxItems)
            const response = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/survey/bulk-delete`,
                {data: selectCheckBoxItems || [], ...getAxiosConfig()});
            setSelectCheckBoxItems && setSelectCheckBoxItems([])
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);


export const recommendedCoachById = createAsyncThunk('attempt/recommendedCoachById', async (userId, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/survey-attempt/tags/${userId}`, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    })
})
export const isAttemptExistsForSurvey = createAsyncThunk('survey/isAttemptExistsForSurvey', async (surveyId, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/survey-attempt/attempt-exists/${surveyId}`, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})


export const shareSurveyAttemptReportAction = createAsyncThunk('survey/shareSurveyAttemptReportAction', async (data, thunkAPI) => {
    const serializedData = serialize({email: data.email, startDate: data?.startDate, endDate: data?.endDate})
    const formData = new FormData();
    console.log("data.files ", data.files)
    if (data.files.length > 0) {
        data.files.forEach((file, index) => {
            formData.append(`files[${index}]`, file?.blob, file?.name);

        });
    }

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/survey-attempt/share-report?${serializedData}`,
        headers: getAxiosConfig().headers,
        data: formData
    };
    return await axios.request(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return thunkAPI.rejectWithValue(error.response);
        });
})


export const attemptSurveySaveAsDraft = createAsyncThunk('survey/attemptSurveySaveAsDraft', async (data, thunkAPI) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/survey-attempt`, data, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})
export const assignSurveyAttemptToMentee = createAsyncThunk('survey/assignSurveyAttemptToMentee', async (data, thunkAPI) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/survey-attempt`, data, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})

export const attemptSurveyComplete = createAsyncThunk('survey/attemptSurveyComplete', async (data, thunkAPI) => {
    return await axios.put(`${process.env.REACT_APP_BASE_URL}/survey-attempt/complete`, data, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})

export const attemptSurveyCompletePublicly = createAsyncThunk('survey/attemptSurveyCompletePublicly', async (data, thunkAPI) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/survey-attempt/calculate-result-public?surveyeeEmail=${data.surveyeeEmail}`, data).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})
export const getAllAttemptSurveyByUserId = createAsyncThunk('survey/getAllAttemptSurveyByUserId', async (data, thunkAPI) => {
    const serializedData = serialize({
        page: data.pageNumber,
        sortBy: data.sortBy,
        sortOrder: data.sortOrder,
        ...(data.hasOwnProperty("attemptStatus") ? {attemptStatus: data.attemptStatus} : {})
    })

    return await axios.get(`${process.env.REACT_APP_BASE_URL}/survey-attempt/user/${data.userId}?${serializedData}`, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})
export const getSurveyAttemptBySurveyIdAndSurveyeeId = createAsyncThunk('survey/getSurveyAttemptBySurveyIdAndSurveyeeId', async (data, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/survey-attempt/survey/${data.surveyId}/surveyee/${data.surveyeeId}`, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})

export const getPreviousSurveyAttemptBySurveyIdAndSurveyeeId = createAsyncThunk('survey/getPreviousSurveyAttemptBySurveyIdAndSurveyeeId', async (data, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/survey-attempt/previous/survey/${data.surveyId}/surveyee/${data.surveyeeId}`, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})
export const updateSurveyAttemptById = createAsyncThunk('survey/updateSurveyAttemptById', async (data, thunkAPI) => {
    return await axios.put(`${process.env.REACT_APP_BASE_URL}/survey-attempt/${data.attemptSurveyId}`, data.updatedAnswerSurveyData, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})


export const getSurveyAttemptById = createAsyncThunk('survey/getSurveyAttemptById', async (surveyId, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/survey-attempt/${surveyId}`, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})

export const getMembersOverallResult = createAsyncThunk('survey/getMembersOverallResult', async (data, thunkAPI) => {
    const serializedData = serialize(data)
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/survey-attempt/dashboard-member?${serializedData}`, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    })
})
export const assignReflectionToOrg = createAsyncThunk('survey/assignReflectionToOrg', async (data, thunkAPI) => {
    const serializedData = serialize(data)
    return await axios.put(`${process.env.REACT_APP_BASE_URL}/survey/assign-survey-organization?${serializedData}`, data, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    })
})
export const unAssignSurveyByOrgAdmin = createAsyncThunk('survey/unAssignReflection', async (data, thunkAPI) => {
    const serializedData = serialize(data)
    return await axios.put(`${process.env.REACT_APP_BASE_URL}/survey/unShare-reflection?${serializedData}`, data, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    })
})

export const moveSurveyOrganization = createAsyncThunk('survey/moveSurveyOrganization', async (data, thunkAPI) => {
    return await axios.put(`${process.env.REACT_APP_BASE_URL}/survey/move-survey-organization?orgId=${data.orgId}&surveyId=${data.surveyId}`, {}, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    });
})


export const unAssignReflection = createAsyncThunk('survey/unAssignReflection', async (surveyId, thunkAPI) => {
    return await axios.put(`${process.env.REACT_APP_BASE_URL}/survey/unAssign-survey?surveyId=${surveyId}`, surveyId, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    })
})
export const getAllSurveyAttempts = createAsyncThunk('survey/getAllSurveyAttempts', async (data, thunkAPI) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/survey-attempt/list`, data, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    })
})

export const getAssignedReflections = createAsyncThunk('survey/getAssignedReflections', async (data, thunkAPI) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/survey/assigned/${data.teamId}`, data, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    })
})

export const getAllAssignedReflections = createAsyncThunk('survey/getAllAssignedReflections', async (teamId, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/survey/assigned/list/${teamId}`, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    })
})


const surveySlices = createSlice({
    name: "survey",
    initialState: {
        createSurveyDataReducer: {loading: false},
        getAllSurveysDataReducer: {loading: false},
        getSurveyByIdDataReducer: {loading: false},
        getPublishSurveysReducer: {loading: false},
        assignedReflectionsToSubOrgReducer: {loading: false},
        updateSurveyByIdDataReducer: {loading: false},
        publishSurveyDataReducer: {loading: false},
        shareSurveyDataReducer: {loading: false},
        getSearchSurveyDataReducer: {loading: false},
        searchSurveysToAssignToOrgReducer: {loading: false},
        attemptSurveySaveAsDraftDataReducer: {loading: false},
        assignSurveyAttemptToMenteeDataReducer: {loading: false},
        shareSurveyAttemptReportReducer: {loading: false},
        attemptSurveyCompleteDataReducer: {loading: false},
        attemptSurveyCompletePubliclyDataReducer: {loading: false},
        getAllAttemptSurveyByUserIdDataReducer: {loading: false},
        deleteSurveyByIdDataReducer: {loading: false},
        bulkDeleteSurveysDataReducer: {loading: false},
        recommendedCoachByIdDataReducer: {loading: false},
        getSurveyAttemptBySurveyIdAndSurveyeeIdDataReducer: {loading: false},
        getPreviousSurveyAttemptBySurveyIdAndSurveyeeIdDataReducer: {loading: false},
        updateSurveyAttemptByIdDataReducer: {loading: false},
        getSurveyAttemptByIdDataReducer: {loading: false},
        getAllSurveysWithoutPaginationDataReducer: {loading: false},
        getAllSurveysWithoutPaginationForAddReflectionBtnReducer: {loading: false},
        isAttemptExistsForSurveyDataReducer: {loading: false},
        disableSurveyByIdDataReducer: {loading: false},
        getMembersOverallResultReducer: {loading: false},
        assignReflectionToOrgReducer: {loading: false},
        unAssignSurveyByOrgAdminReducer: {loading: false},
        moveSurveyOrganizationReducer: {loading: false},
        unAssignReflectionReducer: {loading: false},
        getAllSurveyAttemptsReducer: {loading: false},
        getAssignedReflectionsReducer: {loading: false},
        getAllAssignedReflectionsReducer: {loading: false},
    },

    reducers: {
        resetGetPreviousSurveyAttemptBySurveyIdAndSurveyeeIdDataReducer: (state) => {
            state.getPreviousSurveyAttemptBySurveyIdAndSurveyeeIdDataReducer = {loading: false, data: {}}
        },
        resetGetSearchSurveyDataReducer: (state) => {
            state.getSearchSurveyDataReducer = {loading: true, data: null}
        },

        resetSurveyListReducer: (state) => {
            state.getAllSurveysWithoutPaginationDataReducer = {loading: true, data: null}
        }
    },

    extraReducers: {
        // Create Survey
        [createSurvey.pending]: (state) => {
            state.createSurveyDataReducer = {loading: true};
        },
        [createSurvey.fulfilled]: (state, action) => {
            state.createSurveyDataReducer = {loading: false, data: action.payload.data};
            showToast(ReflectionCreatedSuccessfully, "success")
        },
        [createSurvey.rejected]: (state, action) => {
            showToast(action?.payload?.detailDesc || SomethingWentWrong, "error")
            state.createSurveyDataReducer = {loading: false};
        },
        // Get All Surveys List
        [getAllSurveys.pending]: (state) => {
            state.getAllSurveysDataReducer = {loading: true};
        },
        [getAllSurveys.fulfilled]: (state, action) => {
            state.getAllSurveysDataReducer = {loading: false, data: action.payload.data};
        },
        [getAllSurveys.rejected]: (state, action) => {
            state.getAllSurveysDataReducer = {loading: false};
            showToast(action.payload.detailDesc, "error")
        },
        // Get Surveys By Id
        [getSurveyById.pending]: (state) => {
            state.getSurveyByIdDataReducer = {loading: true};
        },
        [getSurveyById.fulfilled]: (state, action) => {
            state.getSurveyByIdDataReducer = {loading: false, data: action.payload.data};
            state.getAllSurveysDataReducer = {loading: false, data: action.payload.data};
        },
        [getSurveyById.rejected]: (state, action) => {
            state.getSurveyByIdDataReducer = {loading: false};
            if (!(action.payload.errorCode.value === 400 || action.payload.errorCode.value === 404)) {
                showToast(action.payload.detailDesc, "error")
            }
        },


        [getPublishSurveys.pending]: (state) => {
            state.getPublishSurveysReducer = {loading: true};
        },
        [getPublishSurveys.fulfilled]: (state, action) => {
            state.getPublishSurveysReducer = {loading: false, data: action.payload.data};
            showToast(action.payload.data, "success")
        },
        [getPublishSurveys.rejected]: (state, action) => {
            state.getPublishSurveysReducer = {loading: false};
            if (!(action.payload.errorCode.value === 400 || action.payload.errorCode.value === 404)) {
                showToast(action.payload.detailDesc, "error")
            }
        },


        [assignedReflectionsToSubOrg.pending]: (state) => {
            state.assignedReflectionsToSubOrgReducer = {loading: true};
        },
        [assignedReflectionsToSubOrg.fulfilled]: (state, action) => {
            state.assignedReflectionsToSubOrgReducer = {loading: false, data: action.payload.data};
            showToast(action.payload.data, "success")
        },
        [assignedReflectionsToSubOrg.rejected]: (state, action) => {
            state.assignedReflectionsToSubOrgReducer = {loading: false};
            if (!(action.payload.errorCode.value === 400 || action.payload.errorCode.value === 404)) {
                showToast(action.payload.detailDesc, "error")
            }
        },


        // Update Survey By Id
        [updateSurveyById.pending]: (state) => {
            state.updateSurveyByIdDataReducer = {loading: true};
        },
        [updateSurveyById.fulfilled]: (state, action) => {
            state.updateSurveyByIdDataReducer = {loading: false, data: action.payload.data};
            showToast(action.payload.data, "success")
        },
        [updateSurveyById.rejected]: (state, action) => {
            state.updateSurveyByIdDataReducer = {loading: false}
            showToast(action.payload.detailDesc, "error")
        },
        // Publish Survey
        [publishSurvey.pending]: (state) => {
            state.publishSurveyDataReducer = {loading: true};
        },
        [publishSurvey.fulfilled]: (state, action) => {
            state.publishSurveyDataReducer = {loading: false, data: action.payload.data};
            showToast(action.payload.data, "success")
        },
        [publishSurvey.rejected]: (state, action) => {
            state.publishSurveyDataReducer = {loading: false};
        },

        [shareSurvey.pending]: (state) => {
            state.shareSurveyDataReducer = {loading: true};
        },
        [shareSurvey.fulfilled]: (state, action) => {
            state.shareSurveyDataReducer = {loading: false, data: action.payload.data};
            if (action.payload.data.hasOwnProperty("Success")) {
                showToast(action.payload.data.Success, "success")
            }
            if (action.payload.data.hasOwnProperty("Exist_Fail") || action.payload.data.hasOwnProperty("High_Authority_Fail")) {
                let message;
                if (action.payload.data.hasOwnProperty("Exist_Fail") && action.payload.data.hasOwnProperty("High_Authority_Fail")) {
                    message = <span className="black-icon">● {action.payload.data.Exist_Fail}<br />● {action.payload.data.High_Authority_Fail}</span>;
                } else if (action.payload.data.hasOwnProperty("Exist_Fail")) {
                    message = action.payload.data.Exist_Fail
                } else if (action.payload.data.hasOwnProperty("High_Authority_Fail")) {
                    message = action.payload.data.High_Authority_Fail
                }
                showToast(message , "error")
            }

        },
        [shareSurvey.rejected]: (state, action) => {
            state.shareSurveyDataReducer = {loading: false};
            showToast(action.payload.detailDesc, "error")
        },
        // Search Survey
        [searchSurveys.pending]: (state) => {
            state.getSearchSurveyDataReducer = {loading: true}
        },
        [searchSurveys.fulfilled]: (state, action) => {
            state.getSearchSurveyDataReducer = {loading: false, data: action.payload.data}
        },
        [searchSurveys.rejected]: (state, action) => {
            state.getSearchSurveyDataReducer = {loading: false};
            showToast(action.payload.detailDesc || SomethingWentWrong, "error")
        },

        // Search Surveys To Assign To Org
        [searchSurveysToAssignToOrg.pending]: (state) => {
            state.searchSurveysToAssignToOrgReducer = {loading: true}
        },
        [searchSurveysToAssignToOrg.fulfilled]: (state, action) => {
            state.searchSurveysToAssignToOrgReducer = {loading: false, data: action.payload.data}
        },
        [searchSurveysToAssignToOrg.rejected]: (state, action) => {
            state.searchSurveysToAssignToOrgReducer = {loading: false};
            showToast(action.payload.data.detailDesc, "error")
        },

        // Attempt Survey Save As Draft
        [attemptSurveySaveAsDraft.pending]: (state) => {
            state.attemptSurveySaveAsDraftDataReducer = {loading: true};
        },
        [attemptSurveySaveAsDraft.fulfilled]: (state, action) => {
            state.attemptSurveySaveAsDraftDataReducer = {loading: false, data: action.payload.data};
            showToast(AttemptSurveySaveAsDraft, "success")
            // state.isAttemptSurveySaveAsDraftLoading = false;
        },
        [attemptSurveySaveAsDraft.rejected]: (state, action) => {
            state.attemptSurveySaveAsDraftDataReducer = {loading: false}
            showToast(action.payload.detailDesc, "error")
        },

        // Assign Survey Attempt To Mentee
        [assignSurveyAttemptToMentee.pending]: (state) => {
            state.assignSurveyAttemptToMenteeDataReducer = {loading: true};
        },
        [assignSurveyAttemptToMentee.fulfilled]: (state, action) => {
            state.assignSurveyAttemptToMenteeDataReducer = {loading: false, data: action.payload.data};
            showToast(AttemptSurveyAssignedToMentee, "success")
        },
        [assignSurveyAttemptToMentee.rejected]: (state, action) => {
            state.assignSurveyAttemptToMenteeDataReducer = {loading: false}
            showToast(action.payload.detailDesc, "error")
        },


        // share survey report via email
        [shareSurveyAttemptReportAction.pending]: (state) => {
            state.shareSurveyAttemptReportReducer = {loading: true};
        },
        [shareSurveyAttemptReportAction.fulfilled]: (state, action) => {
            state.shareSurveyAttemptReportReducer = {loading: false, data: action.payload.data};
            showToast(AttemptSurveyShareReport, "success")
        },
        [shareSurveyAttemptReportAction.rejected]: (state, action) => {
            state.shareSurveyAttemptReportReducer = {loading: false}
            showToast(action?.payload?.detailDesc || action?.payload?.data?.detailDesc, "error")
        },

        // Get All Survey without Pagination
        [getAllSurveysWithoutPagination.pending]: (state) => {
            state.getAllSurveysWithoutPaginationDataReducer = {loading: true};
        },
        [getAllSurveysWithoutPagination.fulfilled]: (state, action) => {
            state.getAllSurveysWithoutPaginationDataReducer = {loading: false, data: action.payload.data};
        },
        [getAllSurveysWithoutPagination.rejected]: (state, action) => {
            state.getAllSurveysWithoutPaginationDataReducer = {loading: false}
            showToast(action.payload.detailDesc, "error")

        },

        // get All Surveys Without Pagination For AddReflection Btn
        [getAllSurveysWithoutPaginationForAddReflectionBtn.pending]: (state) => {
            state.getAllSurveysWithoutPaginationForAddReflectionBtnReducer = {loading: true};
        },
        [getAllSurveysWithoutPaginationForAddReflectionBtn.fulfilled]: (state, action) => {
            state.getAllSurveysWithoutPaginationForAddReflectionBtnReducer = {
                loading: false,
                data: action.payload.data
            };
        },
        [getAllSurveysWithoutPaginationForAddReflectionBtn.rejected]: (state, action) => {
            state.getAllSurveysWithoutPaginationForAddReflectionBtnReducer = {loading: false}
            showToast(action.payload.detailDesc, "error")

        },


        // Attempt Survey Complete
        [attemptSurveyComplete.pending]: (state) => {
            state.attemptSurveyCompleteDataReducer = {loading: true};
        },
        [attemptSurveyComplete.fulfilled]: (state, action) => {
            state.attemptSurveyCompleteDataReducer = {loading: false, data: action.payload.data};
            showToast(action.payload.data, "success")
        },
        [attemptSurveyComplete.rejected]: (state, action) => {
            state.attemptSurveyCompleteDataReducer = {loading: false}
            showToast(action.payload.detailDesc, "error")
        },

        // Attempt Survey Complete Publicly

        [attemptSurveyCompletePublicly.pending]: (state) => {
            state.attemptSurveyCompletePubliclyDataReducer = {loading: true};
        },
        [attemptSurveyCompletePublicly.fulfilled]: (state, action) => {
            state.attemptSurveyCompletePubliclyDataReducer = {loading: false, data: action.payload.data};
        },
        [attemptSurveyCompletePublicly.rejected]: (state, action) => {
            state.attemptSurveyCompletePubliclyDataReducer = {loading: false}
            showToast(action.payload.detailDesc, "error")
        },
        // Get All Attempt Survey By User Id
        [getAllAttemptSurveyByUserId.pending]: (state) => {
            state.getAllAttemptSurveyByUserIdDataReducer = {loading: true};
        },
        [getAllAttemptSurveyByUserId.fulfilled]: (state, action) => {
            state.getAllAttemptSurveyByUserIdDataReducer = {loading: false, data: action.payload.data};
        },
        [getAllAttemptSurveyByUserId.rejected]: (state, action) => {
            state.getAllAttemptSurveyByUserIdDataReducer = {loading: false};
            if (!(action.payload.errorCode.value === 400 || action.payload.errorCode.value === 404)) {
                showToast(action.payload.detailDesc, "error")
            }
        },
        // Get All Attempt Survey By Surveyee Id and Survey Id
        [getSurveyAttemptBySurveyIdAndSurveyeeId.pending]: (state) => {
            state.getSurveyAttemptBySurveyIdAndSurveyeeIdDataReducer = {loading: false};
        },
        [getSurveyAttemptBySurveyIdAndSurveyeeId.fulfilled]: (state, action) => {
            state.getSurveyAttemptBySurveyIdAndSurveyeeIdDataReducer = {loading: false, data: action.payload.data};
        },
        [getSurveyAttemptBySurveyIdAndSurveyeeId.rejected]: (state, action) => {
            state.getSurveyAttemptBySurveyIdAndSurveyeeIdDataReducer = {loading: false};
            if (!(action.payload.errorCode.value === 400 || action.payload.errorCode.value === 404)) {
                showToast(action.payload.detailDesc, "error")
            }
        },
        // Get All Attempt Survey By Surveyee Id and Survey Id previous data
        [getPreviousSurveyAttemptBySurveyIdAndSurveyeeId.pending]: (state) => {
            state.getPreviousSurveyAttemptBySurveyIdAndSurveyeeIdDataReducer = {loading: false};
        },
        [getPreviousSurveyAttemptBySurveyIdAndSurveyeeId.fulfilled]: (state, action) => {
            state.getPreviousSurveyAttemptBySurveyIdAndSurveyeeIdDataReducer = {
                loading: false,
                data: action.payload.data
            };
        },
        [getPreviousSurveyAttemptBySurveyIdAndSurveyeeId.rejected]: (state, action) => {
            state.getPreviousSurveyAttemptBySurveyIdAndSurveyeeIdDataReducer = {loading: false};
            if (!(action.payload.errorCode.value === 400 || action.payload.errorCode.value === 404)) {
                showToast(action.payload.detailDesc, "error")
            }
        },
        // Update Attempt Survey By Id
        [updateSurveyAttemptById.pending]: (state) => {
            state.updateSurveyAttemptByIdDataReducer = {loading: true};
        },
        [updateSurveyAttemptById.fulfilled]: (state, action) => {
            state.updateSurveyAttemptByIdDataReducer = {loading: false, data: action.payload.data};
            showToast(action.payload.data, "success")
            // state.updateSurveyAttemptByIdData = action.payload.data
        },
        [updateSurveyAttemptById.rejected]: (state, action) => {
            state.updateSurveyAttemptByIdDataReducer = {loading: false};
            showToast(action.payload.detailDesc, "error")

        },

        // delete survey by Id

        [deleteSurveyById.pending]: (state) => {
            state.recommendedCoachByIdDataReducer = {loading: true};
        },
        [deleteSurveyById.fulfilled]: (state, action) => {
            state.recommendedCoachByIdDataReducer = {loading: false, data: action.payload.data};
            showToast(action.payload.data, "success")
        },
        [deleteSurveyById.rejected]: (state, action) => {
            state.recommendedCoachByIdDataReducer = {loading: false};
            showToast(action.payload.detailDesc, "error")
        },

        // bulk delete survey
        [bulkSurveyDelete.pending]: (state) => {
            state.bulkDeleteSurveysDataReducer = {loading: true};
        },
        [bulkSurveyDelete.fulfilled]: (state, action) => {
            state.bulkDeleteSurveysDataReducer = {loading: false, data: action?.payload?.data};
            showToast(action.payload.data, "success")
        },
        [bulkSurveyDelete.rejected]: (state, action) => {
            state.bulkDeleteSurveysDataReducer = {loading: false};
            showToast(action.payload.detailDesc, "error")

        },

        [recommendedCoachById.pending]: (state) => {
            state.recommendedCoachByIdDataReducer = {loading: true};
        },
        [recommendedCoachById.fulfilled]: (state, action) => {
            state.recommendedCoachByIdDataReducer = {loading: false, data: action.payload.data};
        },
        [recommendedCoachById.rejected]: (state, action) => {
            state.recommendedCoachByIdDataReducer = {loading: false};
            showToast(action.payload.detailDesc, "error")
        },
        // Get Attempt Survey By Id
        [getSurveyAttemptById.pending]: (state) => {
            state.getSurveyAttemptByIdDataReducer = {loading: true};
        },
        [getSurveyAttemptById.fulfilled]: (state, action) => {
            state.getSurveyAttemptByIdDataReducer = {loading: false, data: action.payload.data};
        },
        [getSurveyAttemptById.rejected]: (state, action) => {
            state.getSurveyAttemptByIdDataReducer = {loading: false};
            if (!(action.payload.errorCode.value === 400 || action.payload.errorCode.value === 404)) {
                showToast(action.payload.detailDesc, "error")
            }
        },

        // Check whether attempts exists for survey or not
        [isAttemptExistsForSurvey.pending]: (state) => {
            state.isAttemptExistsForSurveyDataReducer = {loading: true};
        },
        [isAttemptExistsForSurvey.fulfilled]: (state, action) => {
            state.isAttemptExistsForSurveyDataReducer = {loading: false, data: action.payload.data};
        },
        [isAttemptExistsForSurvey.rejected]: (state, action) => {
            state.isAttemptExistsForSurveyDataReducer = {loading: false};
            showToast(action.payload.detailDesc, "error")
        },

        // Disable Survey By Id
        [disableSurveyById.pending]: (state) => {
            state.disableSurveyByIdDataReducer = {loading: true};
        },
        [disableSurveyById.fulfilled]: (state, action) => {
            state.disableSurveyByIdDataReducer = {loading: false, data: action.payload.data};
            showToast(action.payload.data, "success")

        },
        [disableSurveyById.rejected]: (state, action) => {
            state.disableSurveyByIdDataReducer = {loading: false};
            showToast(action.payload.detailDesc, "error")
        },
        // Get Members Overall Result
        [getMembersOverallResult.pending]: (state) => {
            state.getMembersOverallResultReducer = {loading: true}
        },
        [getMembersOverallResult.fulfilled]: (state, action) => {
            state.getMembersOverallResultReducer = {loading: false, data: action.payload.data}

        },
        [getMembersOverallResult.rejected]: (state, action) => {
            state.getMembersOverallResultReducer = {loading: false}
            showToast(action.payload.detailDesc || action?.payload?.statusText, "error")
        },

        // Assign Reflection To Org
        [assignReflectionToOrg.pending]: (state) => {
            state.assignReflectionToOrgReducer = {loading: true}
        },
        [assignReflectionToOrg.fulfilled]: (state, action) => {
            state.assignReflectionToOrgReducer = {loading: false, data: action.payload.data}
            showToast(action.payload.data, "success")

        },
        [assignReflectionToOrg.rejected]: (state, action) => {
            state.assignReflectionToOrgReducer = {loading: false}
            showToast(action.payload.detailDesc || action?.payload?.statusText, "error")
        },


        [unAssignSurveyByOrgAdmin.pending]: (state) => {
            state.unAssignSurveyByOrgAdminReducer = {loading: true}
        },
        [unAssignSurveyByOrgAdmin.fulfilled]: (state, action) => {
            showToast(action.payload.data, "success")
            state.unAssignSurveyByOrgAdminReducer = {loading: false, data: action.payload.data}
        },
        [unAssignSurveyByOrgAdmin.rejected]: (state, action) => {
            state.unAssignSurveyByOrgAdminReducer = {loading: false}
            showToast(action.payload.detailDesc || action?.payload?.statusText, "error")
        },

        [moveSurveyOrganization.pending]: (state) => {
            state.moveSurveyOrganizationReducer = {loading: true}
        },
        [moveSurveyOrganization.fulfilled]: (state, action) => {
            state.moveSurveyOrganizationReducer = {loading: false, data: action.payload.data}
            showToast(action.payload.data, "success")

        },
        [moveSurveyOrganization.rejected]: (state, action) => {
            state.moveSurveyOrganizationReducer = {loading: false}
            showToast(action.payload.detailDesc || action?.payload?.statusText, "error")
        },

        // UnAssign Reflection
        [unAssignReflection.pending]: (state) => {
            state.unAssignReflectionReducer = {loading: true}
        },
        [unAssignReflection.fulfilled]: (state, action) => {
            state.unAssignReflectionReducer = {loading: false, data: action.payload.data}
            showToast(action.payload.data, "success")

        },
        [unAssignReflection.rejected]: (state, action) => {
            state.unAssignReflectionReducer = {loading: false}
            showToast(action.payload.detailDesc || action?.payload?.statusText, "error")
        },

        // Get All Survey Attempts
        [getAllSurveyAttempts.pending]: (state) => {
            state.getAllSurveyAttemptsReducer = {loading: true}
        },
        [getAllSurveyAttempts.fulfilled]: (state, action) => {
            state.getAllSurveyAttemptsReducer = {loading: false, data: action.payload.data}

        },
        [getAllSurveyAttempts.rejected]: (state, action) => {
            state.getAllSurveyAttemptsReducer = {loading: false}
        },

        // Get Assigned Reflections
        [getAssignedReflections.pending]: (state) => {
            state.getAssignedReflectionsReducer = {loading: true}
        },
        [getAssignedReflections.fulfilled]: (state, action) => {
            state.getAssignedReflectionsReducer = {loading: false, data: action.payload.data}

        },
        [getAssignedReflections.rejected]: (state, action) => {
            state.getAssignedReflectionsReducer = {loading: false}
        },

        // Get All Assigned Reflections
        [getAllAssignedReflections.pending]: (state) => {
            state.getAllAssignedReflectionsReducer = {loading: true}
        },
        [getAllAssignedReflections.fulfilled]: (state, action) => {
            state.getAllAssignedReflectionsReducer = {loading: false, data: action.payload.data}

        },
        [getAllAssignedReflections.rejected]: (state, action) => {
            state.getAllAssignedReflectionsReducer = {loading: false}
        },

    }

})
export const {
    resetGetPreviousSurveyAttemptBySurveyIdAndSurveyeeIdDataReducer,
    resetGetSearchSurveyDataReducer,
    resetSurveyListReducer
} = surveySlices.actions

export default surveySlices.reducer;
