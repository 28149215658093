import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {Card, Form} from "react-bootstrap";
import {
    defaultQuestionForReflection,
    exceptThisSymbols,
    Marks,
    OutOfRange,
    QuestionType,
    Survey
} from "../../helpers/constants";
import {RiDeleteBin6Line} from "react-icons/ri";
import {HiPencil} from "react-icons/hi";
import {
    getDataFromLocalStorage,
    getValueOrDefault,
    isNullOrEmpty,
    removeItemFromArrayAtIndex,
    trim
} from "../../helpers/utils";
import {hasPermission, Permissions, roleHasAccess, Roles} from "../../helpers/accessControl";
import jwtDecode from "jwt-decode";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";


const DraggableQuestions = ({
                                data,
                                questionsList,
                                setQuestionsList,
                                formType,
                                segmentType,
                                setSegmentType,
                                parentId,
                                reflectionSegments,
                                setReflectionSegments,
                                handleSelectedParentSection,
                                tags,
                                setTags,
                                goals,
                                setGoals,
                                setActiveQuestionIndex,
                                activeQuestionIndex
                            }) => {


    const [enableReflectionEditing, setEnableReflectionEditing] = useState([]);
    const [render, setRender] = useState("");
    const [surveyGoals, setSurveyGoals] = useState("");
    const [surveyTags, setSurveyTags] = useState("");
    const [survey, setSurvey] = useState({
        name: "",
        planLevel: "",
        status: Survey.SURVEY_STATUS_DRAFT,
        description: "",
        tags: [],
        goals: [],
        visibility: "TENANCY",
        questions: [],
    });

    useEffect(() => {
        if (parentId !== null) {
            let updatedSegments = reflectionSegments.map((segment, i) => {
                if (segment.questionsList && Array.isArray(segment.questionsList)) {
                    let updatedQuestionsList = segment.questionsList.map((question, index) => {
                        return {
                            ...question,
                            segmentIndex: i
                        }
                    })

                    return {
                        ...segment,
                        questionsList: updatedQuestionsList
                    };
                }
                return segment;
            });
            setReflectionSegments(updatedSegments)
        }

    }, [parentId]);

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const updatedCharacters = [...questionsList];
        const [movedCharacter] = updatedCharacters.splice(result.source.index, 1);
        updatedCharacters.splice(result.destination.index, 0, movedCharacter);

        setQuestionsList(updatedCharacters);
    };

    const handleQuestionTypeChange = (questionIndex, e, segmentIndex) => {

        if (segmentIndex !== null) {
            const updatedSegments = reflectionSegments.map((segment, segIndex) => {
                if (segIndex === segmentIndex && segment.questionsList) {
                    const updatedQuestionsList = segment.questionsList.map((question, index) => {
                        if (index === questionIndex) {
                            return {
                                ...question,
                                [e.target.name]: e.target.value,
                                options: e.target.value === QuestionType.OPTION ? [{
                                    optionText: "",
                                    optionValue: null
                                }, {optionText: "", optionValue: null}, {
                                    optionText: "",
                                    optionValue: null
                                }] : [],
                                number: e.target.value === QuestionType.NUMBER ? {
                                    min: null,
                                    max: null,
                                    isMinLowestRank: true
                                } : null
                            };
                        }
                        return question;
                    });
                    return {
                        ...segment,
                        questionsList: updatedQuestionsList
                    };
                }
                return segment;
            });
            setReflectionSegments(updatedSegments)
        } else {
            const updatedQuestionsList = [...questionsList];
            updatedQuestionsList[questionIndex] = {
                ...updatedQuestionsList[questionIndex],
                [e.target.name]: e.target.value,
                options: e.target.value === QuestionType.OPTION ? [{
                    optionText: "",
                    optionValue: null
                }, {optionText: "", optionValue: null}, {
                    optionText: "",
                    optionValue: null
                }] : [],
                number: e.target.value === QuestionType.NUMBER ? {
                    min: null,
                    max: null,
                    isMinLowestRank: true
                } : null
            };
            setQuestionsList(updatedQuestionsList);
        }
    };

    const isReflectionEditable = (Id) => {
        if (formType === "Add") {
            return true
        } else if (formType === "Update") {
            return true
        } else if (formType === "Edit" && typeof Id === "undefined") {
            return true
        }
        return false
    }

    const checkBtnPermission = (Id) => {
        return ((formType === "Add" && hasPermission([Permissions.REFLECTION_CREATE], true)) || ((formType === "Update") && hasPermission([Permissions.REFLECTION_WRITE], true) && (data.orgId === jwtDecode(getDataFromLocalStorage("token"))?.orgId || roleHasAccess([Roles.ORG_ADMIN])))
            || (formType === "Edit" && data.enable && hasPermission([Permissions.REFLECTION_WRITE], true) && (data.orgId === jwtDecode(getDataFromLocalStorage("token"))?.orgId || roleHasAccess([Roles.ORG_ADMIN])) && typeof Id === "undefined" || Id === undefined)
        )
    };

    const handleRequiredChange = (e, questionIndex, segmentIndex) => {
        if (segmentIndex !== null) {
            const updatedSegments = reflectionSegments.map((segment, segIndex) => {
                if (segIndex === segmentIndex && segment.questionsList) {
                    const updatedQuestionsList = segment.questionsList.map((question, index) => {
                        if (index === questionIndex) {
                            return {
                                ...question,
                                [e.target.name]: e.target.checked
                            };
                        }
                        return question;
                    });
                    return {
                        ...segment,
                        questionsList: updatedQuestionsList
                    };
                }
                return segment;
            });
            setReflectionSegments(updatedSegments)

        } else {
            const updatedQuestionsList = [...questionsList];
            updatedQuestionsList[questionIndex] = {
                ...updatedQuestionsList[questionIndex],
                [e.target.name]: e.target.checked
            };
            setQuestionsList(updatedQuestionsList);
        }

    };

    const handleChangeQuestionText = (e, questionIndex, segmentIndex) => {
        if (segmentIndex !== null) {
            const updatedSegments = reflectionSegments.map((segment, segIndex) => {
                if (segIndex === segmentIndex && segment.questionsList) {
                    const updatedQuestionsList = segment.questionsList.map((question, index) => {
                        if (index === questionIndex) {
                            return {
                                ...question,
                                [e.target.name]: e.target.value,
                                isTextLimitExceeded: false
                            };
                        }
                        return question;
                    });
                    return {
                        ...segment,
                        questionsList: updatedQuestionsList
                    };
                }
                return segment;
            });
            setReflectionSegments(updatedSegments)
        } else {
            const updatedQuestionsList = [...questionsList];
            updatedQuestionsList[questionIndex] = {
                ...updatedQuestionsList[questionIndex],
                [e.target.name]: e.target.value,
                isTextLimitExceeded: false
            };
            setQuestionsList(updatedQuestionsList);
        }
    };

    const updateMinMaxNumber = (e, questionIndex, segmentIndex) => {
        if (segmentIndex !== null) {
            const updatedSegments = reflectionSegments.map((segment, segIndex) => {
                if (segIndex === segmentIndex && segment.questionsList) {
                    const updatedQuestionsList = segment.questionsList.map((question, index) => {
                        if (index === questionIndex) {
                            return {
                                ...question,
                                number: {
                                    ...question.number,
                                    [e.target.name]: e.target.value === "" ? null : e.target.value
                                }
                            };
                        }
                        return question;
                    });
                    return {
                        ...segment,
                        questionsList: updatedQuestionsList
                    };
                }
                return segment;
            });
            setReflectionSegments(updatedSegments);
        } else {
            const updatedQuestionsList = [...questionsList];
            updatedQuestionsList[questionIndex] = {
                ...updatedQuestionsList[questionIndex],
                number: {
                    ...updatedQuestionsList[questionIndex].number,
                    [e.target.name]: e.target.value === "" ? null : e.target.value
                }
            };
            setQuestionsList(updatedQuestionsList);
        }
    };

    const handleDeleteQuestion = (index, segmentIndex) => {
        if (segmentType === "LINEAR") {
            setActiveQuestionIndex({
                ...activeQuestionIndex,
                rowIndex: 0,
                columnIndex: null
            })
            setGoals(removeItemFromArrayAtIndex([...goals], index))
            setTags(removeItemFromArrayAtIndex([...tags], index))
        }
        if (segmentType === "TABULAR") {
            if (goals?.length > 0 && goals?.[parentId]?.[index] !== undefined && goals?.[parentId]?.[index] !== null && goals?.[parentId]?.[index] !== "") {
                let updatedGoals = goals?.map(row => [...row]);
                updatedGoals[parentId][index] = undefined
                setGoals(updatedGoals)
            }
            if (tags?.length > 0 && tags?.[parentId]?.[index] !== undefined && tags?.[parentId]?.[index] !== null && tags?.[parentId]?.[index] !== "") {
                let updatedTags = tags?.map(row => [...row]);
                updatedTags[parentId][index] = undefined
                setTags(updatedTags)
            }
        }
        if (segmentIndex !== null) {
            let shouldDeleteSegement = false;

            const updatedSegments = reflectionSegments.map((segment, segIndex) => {
                if (segIndex === segmentIndex && segment.questionsList) {
                    const updatedQuestionsList = segment.questionsList.filter((question, questionIndex) => questionIndex !== index);
                    shouldDeleteSegement = Array.isArray(updatedQuestionsList) && updatedQuestionsList.length === 0;
                    return {
                        ...segment,
                        questionsList: updatedQuestionsList
                    };
                }
                return segment;
            });

            if (Array.isArray(updatedSegments) && updatedSegments.length > 0 && shouldDeleteSegement) {
                let filteredSegments = updatedSegments.filter((segment, ind) => segmentIndex !== ind);

                if (filteredSegments.length === 0) {
                    setSegmentType("LINEAR");
                    handleSelectedParentSection(0, 0, [defaultQuestionForReflection]);
                }

                const updatedFilteredSegments = filteredSegments.map((segment, index) => ({
                    ...segment,
                    questionsList: segment.questionsList.map((question, questionIndex) => ({
                        ...question,
                        segmentIndex: index
                    }))
                }));


                setReflectionSegments(updatedFilteredSegments);
            } else {
                setReflectionSegments(updatedSegments);
            }

        } else {
            let latestList = questionsList.filter((el, i) => {
                return i !== index;
            })
            if (Array.isArray(latestList) && latestList.length > 0) {
                if (latestList[0]?.hasOwnProperty('isShow')) {
                    latestList[0].isShow = true;
                }
            }
            setQuestionsList(latestList);
        }

    };

    const handleEnableEditing = (index) => {
        let updatedEnableReflectionEditing = [...enableReflectionEditing]
        updatedEnableReflectionEditing[index] = true;
        setEnableReflectionEditing(updatedEnableReflectionEditing);

    }

    const setOptionTextLimitExceeds = (index, optionIndex) => {
        const updatedQuestionsList = [...questionsList];
        const updatedOptions = [...updatedQuestionsList[index].options];
        updatedOptions[optionIndex] = {
            ...updatedOptions[optionIndex],
            isTextLimitExceeded: true
        };
        updatedQuestionsList[index] = {
            ...updatedQuestionsList[index],
            options: updatedOptions
        };
        setQuestionsList(updatedQuestionsList);
        setRender(new Date().getMilliseconds());
    }

    const handleAddOptions = (e, questionIndex, i, segmentIndex) => {

        if (segmentIndex !== null) {
            const newOption = {
                isTextLimitExceeded: false,
                optionText: "",
                optionValue: null
            };
            const updatedQuestionsList = reflectionSegments.map((segment, segIndex) => {
                if (segIndex === segmentIndex && segment.questionsList) {
                    return {
                        ...segment,
                        questionsList: segment.questionsList.map((question, index) => {
                            if (index === questionIndex) {
                                if (question.options.length >= 10) {
                                    toast.info(OutOfRange, {toastId: "toast"});
                                    return question; // Return unchanged question if the option limit is exceeded
                                }
                                return {
                                    ...question,
                                    options: [...question.options, newOption]
                                };
                            }
                            return question;
                        })
                    };
                }
                return segment;
            });
            setReflectionSegments(updatedQuestionsList);
        } else {
            const newOption = {
                isTextLimitExceeded: false,
                optionText: "",
                optionValue: null
            };
            const updatedQuestionsList = [...questionsList];
            updatedQuestionsList[questionIndex] = {
                ...updatedQuestionsList[questionIndex],
                options: [...updatedQuestionsList[questionIndex].options, newOption]
            };
            if (updatedQuestionsList[questionIndex].options.length > 10) {
                toast.info(OutOfRange, {toastId: "toast"})
            } else {
                setQuestionsList(updatedQuestionsList);
            }
        }


    };

    const handleAddQuestion = (questionIndex, parentId) => {

        if (parentId !== null) {
            const updatedReflectionSegments = [...reflectionSegments];
            const segmentToUpdate = updatedReflectionSegments.find((segment, segIndex) => segIndex === parentId);
            if (segmentToUpdate && segmentToUpdate.questionsList) {
                const updatedQuestionsList = segmentToUpdate.questionsList.map((question, index) => {
                    if (index === questionIndex) {
                        return {...question, isShow: false};
                    }
                    return question;
                });

                const newQuestion = {
                    questionText: "",
                    isTextLimitExceeded: false,
                    type: QuestionType.OPTION,
                    isShow: false,
                    isRequired: true,
                    segmentIndex: parentId,
                    sortOrder: 0,
                    options: [{optionText: "", optionValue: null}, {optionText: "", optionValue: null}, {
                        optionText: "",
                        optionValue: null
                    }],
                    number: {},
                    tags: [],
                    goals: []
                };

                updatedQuestionsList.push(newQuestion);
                segmentToUpdate.questionsList = updatedQuestionsList;
                setReflectionSegments(updatedReflectionSegments);
            }
        } else {
            let updatedQuestions = questionsList.map((question, index) => {
                if (index === questionIndex) {
                    return {...question, isShow: false};
                }
                return question;
            });

            const newQuestion = {
                questionText: "",
                isTextLimitExceeded: false,
                type: QuestionType.OPTION,
                isShow: !(Array.isArray(updatedQuestions) && updatedQuestions.find(c => c.isShow === true)),
                isRequired: true,
                segmentIndex: null,
                sortOrder: 0,
                options: [{optionText: "", optionValue: null}, {optionText: "", optionValue: null}, {
                    optionText: "",
                    optionValue: null
                }],
                number: {},
                tags: [],
                goals: []
            };

            updatedQuestions.push(newQuestion);
            setQuestionsList(updatedQuestions);

        }


    };
    const handleRemoveOptions = (e, questionIndex, i, segmentIndex) => {
        if (segmentIndex !== null) {
            const updatedQuestionsList = reflectionSegments.map((segment, segIndex) => {
                if (segIndex === segmentIndex && segment.questionsList) {
                    return {
                        ...segment,
                        questionsList: segment.questionsList.map((question, index) => {
                            if (index === questionIndex) {
                                const updatedOptions = question.options.filter((option, optIndex) => optIndex !== i);
                                return {
                                    ...question,
                                    options: updatedOptions
                                };
                            }
                            return question;
                        })
                    };
                }
                return segment;
            });

            setReflectionSegments(updatedQuestionsList);
        } else {
            const updatedQuestionsList = [...questionsList];
            const updatedOptions = [...updatedQuestionsList[questionIndex].options];
            updatedOptions.splice(i, 1);
            updatedQuestionsList[questionIndex] = {
                ...updatedQuestionsList[questionIndex],
                options: updatedOptions,
            };
            setQuestionsList(updatedQuestionsList);
        }
    };

    const handleKeyDown = (e, index, segmentIndex) => {
        if (e.key === "Enter") {
            if (segmentIndex !== null) {
                const updatedSegments = reflectionSegments.map((segment, segIndex) => {
                    if (segIndex === segmentIndex && segment.questionsList) {
                        return {
                            ...segment,
                            questionsList: segment.questionsList.map((question, qIndex) => {
                                if (qIndex === index) {
                                    const newValue = e.target.value.trim();
                                    const updatedValues = [...question[e.target.name]];
                                    if (!updatedValues.includes(newValue) && newValue !== null && newValue !== undefined && newValue !== "") {
                                        updatedValues.push(newValue);
                                    }
                                    return {
                                        ...question,
                                        [e.target.name]: updatedValues
                                    };
                                }
                                return question;
                            })
                        };
                    }
                    return segment;
                });
                setReflectionSegments(updatedSegments);
                e.target.name === "tags" ? handleTagChange("", index) : handleGoalChange("", index)
            } else {
                if (index === -1) {
                    if (!isNullOrEmpty(e.target.value.trim()) && !survey[e.target.name].includes(e.target.value.trim())) {
                        setSurvey({...survey, [e.target.name]: [...survey[e.target.name], e.target.value.trim()]});
                    }
                    e.target.name === "tags" ? setSurveyTags("") : setSurveyGoals("")
                } else {
                    const updatedQuestionsList = [...questionsList];
                    const question = {...updatedQuestionsList[index]};

                    if (!isNullOrEmpty(e.target.value.trim()) && !question[e.target.name].includes(e.target.value.trim())) {
                        question[e.target.name] = [...question[e.target.name], e.target.value.trim()];
                    }
                    updatedQuestionsList[index] = question;
                    setQuestionsList(updatedQuestionsList);
                    e.target.name === "tags" ? handleTagChange("", index) : handleGoalChange("", index)
                }
            }

        }
    };

    const handleOptionChange = (e, questionIndex, optionIndex, segmentIndex) => {
        const {name, value} = e.target;
        const numericValue = parseFloat(value);
        if (segmentIndex !== null) {
            const updatedSegments = reflectionSegments.map((segment, segIndex) => {
                if (segIndex === segmentIndex && segment.questionsList) {
                    const updatedQuestionsList = segment.questionsList.map((question, index) => {
                        if (index === questionIndex) {
                            const updatedOptions = question.options.map((option, optIndex) => {
                                if (optIndex === optionIndex) {
                                    if (name === "optionValue") {
                                        const numericValue = parseFloat(e.target.value);
                                        if ((numericValue > 0 && numericValue <= 100) || isNaN(numericValue)) {
                                            return {
                                                ...option,
                                                [e.target.name]: e.target.value,
                                                isTextLimitExceeded: false
                                            };
                                        } else {
                                            toast.info("Weight should be a value greater than 0 and less than or equal to 100. Please review and adjust your choices.", {toastId: "toast"});
                                            return option;
                                        }
                                    } else {
                                        return {
                                            ...option,
                                            [e.target.name]: e.target.value,
                                            isTextLimitExceeded: false
                                        };
                                    }
                                }
                                return option;
                            });
                            return {
                                ...question,
                                options: updatedOptions
                            };
                        }
                        return question;
                    });

                    return {
                        ...segment,
                        questionsList: updatedQuestionsList
                    };
                }
                return segment;
            });

            setReflectionSegments(updatedSegments);
        } else {
            //ToDo code optimize later
            if (name === "optionValue") {
                if ((numericValue > 0 && numericValue <= 100) || isNaN(numericValue)) {
                    const updatedQuestionsList = [...questionsList];
                    const updatedOptions = [...updatedQuestionsList[questionIndex].options];
                    updatedOptions[optionIndex] = {
                        ...updatedOptions[optionIndex],
                        [e.target.name]: e.target.value,
                        isTextLimitExceeded: false
                    };
                    updatedQuestionsList[questionIndex] = {
                        ...updatedQuestionsList[questionIndex],
                        options: updatedOptions
                    };
                    setQuestionsList(updatedQuestionsList);
                } else {
                    toast.info(`Weight  should be a value greater than 0 and less than or equal to 100. Please review and adjust your choices.`, {toastId: "toast"})
                }
            } else {
                const updatedQuestionsList = [...questionsList];
                const updatedOptions = [...updatedQuestionsList[questionIndex].options];
                updatedOptions[optionIndex] = {
                    ...updatedOptions[optionIndex],
                    [e.target.name]: e.target.value,
                    isTextLimitExceeded: false
                };
                updatedQuestionsList[questionIndex] = {
                    ...updatedQuestionsList[questionIndex],
                    options: updatedOptions
                };
                setQuestionsList(updatedQuestionsList);
            }
        }


    }

    const setQuestionTextLimitExceeds = (index) => {
        let updatedQuestionsList = [...questionsList];
        updatedQuestionsList[index] = {
            ...updatedQuestionsList[index],
            isTextLimitExceeded: true
        };
        setRender(new Date().getMilliseconds());
        setQuestionsList(updatedQuestionsList);
    };

    const handleTagChange = (value, index) => {
        const updatedTags = [...tags]
        if (segmentType === "LINEAR") {
            updatedTags[index] = value;
        }
        if (segmentType === "TABULAR") {
            let parentIndexArray = updatedTags[parentId] || []
            parentIndexArray[index] = value
            updatedTags[parentId] = parentIndexArray;
        }
        setTags(updatedTags)

    };

    const handleGoalChange = (value, index) => {
        const updatedGoals = [...goals]
        if (segmentType === "LINEAR") {
            updatedGoals[index] = value;
        }
        if (segmentType === "TABULAR") {
            let parentIndexArray = updatedGoals[parentId] || []
            parentIndexArray[index] = value
            updatedGoals[parentId] = parentIndexArray;
        }
        setGoals(updatedGoals)
    };

    const updateMinMaxValue = (e, questionIndex, segmentIndex) => {

        if (segmentIndex !== null) {
            const updatedSegments = reflectionSegments.map((segment, segIndex) => {
                if (segIndex === segmentIndex && segment.questionsList) {
                    const updatedQuestionsList = segment.questionsList.map((question, index) => {
                        if (index === questionIndex) {
                            return {
                                ...question,
                                number: {
                                    ...question.number,
                                    isMinLowestRank: e.target.value === 'true' ? true : false
                                }
                            };
                        }
                        return question;
                    });
                    return {
                        ...segment,
                        questionsList: updatedQuestionsList
                    };
                }
                return segment;
            });
            setReflectionSegments(updatedSegments);
        } else {
            const updatedQuestionsList = [...questionsList];
            updatedQuestionsList[questionIndex] = {
                ...updatedQuestionsList[questionIndex],
                number: {
                    ...updatedQuestionsList[questionIndex].number,
                    [e.target.name]: e.target.value === 'true' ? true : false
                }
            };
            setRender(new Date().getMilliseconds());
            setQuestionsList(updatedQuestionsList);
        }

    };

    const handleRemoveGoals = (index, tagIndex, segmentId) => {

        if (segmentId !== null) {
            const updatedSegments = reflectionSegments.map((segment, segIndex) => {
                if (segIndex === segmentId && segment.questionsList) {
                    return {
                        ...segment,
                        questionsList: segment.questionsList.map((question, qIndex) => {
                            if (qIndex === index) {
                                const updatedGoals = [...question.goals];
                                updatedGoals.splice(tagIndex, 1);
                                return {
                                    ...question,
                                    goals: updatedGoals
                                };
                            }
                            return question;
                        })
                    };
                }
                return segment;
            });
            setReflectionSegments(updatedSegments);
        } else {
            const updatedQuestionsList = [...questionsList];
            const question = {...updatedQuestionsList[index]};
            const goals = [...question.goals];
            goals.splice(tagIndex, 1);
            question.goals = goals;
            updatedQuestionsList[index] = question;
            setQuestionsList(updatedQuestionsList);
        }

    };
    const handleRemoveTag = (index, tagIndex, segmentId) => {
        if (segmentId !== null) {
            const updatedSegments = reflectionSegments.map((segment, segIndex) => {
                if (segIndex === segmentId && segment.questionsList) {
                    return {
                        ...segment,
                        questionsList: segment.questionsList.map((question, qIndex) => {
                            if (qIndex === index) {
                                const updatedTags = [...question.tags];
                                updatedTags.splice(tagIndex, 1);
                                return {
                                    ...question,
                                    tags: updatedTags
                                };
                            }
                            return question;
                        })
                    };
                }
                return segment;
            });
            setReflectionSegments(updatedSegments);
        } else {
            const updatedQuestionsList = [...questionsList];
            const question = {...updatedQuestionsList[index]};
            const tags = [...question.tags];
            tags.splice(tagIndex, 1);
            question.tags = tags;
            updatedQuestionsList[index] = question;
            setQuestionsList(updatedQuestionsList)
        }
    };

    console.log("questionsList====>",questionsList)
    console.log("reflectionSegments====>",reflectionSegments)
    console.log("reflectionSegments[parentId]====>",reflectionSegments[parentId])
    console.log("activeQuestionIndex====>",activeQuestionIndex)
    console.log("parentId====>",parentId)
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="characters">
                {
                    (provided) => (
                        <ul className="inner_item" {...provided.droppableProps} ref={provided.innerRef}>
                            {
                                segmentType === "LINEAR" &&
                                <>
                                    {Array.isArray(questionsList) && questionsList.map((question, index) => (
                                        <Draggable key={index} draggableId={"characters" + index} index={index}>
                                            {
                                                (provided) => (
                                                    <li ref={provided.innerRef} {...provided.draggableProps}
                                                        key={index}>
                                                        {question.isShow &&
                                                            <>
                                                                <div className={"d-flex"}>
                                                                    <Card
                                                                        className={segmentType === "LINEAR " ? 'question-card question-list' : " question-card question-list question-card question-list input"}>
                                                                        <Card.Body>
                                                                            <div className={"required-check "}>
                                                                                {
                                                                                    isReflectionEditable(question?.questionId) &&
                                                                                    <select
                                                                                        value={question.type}
                                                                                        disabled={checkBtnPermission() === false}
                                                                                        name={"type"}
                                                                                        onChange={(e) => {
                                                                                            if (checkBtnPermission() === true) {
                                                                                                handleQuestionTypeChange(index, e, parentId)
                                                                                            }
                                                                                        }}
                                                                                        className={"form-controls question-type-dropdown"}>
                                                                                        <option
                                                                                            value={QuestionType.OPTION}>Option
                                                                                        </option>
                                                                                        <option
                                                                                            value={QuestionType.TEXT}>Text
                                                                                        </option>
                                                                                        <option
                                                                                            value={QuestionType.NUMBER}>Number
                                                                                        </option>
                                                                                    </select>
                                                                                }
                                                                                <label
                                                                                    className={"required-label ps-2"}>
                                                                                    <input name={"isRequired"}
                                                                                           disabled={formType === "Edit" ? checkBtnPermission(question?.questionId) === false && !enableReflectionEditing[index] : checkBtnPermission() === false}
                                                                                           onChange={(e) => {
                                                                                               if (checkBtnPermission() === true) {
                                                                                                   handleRequiredChange(e, index, parentId)
                                                                                               }
                                                                                           }}
                                                                                           checked={questionsList[index].isRequired}
                                                                                           className={"required-checkbox"}
                                                                                           type="checkbox"/>
                                                                                    Required
                                                                                </label>
                                                                            </div>
                                                                            <div
                                                                                className={"positiveNegative mediumFontSize"}>
                                                                                <h2 className={segmentType === "LINEAR" ? " " : "cmn_font"}> {`${index + 1}.`} Question</h2>
                                                                            </div>
                                                                            <div className="question-dropdown-outer">
                                                                                <Form.Group className='detail_para'>
                                                                                    <Form.Control type="text"
                                                                                                  value={questionsList[index].questionText}
                                                                                                  name={"questionText"}
                                                                                                  onChange={(e) => {
                                                                                                      if (checkBtnPermission() === true) {
                                                                                                          if (e.target.value.length <= 512) {
                                                                                                              handleChangeQuestionText(e, index, parentId)
                                                                                                          } else if (e.target.value.length > 512) {
                                                                                                              setQuestionTextLimitExceeds(index)
                                                                                                          }
                                                                                                      }
                                                                                                  }}
                                                                                                  disabled={formType === "Edit" ? checkBtnPermission(question?.questionId) === false && !enableReflectionEditing[index] : checkBtnPermission() === false}
                                                                                                  className={segmentType === "LINEAR" ? "cmn_fontStyle " : "cmn_fontStyle cmn_input_size"}
                                                                                                  placeholder=''>
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                                <div className='d-flex'>
                                                                                    {
                                                                                        isReflectionEditable(question?.questionId) ?
                                                                                            <div
                                                                                                className={('crossIcon ms-2') + (checkBtnPermission() === true ? "" : "cursor-default")}
                                                                                                onClick={() => {
                                                                                                    if (checkBtnPermission() === true) {
                                                                                                        handleDeleteQuestion(index, parentId)
                                                                                                    }
                                                                                                }}>
                                                                                                <RiDeleteBin6Line
                                                                                                    className={""}
                                                                                                    style={{fill: checkBtnPermission() === true ? "red" : "grey"}}/>
                                                                                            </div>
                                                                                            :
                                                                                            <>

                                                                                                <div
                                                                                                    onClick={() => {
                                                                                                        if (checkBtnPermission() === true) {
                                                                                                            handleEnableEditing(index)

                                                                                                        }
                                                                                                    }}
                                                                                                    className={('crossIcon ms-2') + (checkBtnPermission() === true ? "" : "disable-edit-reflection-pencil-btn")}>
                                                                                                    <HiPencil
                                                                                                        className={"edit-reflection-pencil-btn "}/>
                                                                                                </div>

                                                                                                <div
                                                                                                    className={('crossIcon ms-2') + (checkBtnPermission() === true ? "" : "cursor-default")}
                                                                                                    onClick={() => {
                                                                                                        if (checkBtnPermission() === true) {

                                                                                                            handleDeleteQuestion(index, parentId)
                                                                                                        }
                                                                                                    }}>
                                                                                                    <RiDeleteBin6Line
                                                                                                        className={""}
                                                                                                        style={{fill: checkBtnPermission() === true ? "red" : "grey"}}/>
                                                                                                </div>
                                                                                            </>

                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                questionsList[index].isTextLimitExceeded &&
                                                                                <div
                                                                                    className={"question-length-exceeds-warning "}>Question
                                                                                    length cannot be greater than 512
                                                                                    characters</div>
                                                                            }
                                                                            {
                                                                                question.type === QuestionType.NUMBER &&
                                                                                <div
                                                                                    className={"number-range-value-outer"}>
                                                                                    <div
                                                                                        className={"mt-2 min-value-container"}>
                                                                                        <div
                                                                                            className={segmentType === "LINEAR" ? "max-value-heading ms-2" : "max-value-heading ms-2 cmn_font"}>Min
                                                                                            :
                                                                                        </div>
                                                                                        <input type={"number"}
                                                                                               inputMode={"numeric"}
                                                                                               name={"min"}
                                                                                               disabled={formType === "Edit" ? checkBtnPermission(question?.questionId) === false && !enableReflectionEditing[index] : checkBtnPermission() === false}
                                                                                               value={questionsList[index].number.min}
                                                                                               onWheel={(e) => {
                                                                                                   document.activeElement.blur();
                                                                                               }}
                                                                                               onKeyPress={(e) => {
                                                                                                   const isValidKey = /^\d*$/.test(e.key);
                                                                                                   if (!isValidKey) {
                                                                                                       e.preventDefault();
                                                                                                   }
                                                                                               }}
                                                                                               onKeyDown={(e) => {
                                                                                                   if (e.key === '.' || e.key === "-") {
                                                                                                       e.preventDefault();
                                                                                                   }
                                                                                               }}
                                                                                               onChange={(e) => {
                                                                                                   if (checkBtnPermission() === true && e.target.value >= 0) {
                                                                                                       updateMinMaxNumber(e, index, parentId)
                                                                                                   }
                                                                                               }}
                                                                                               min={0} placeholder={"0"}
                                                                                               className={segmentType === "LINEAR" ? "form-control range-value-input me-auto" : "form-control range-value-input me-auto cmn_input_size"}/>
                                                                                        <div
                                                                                            className={"me-2 good-bad-outer"}>
                                                                                            <div
                                                                                                className={question.number.isMinLowestRank ? "color-data-high-risk" : "color-data-good"}/>
                                                                                            <select
                                                                                                name={"isMinLowestRank"}
                                                                                                value={question.number.isMinLowestRank}
                                                                                                disabled={formType === "Edit" ? checkBtnPermission(question?.questionId) === false && !enableReflectionEditing[index] : checkBtnPermission() === false}
                                                                                                className={"good-bad-dropdown"}
                                                                                                onChange={(e) => {
                                                                                                    if (checkBtnPermission() === true) {
                                                                                                        updateMinMaxValue(e, index, parentId)
                                                                                                    }
                                                                                                }}>
                                                                                                <option
                                                                                                    value={true}>{Marks.HIGH_RISK}</option>
                                                                                                <option
                                                                                                    value={false}>{Marks.GOOD}</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className={"mt-2 max-value-container"}>
                                                                                        <div
                                                                                            className={segmentType === "LINEAR" ? "max-value-heading ms-2" : "max-value-heading ms-2 cmn_font"}>Max
                                                                                            :
                                                                                        </div>
                                                                                        <input type={"number"}
                                                                                               inputMode={"numeric"}
                                                                                               name={"max"}
                                                                                               value={question.number.max}
                                                                                               onWheel={(e) => {
                                                                                                   document.activeElement.blur();
                                                                                               }}
                                                                                               onKeyPress={(e) => {
                                                                                                   const isValidKey = /^\d*$/.test(e.key);
                                                                                                   if (!isValidKey) {
                                                                                                       e.preventDefault();
                                                                                                   }
                                                                                               }}
                                                                                               onKeyDown={(e) => {
                                                                                                   if (e.key === '.' || e.key === "-") {
                                                                                                       e.preventDefault();
                                                                                                   }
                                                                                               }}
                                                                                               disabled={formType === "Edit" ? checkBtnPermission(question?.questionId) === false && !enableReflectionEditing[index] : checkBtnPermission() === false}
                                                                                               onChange={(e) => {
                                                                                                   if (e.target.value >= 0) {
                                                                                                       updateMinMaxNumber(e, index, parentId)
                                                                                                   }
                                                                                               }} min={0}
                                                                                               placeholder={"..."}
                                                                                               className={segmentType === "LINEAR" ? "form-control range-value-input me-auto" : "form-control range-value-input me-auto cmn_input_size"}/>
                                                                                        <div
                                                                                            className={"me-2 good-bad-outer"}>
                                                                                            <div
                                                                                                className={question.number.isMinLowestRank ? "color-data-good" : "color-data-high-risk"}/>
                                                                                            <select
                                                                                                value={question.number.isMinLowestRank}
                                                                                                name={"isMinLowestRank"}
                                                                                                disabled={formType === "Edit" ? checkBtnPermission(question?.questionId) === false && !enableReflectionEditing[index] : checkBtnPermission() === false}

                                                                                                className={"good-bad-dropdown"}
                                                                                                onChange={(e) => {
                                                                                                    if (checkBtnPermission() === true) {
                                                                                                        updateMinMaxValue(e, index, parentId)

                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                <option value={false}
                                                                                                        selected={!question.number.isMinLowestRank}>{Marks.HIGH_RISK}</option>
                                                                                                <option value={true}
                                                                                                        selected={question.number.isMinLowestRank}>{Marks.GOOD}</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                question.type === QuestionType.OPTION &&
                                                                                <div
                                                                                    className={"options-list-container mt-2"}>
                                                                                    {
                                                                                        [...Array(questionsList[index].options.length)].map((x, i) => {

                                                                                            return (

                                                                                                <>

                                                                                                    <div key={i}
                                                                                                         className={"option-select-container mt-2 position-relative"}>
                                                                                                        <div
                                                                                                            className={"option-container"}>
                                                                                                            <div
                                                                                                                className={segmentType === "LINEAR" ? "option-heading " : "option-heading cmn_font"}>{"Option " + (i + 1)}
                                                                                                                <input
                                                                                                                    type={"text"}
                                                                                                                    name={"optionText"}
                                                                                                                    disabled={formType === "Edit" ? checkBtnPermission(question?.questionId) === false && !enableReflectionEditing[index] : checkBtnPermission() === false}
                                                                                                                    onChange={(e) => {
                                                                                                                        if (checkBtnPermission() === true) {
                                                                                                                            if (e.target.value.length <= 512) {
                                                                                                                                handleOptionChange(e, index, i, parentId)
                                                                                                                            } else if (e.target.value.length > 512) {
                                                                                                                                setOptionTextLimitExceeds(index, i, parentId)
                                                                                                                            }

                                                                                                                        }
                                                                                                                    }}
                                                                                                                    value={questionsList[index].options[i].optionText}
                                                                                                                    className={segmentType === "LINEAR" ? "form-control option-input" : "form-control option-input cmn_input_size"}
                                                                                                                    placeholder={"Enter option here"}/>
                                                                                                            </div>

                                                                                                            <div
                                                                                                                className={"risk-color-and-dropdown"}>
                                                                                                                <input
                                                                                                                    placeholder={"Enter weight here..."}
                                                                                                                    onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                                                                                                    disabled={formType === "Edit" ? checkBtnPermission(question?.questionId) === false && !enableReflectionEditing[index] : checkBtnPermission() === false}
                                                                                                                    value={questionsList[index].options[i].optionValue}
                                                                                                                    className={segmentType === "LINEAR" ? "form-control " : "form-control cmn_input_size"}
                                                                                                                    type={"number"}
                                                                                                                    min={1}
                                                                                                                    name={"optionValue"}
                                                                                                                    onChange={(e) => {
                                                                                                                        if (checkBtnPermission() === true) {
                                                                                                                            handleOptionChange(e, index, i, parentId);
                                                                                                                        }
                                                                                                                    }}/>
                                                                                                            </div>

                                                                                                            {((formType === "Edit" || formType === "Update") && i > 2 && enableReflectionEditing[index]) &&
                                                                                                                <div
                                                                                                                    className={"add-Icon-option"}>
                                                                                                                    <i onClick={(e) => {
                                                                                                                        if (checkBtnPermission() === true) {
                                                                                                                            handleRemoveOptions(e, index, i, parentId)
                                                                                                                        }
                                                                                                                    }}
                                                                                                                       className="fa fa-trash trash-icon"/>

                                                                                                                </div>
                                                                                                            }
                                                                                                            {(formType === "Update" && i > 2 && !enableReflectionEditing[index]) &&
                                                                                                                <div
                                                                                                                    className={"add-Icon-option"}>
                                                                                                                    <i onClick={(e) => {
                                                                                                                        if (checkBtnPermission() === true) {
                                                                                                                            handleRemoveOptions(e, index, i, parentId)
                                                                                                                        }
                                                                                                                    }}
                                                                                                                       className="fa fa-trash trash-icon"/>

                                                                                                                </div>
                                                                                                            }

                                                                                                            {(formType === "Add" && i > 2 && !enableReflectionEditing[index]) &&
                                                                                                                <div
                                                                                                                    className={"add-Icon-option"}>
                                                                                                                    <i onClick={(e) => {
                                                                                                                        if (checkBtnPermission() === true) {
                                                                                                                            handleRemoveOptions(e, index, i, parentId)
                                                                                                                        }
                                                                                                                    }}
                                                                                                                       className="fa fa-trash trash-icon"/>

                                                                                                                </div>
                                                                                                            }


                                                                                                        </div>

                                                                                                        {
                                                                                                            questionsList[index].options[i].isTextLimitExceeded &&
                                                                                                            <div
                                                                                                                className={"option-length-exceeds-warning"}>Option
                                                                                                                length
                                                                                                                cannot
                                                                                                                exceed
                                                                                                                512
                                                                                                                characters</div>
                                                                                                        }

                                                                                                    </div>


                                                                                                    {((formType === "Add" || formType === "Update") && i === 2 && !enableReflectionEditing[index]) &&

                                                                                                        <div
                                                                                                            style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                                                            className={"add-Icon-button cmn_background_color"}>
                                                                                                            <i className={"fa fa-plus add-tag-icon"}/>
                                                                                                            <button
                                                                                                                style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                                                                className={"cmn_background_color"}
                                                                                                                onClick={(e) => {
                                                                                                                    if (checkBtnPermission() === true) {
                                                                                                                        handleAddOptions(e, index, i, parentId)
                                                                                                                    }
                                                                                                                }}>Add
                                                                                                                More
                                                                                                                Options...
                                                                                                            </button>

                                                                                                        </div>
                                                                                                    }
                                                                                                    {(formType === "Edit" && i === 2 && enableReflectionEditing[index]) &&

                                                                                                        <div
                                                                                                            className={"add-Icon-button"}>
                                                                                                            <i className={"fa fa-plus add-tag-icon"}/>
                                                                                                            <button
                                                                                                                onClick={(e) => {
                                                                                                                    if (checkBtnPermission() === true) {
                                                                                                                        handleAddOptions(e, index, i, parentId)
                                                                                                                    }
                                                                                                                }}>Add
                                                                                                                More
                                                                                                                Options...
                                                                                                            </button>

                                                                                                        </div>
                                                                                                    }

                                                                                                </>


                                                                                            )
                                                                                        })
                                                                                    }


                                                                                </div>
                                                                            }


                                                                            <div
                                                                                className={"tag-outer mt-3 mb-2 material-icons"}>
                                                                                {
                                                                                    questionsList[index].tags?.map((tag, tagIndex) => {
                                                                                        return (<div className={"tags"}
                                                                                                     key={tagIndex}>
                                                                                            <div className={"tag-text"}
                                                                                                 title={tag}>{trim(tag, 12)}</div>
                                                                                            <div className={"tag-cross"}
                                                                                                 onClick={() => {
                                                                                                     if (checkBtnPermission() === true) {
                                                                                                         handleRemoveTag(index, tagIndex, parentId)
                                                                                                     }
                                                                                                 }}>
                                                                                                <i className={"fa fa-times remove-tag-icon"}/>
                                                                                            </div>
                                                                                        </div>)
                                                                                    })
                                                                                }
                                                                                <div className={"add-tag-outer"}>
                                                                                    <i style={{color: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                                       className={"fa fa-plus add-tag-icon"}/>
                                                                                    <input value={tags[index]}
                                                                                           name={"tags"}
                                                                                           disabled={checkBtnPermission() === false}
                                                                                           onKeyDown={(e) => {
                                                                                               if (checkBtnPermission() === true) {
                                                                                                   handleKeyDown(e, index, parentId, parentId)
                                                                                               }
                                                                                           }}
                                                                                           onChange={(e) => {
                                                                                               checkBtnPermission() === true ? handleTagChange(e.target.value, index) : handleTagChange("", index)
                                                                                           }}
                                                                                           placeholder={"Add Question Tag"}
                                                                                           className={segmentType === "LINEAR" ? "tags-input-field" : " tags-input-field cmn_input_size"}
                                                                                           type={"text"}/>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className={"tag-outer  mb-2 material-icons"}>
                                                                                {
                                                                                    questionsList[index].goals?.map((goal, tagIndex) => {
                                                                                        return (<div className={"goals"}
                                                                                                     key={tagIndex}>
                                                                                            <div className={"tag-text"}
                                                                                                 title={goal}>{trim(goal, 12)}</div>
                                                                                            <div className={"tag-cross"}
                                                                                                 onClick={() => {
                                                                                                     if (checkBtnPermission() === true) {

                                                                                                         handleRemoveGoals(index, tagIndex, parentId)
                                                                                                     }
                                                                                                 }}><i
                                                                                                className={"fa fa-times remove-tag-icon"}/>
                                                                                            </div>
                                                                                        </div>)
                                                                                    })
                                                                                }
                                                                                <div className={"add-tag-outer"}>
                                                                                    <i style={{color: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                                       className={"fa fa-plus add-tag-icon"}/>
                                                                                    <input value={goals[index]}
                                                                                           name={"goals"}
                                                                                           onKeyDown={(e) => {
                                                                                               if (checkBtnPermission() === true) {
                                                                                                   handleKeyDown(e, index, parentId)
                                                                                               }
                                                                                           }}
                                                                                           disabled={checkBtnPermission() === false}

                                                                                           onChange={(e) => {
                                                                                               checkBtnPermission() === true ? handleGoalChange(e.target.value, index) : handleGoalChange("", index)
                                                                                           }}


                                                                                           placeholder={"Add Question Goal"}
                                                                                           className={segmentType === "LINEAR" ? "tags-input-field" : " tags-input-field cmn_input_size"}
                                                                                           type={"text"}/>
                                                                                </div>
                                                                            </div>


                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                            </>

                                                        }
                                                    </li>


                                                )
                                            }
                                        </Draggable>
                                    ))}

                                    <div className={"add-ques mt-2"}>
                                        <button disabled={checkBtnPermission() === false}
                                                onClick={() => {
                                                    if (checkBtnPermission() === true) {
                                                        handleAddQuestion(questionsList.length || 0, parentId)
                                                    }
                                                }}
                                                className="add-ques_btn cmn_fontStyle mediumFontSize "><i
                                            className="fa fa-plus"
                                            aria-hidden="true"/> Add
                                            question
                                        </button>
                                    </div>
                                </>
                            }
                            {
                                segmentType === "TABULAR" &&
                                <>
                                    {
                                        reflectionSegments[parentId]?.questionsList.map((question, index) => (
                                            <Draggable key={index} draggableId={"characters" + index} index={index}>
                                                {
                                                    (provided) => (
                                                        <li ref={provided.innerRef} {...provided.draggableProps}
                                                            key={index}>
                                                            {
                                                                question.isShow && <div className={"d-flex mt-4"}>
                                                                        <Card
                                                                            className={segmentType === "LINEAR " ? 'question-card question-list' : " question-card question-list question-card question-list input"}>
                                                                            <Card.Body>
                                                                                <div className={"required-check "}>
                                                                                    {
                                                                                        isReflectionEditable(question?.questionId) &&
                                                                                        <select
                                                                                            value={question.type}
                                                                                            disabled={checkBtnPermission() === false}
                                                                                            name={"type"}
                                                                                            onChange={(e) => {
                                                                                                if (checkBtnPermission() === true) {
                                                                                                    handleQuestionTypeChange(index, e, parentId)
                                                                                                }
                                                                                            }}
                                                                                            className={"form-controls question-type-dropdown"}>
                                                                                            <option value={QuestionType.OPTION}>Option</option>
                                                                                            <option value={QuestionType.TEXT}>Text</option>
                                                                                            <option value={QuestionType.NUMBER}>Number</option>
                                                                                        </select>
                                                                                    }
                                                                                    <label
                                                                                        className={"required-label ps-2"}>
                                                                                        <input name={"isRequired"}
                                                                                               disabled={formType === "Edit" ? checkBtnPermission(question?.questionId) === false && !enableReflectionEditing[index] : checkBtnPermission() === false}
                                                                                               onChange={(e) => {
                                                                                                   if (checkBtnPermission() === true) {
                                                                                                       handleRequiredChange(e, index, parentId)
                                                                                                   }
                                                                                               }}
                                                                                               checked={reflectionSegments[parentId]?.questionsList[index].isRequired}
                                                                                               className={"required-checkbox"}
                                                                                               type="checkbox"/>
                                                                                        Required
                                                                                    </label>

                                                                                </div>


                                                                                <div
                                                                                    className={"positiveNegative mediumFontSize"}>
                                                                                    <h2 className={segmentType === "LINEAR" ? " " : "cmn_font"}> {`${index + 1}.`} Question</h2>
                                                                                </div>

                                                                                <div
                                                                                    className="question-dropdown-outer">
                                                                                    <Form.Group className='detail_para'>
                                                                                        <Form.Control type="text"
                                                                                                      value={reflectionSegments[parentId]?.questionsList[index].questionText}
                                                                                                      name={"questionText"}
                                                                                                      onChange={(e) => {
                                                                                                          if (checkBtnPermission() === true) {
                                                                                                              if (e.target.value.length <= 512) {
                                                                                                                  handleChangeQuestionText(e, index, parentId)
                                                                                                              } else if (e.target.value.length > 512) {
                                                                                                                  setQuestionTextLimitExceeds(index)
                                                                                                              }

                                                                                                          }
                                                                                                      }}

                                                                                                      disabled={formType === "Edit" ? checkBtnPermission(question?.questionId) === false && !enableReflectionEditing[index] : checkBtnPermission() === false}
                                                                                                      className={segmentType === "LINEAR" ? "cmn_fontStyle " : "cmn_fontStyle cmn_input_size"}
                                                                                                      placeholder=''>
                                                                                        </Form.Control>
                                                                                    </Form.Group>
                                                                                    <div className='d-flex'>
                                                                                        {
                                                                                            isReflectionEditable(question?.questionId) ?
                                                                                                <div
                                                                                                    className={('crossIcon ms-2') + (checkBtnPermission() === true ? "" : "cursor-default")}

                                                                                                    onClick={() => {
                                                                                                        if (checkBtnPermission() === true) {
                                                                                                            handleDeleteQuestion(index, parentId)
                                                                                                        }
                                                                                                    }}>
                                                                                                    <RiDeleteBin6Line
                                                                                                        className={""}
                                                                                                        style={{fill: checkBtnPermission() === true ? "red" : "grey"}}/>
                                                                                                </div> :

                                                                                                <>
                                                                                                    <div
                                                                                                        onClick={() => {
                                                                                                            if (checkBtnPermission() === true) {
                                                                                                                handleEnableEditing(index)

                                                                                                            }
                                                                                                        }}
                                                                                                        className={('crossIcon ms-2') + (checkBtnPermission() === true ? "" : "disable-edit-reflection-pencil-btn")}>
                                                                                                        <HiPencil
                                                                                                            className={"edit-reflection-pencil-btn "}/>
                                                                                                    </div>


                                                                                                    <div
                                                                                                        className={('crossIcon ms-2') + (checkBtnPermission() === true ? "" : "cursor-default")}

                                                                                                        onClick={() => {
                                                                                                            if (checkBtnPermission() === true) {

                                                                                                                handleDeleteQuestion(index, parentId)
                                                                                                            }
                                                                                                        }}>
                                                                                                        <RiDeleteBin6Line
                                                                                                            className={""}
                                                                                                            style={{fill: checkBtnPermission() === true ? "red" : "grey"}}/>
                                                                                                    </div>


                                                                                                </>

                                                                                        }

                                                                                    </div>

                                                                                </div>
                                                                                {
                                                                                    reflectionSegments[parentId]?.questionsList[index].isTextLimitExceeded &&
                                                                                    <div
                                                                                        className={"question-length-exceeds-warning "}>Question
                                                                                        length cannot be greater than
                                                                                        512
                                                                                        characters</div>
                                                                                }
                                                                                {
                                                                                    question.type === QuestionType.NUMBER &&
                                                                                    <div
                                                                                        className={"number-range-value-outer"}>
                                                                                        <div
                                                                                            className={"mt-2 min-value-container"}>
                                                                                            <div
                                                                                                className={segmentType === "LINEAR" ? "max-value-heading ms-2" : "max-value-heading ms-2 cmn_font"}>Min
                                                                                                :
                                                                                            </div>
                                                                                            <input type={"number"}
                                                                                                   inputMode={"numeric"}
                                                                                                   name={"min"}
                                                                                                   value={getValueOrDefault(reflectionSegments[parentId]?.questionsList[index].number.min,"")}
                                                                                                   disabled={formType === "Edit" ? checkBtnPermission(question?.questionId) === false && !enableReflectionEditing[index] : checkBtnPermission() === false}

                                                                                                   onWheel={(e) => {
                                                                                                       document.activeElement.blur();
                                                                                                   }}
                                                                                                   onKeyPress={(e) => {
                                                                                                       const isValidKey = /^\d*$/.test(e.key);
                                                                                                       if (!isValidKey) {
                                                                                                           e.preventDefault();
                                                                                                       }
                                                                                                   }}
                                                                                                   onKeyDown={(e) => {
                                                                                                       if (e.key === '.' || e.key === "-") {
                                                                                                           e.preventDefault();
                                                                                                       }
                                                                                                   }}
                                                                                                   onChange={(e) => {
                                                                                                       if (checkBtnPermission() === true && e.target.value >= 0) {
                                                                                                           updateMinMaxNumber(e, index, parentId)
                                                                                                       }
                                                                                                   }}
                                                                                                   min={0}
                                                                                                   placeholder={"0"}
                                                                                                   className={segmentType === "LINEAR" ? "form-control range-value-input me-auto" : "form-control range-value-input me-auto cmn_input_size"}/>
                                                                                            <div
                                                                                                className={"me-2 good-bad-outer"}>
                                                                                                <div
                                                                                                    className={question.number.isMinLowestRank ? "color-data-high-risk" : "color-data-good"}/>
                                                                                                <select
                                                                                                    name={"isMinLowestRank"}
                                                                                                    value={question.number.isMinLowestRank}
                                                                                                    disabled={formType === "Edit" ? checkBtnPermission(question?.questionId) === false && !enableReflectionEditing[index] : checkBtnPermission() === false}
                                                                                                    className={"good-bad-dropdown"}
                                                                                                    onChange={(e) => {
                                                                                                        if (checkBtnPermission() === true) {
                                                                                                            updateMinMaxValue(e, index, parentId)
                                                                                                        }
                                                                                                    }}
                                                                                                >
                                                                                                    <option value={true}
                                                                                                    >{Marks.HIGH_RISK}</option>
                                                                                                    <option
                                                                                                        value={false}
                                                                                                    >{Marks.GOOD}</option>
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            className={"mt-2 max-value-container"}>
                                                                                            <div
                                                                                                className={segmentType === "LINEAR" ? "max-value-heading ms-2" : "max-value-heading ms-2 cmn_font"}>Max
                                                                                                :
                                                                                            </div>
                                                                                            <input type={"number"}
                                                                                                   inputMode={"numeric"}
                                                                                                   name={"max"}
                                                                                                   value={getValueOrDefault(question.number.max,"") }
                                                                                                   disabled={formType === "Edit" ? checkBtnPermission(question?.questionId) === false && !enableReflectionEditing[index] : checkBtnPermission() === false}

                                                                                                   onWheel={(e) => {
                                                                                                       document.activeElement.blur();
                                                                                                   }}
                                                                                                   onKeyPress={(e) => {
                                                                                                       const isValidKey = /^\d*$/.test(e.key);
                                                                                                       if (!isValidKey) {
                                                                                                           e.preventDefault();
                                                                                                       }
                                                                                                   }}
                                                                                                   onKeyDown={(e) => {
                                                                                                       if (e.key === '.' || e.key === "-") {
                                                                                                           e.preventDefault();
                                                                                                       }
                                                                                                   }}
                                                                                                   onChange={(e) => {
                                                                                                       if (checkBtnPermission() === true && e.target.value >= 0) {
                                                                                                           updateMinMaxNumber(e, index, parentId)
                                                                                                       }
                                                                                                   }} min={0}
                                                                                                   placeholder={"..."}
                                                                                                   className={segmentType === "LINEAR" ? "form-control range-value-input me-auto" : "form-control range-value-input me-auto cmn_input_size"}/>
                                                                                            <div
                                                                                                className={"me-2 good-bad-outer"}>
                                                                                                <div
                                                                                                    className={question.number.isMinLowestRank ? "color-data-good" : "color-data-high-risk"}/>
                                                                                                <select
                                                                                                    value={question.number.isMinLowestRank}
                                                                                                    name={"isMinLowestRank"}
                                                                                                    disabled={formType === "Edit" ? checkBtnPermission(question?.questionId) === false && !enableReflectionEditing[index] : checkBtnPermission() === false}
                                                                                                    className={"good-bad-dropdown"}
                                                                                                    onChange={(e) => {
                                                                                                        if (checkBtnPermission() === true) {
                                                                                                            updateMinMaxValue(e, index, parentId)

                                                                                                        }
                                                                                                    }}
                                                                                                >
                                                                                                    <option
                                                                                                        value={false}
                                                                                                        selected={!question.number.isMinLowestRank}>{Marks.HIGH_RISK}</option>
                                                                                                    <option value={true}
                                                                                                            selected={question.number.isMinLowestRank}>{Marks.GOOD}</option>
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    question.type === QuestionType.OPTION &&
                                                                                    <div
                                                                                        className={"options-list-container mt-2"}>
                                                                                        {
                                                                                            [...Array(reflectionSegments[parentId]?.questionsList[index].options.length)].map((x, i) => {

                                                                                                return (

                                                                                                    <>
                                                                                                        <div key={i}
                                                                                                             className={"option-select-container mt-2 position-relative"}>
                                                                                                            <div
                                                                                                                className={"option-container"}>

                                                                                                                <div
                                                                                                                    className={segmentType === "LINEAR" ? "option-heading " : "option-heading cmn_font"}>{"Option " + (i + 1)}
                                                                                                                    <input
                                                                                                                        type={"text"}
                                                                                                                        name={"optionText"}
                                                                                                                        disabled={formType === "Edit" ? checkBtnPermission(question?.questionId) === false && !enableReflectionEditing[index] : checkBtnPermission() === false}
                                                                                                                        onChange={(e) => {
                                                                                                                            if (checkBtnPermission() === true) {
                                                                                                                                if (e.target.value.length <= 512) {
                                                                                                                                    handleOptionChange(e, index, i, parentId)
                                                                                                                                } else if (e.target.value.length > 512) {
                                                                                                                                    setOptionTextLimitExceeds(index, i)
                                                                                                                                }

                                                                                                                            }
                                                                                                                        }}
                                                                                                                        value={reflectionSegments[parentId]?.questionsList[index].options[i].optionText}
                                                                                                                        className={segmentType === "LINEAR" ? "form-control option-input" : "form-control option-input cmn_input_size"}
                                                                                                                        placeholder={"Enter option here"}/>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    className={"risk-color-and-dropdown"}>
                                                                                                                    <input
                                                                                                                        placeholder={"Enter weight here..."}
                                                                                                                        onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                                                                                                        disabled={formType === "Edit" ? checkBtnPermission(question?.questionId) === false && !enableReflectionEditing[index] : checkBtnPermission() === false}
                                                                                                                        value={getValueOrDefault(reflectionSegments[parentId]?.questionsList[index].options[i].optionValue,"")}
                                                                                                                        className={segmentType === "LINEAR" ? "form-control " : "form-control cmn_input_size"}
                                                                                                                        type={"number"}
                                                                                                                        min={1}
                                                                                                                        name={"optionValue"}
                                                                                                                        onChange={(e) => {
                                                                                                                            if (checkBtnPermission() === true) {
                                                                                                                                handleOptionChange(e, index, i, parentId);
                                                                                                                            }
                                                                                                                        }}/>
                                                                                                                </div>

                                                                                                                {((formType === "Edit" || formType === "Update") && i > 2 && enableReflectionEditing[index]) &&
                                                                                                                    <div
                                                                                                                        className={"add-Icon-option"}>
                                                                                                                        <i onClick={(e) => {
                                                                                                                            if (checkBtnPermission() === true) {
                                                                                                                                handleRemoveOptions(e, index, i, parentId)
                                                                                                                            }
                                                                                                                        }}
                                                                                                                           className="fa fa-trash trash-icon"/>

                                                                                                                    </div>
                                                                                                                }
                                                                                                                {(formType === "Update" && i > 2 && !enableReflectionEditing[index]) &&
                                                                                                                    <div
                                                                                                                        className={"add-Icon-option"}>
                                                                                                                        <i onClick={(e) => {
                                                                                                                            if (checkBtnPermission() === true) {
                                                                                                                                handleRemoveOptions(e, index, i, parentId)
                                                                                                                            }
                                                                                                                        }}
                                                                                                                           className="fa fa-trash trash-icon"/>

                                                                                                                    </div>
                                                                                                                }

                                                                                                                {(formType === "Add" && i > 2 && !enableReflectionEditing[index]) &&
                                                                                                                    <div
                                                                                                                        className={"add-Icon-option"}>
                                                                                                                        <i onClick={(e) => {
                                                                                                                            if (checkBtnPermission() === true) {
                                                                                                                                handleRemoveOptions(e, index, i, parentId)
                                                                                                                            }
                                                                                                                        }}
                                                                                                                           className="fa fa-trash trash-icon"/>

                                                                                                                    </div>
                                                                                                                }


                                                                                                            </div>

                                                                                                            {
                                                                                                                reflectionSegments[parentId]?.questionsList[index].options[i].isTextLimitExceeded &&
                                                                                                                <div
                                                                                                                    className={"option-length-exceeds-warning"}>Option
                                                                                                                    length
                                                                                                                    cannot
                                                                                                                    exceed
                                                                                                                    512
                                                                                                                    characters</div>
                                                                                                            }

                                                                                                        </div>


                                                                                                        {((formType === "Add" || formType === "Update") && i === 2 && !enableReflectionEditing[index]) &&

                                                                                                            <div
                                                                                                                style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                                                                className={"add-Icon-button cmn_background_color"}>
                                                                                                                <i className={"fa fa-plus add-tag-icon"}/>
                                                                                                                <button
                                                                                                                    style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                                                                    className={"cmn_background_color"}
                                                                                                                    onClick={(e) => {
                                                                                                                        if (checkBtnPermission() === true) {
                                                                                                                            handleAddOptions(e, index, i, parentId)
                                                                                                                        }
                                                                                                                    }}>Add
                                                                                                                    More
                                                                                                                    Options...
                                                                                                                </button>

                                                                                                            </div>
                                                                                                        }
                                                                                                        {(formType === "Edit" && i === 2 && enableReflectionEditing[index]) &&

                                                                                                            <div
                                                                                                                className={"add-Icon-button"}>
                                                                                                                <i className={"fa fa-plus add-tag-icon"}/>
                                                                                                                <button
                                                                                                                    onClick={(e) => {
                                                                                                                        if (checkBtnPermission() === true) {
                                                                                                                            handleAddOptions(e, index, i, parentId)
                                                                                                                        }
                                                                                                                    }}>Add
                                                                                                                    More
                                                                                                                    Options...
                                                                                                                </button>

                                                                                                            </div>
                                                                                                        }

                                                                                                    </>


                                                                                                )
                                                                                            })
                                                                                        }


                                                                                    </div>
                                                                                }


                                                                                <div
                                                                                    className={"tag-outer mt-3 mb-2 material-icons"}>
                                                                                    {
                                                                                        reflectionSegments[parentId]?.questionsList[index].tags?.map((tag, tagIndex) => {
                                                                                            return (
                                                                                                <div className={"tags"}
                                                                                                     key={tagIndex}>
                                                                                                    <div
                                                                                                        className={"tag-text"}
                                                                                                        title={tag}>{trim(tag, 12)}</div>
                                                                                                    <div
                                                                                                        className={"tag-cross"}
                                                                                                        onClick={() => {
                                                                                                            if (checkBtnPermission() === true) {

                                                                                                                handleRemoveTag(index, tagIndex, parentId)
                                                                                                            }
                                                                                                        }}>
                                                                                                        <i className={"fa fa-times remove-tag-icon"}/>
                                                                                                    </div>
                                                                                                </div>)
                                                                                        })
                                                                                    }
                                                                                    <div className={"add-tag-outer"}>
                                                                                        <i style={{color: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                                           className={"fa fa-plus add-tag-icon"}/>
                                                                                        <input
                                                                                            value={tags?.[parentId]?.[index] || ""}
                                                                                            name={"tags"}
                                                                                            disabled={checkBtnPermission() === false}
                                                                                            onKeyDown={(e) => {
                                                                                                if (checkBtnPermission() === true) {
                                                                                                    handleKeyDown(e, index, parentId)
                                                                                                }
                                                                                            }}
                                                                                            onChange={(e) => {
                                                                                                checkBtnPermission() === true ? handleTagChange(e.target.value, index) : handleTagChange("", index)
                                                                                            }}
                                                                                            placeholder={"Add Question Tag"}
                                                                                            className={segmentType === "LINEAR" ? "tags-input-field" : " tags-input-field cmn_input_size"}
                                                                                            type={"text"}/>
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className={"tag-outer  mb-2 material-icons"}>
                                                                                    {
                                                                                        reflectionSegments[parentId]?.questionsList[index].goals?.map((goal, tagIndex) => {
                                                                                            return (
                                                                                                <div className={"goals"}
                                                                                                     key={tagIndex}>
                                                                                                    <div
                                                                                                        className={"tag-text"}
                                                                                                        title={goal}>{trim(goal, 12)}</div>
                                                                                                    <div
                                                                                                        className={"tag-cross"}
                                                                                                        onClick={() => {
                                                                                                            if (checkBtnPermission() === true) {

                                                                                                                handleRemoveGoals(index, tagIndex, parentId)
                                                                                                            }
                                                                                                        }}><i
                                                                                                        className={"fa fa-times remove-tag-icon"}/>
                                                                                                    </div>
                                                                                                </div>)
                                                                                        })
                                                                                    }
                                                                                    <div className={"add-tag-outer"}>
                                                                                        <i style={{color: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                                           className={"fa fa-plus add-tag-icon"}/>
                                                                                        <input
                                                                                            value={goals?.[parentId]?.[index] || ""}
                                                                                            name={"goals"}
                                                                                            onKeyDown={(e) => {
                                                                                                if (checkBtnPermission() === true) {
                                                                                                    handleKeyDown(e, index, parentId)
                                                                                                }
                                                                                            }}
                                                                                            disabled={checkBtnPermission() === false}

                                                                                            onChange={(e) => {
                                                                                                checkBtnPermission() === true ? handleGoalChange(e.target.value, index) : handleGoalChange("", index)
                                                                                            }}


                                                                                            placeholder={"Add Question Goal"}
                                                                                            className={segmentType === "LINEAR" ? "tags-input-field" : " tags-input-field cmn_input_size"}
                                                                                            type={"text"}/>
                                                                                    </div>
                                                                                </div>


                                                                            </Card.Body>
                                                                        </Card>
                                                                    </div>
                                                            }
                                                        </li>
                                                    )
                                                }
                                            </Draggable>
                                        ))
                                    }
                                    {
                                        reflectionSegments[parentId]?.questionsList && <div className={"add-ques mt-2"}>
                                            <button disabled={checkBtnPermission() === false}
                                                    onClick={() => {
                                                        if (checkBtnPermission() === true) {
                                                            handleAddQuestion(reflectionSegments[parentId]?.questionsList.length || 0, parentId)
                                                        }
                                                    }}
                                                    className="add-ques_btn cmn_fontStyle mediumFontSize "><i
                                                className="fa fa-plus"
                                                aria-hidden="true"/> Add
                                                question
                                            </button>
                                        </div>
                                    }
                                </>
                            }
                        </ul>
                    )
                }
            </Droppable>
        </DragDropContext>
    );
};
export default DraggableQuestions;