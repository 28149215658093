export let RoutesUtil = {

    admin: {
        path: "/:orgName/admin"
    },
    roles: {
        path: "/roles"
    },
    rolesDetail: {
        path: "/roles/:roleId"
    },
    login: {
        path: "/"
    },

    organizations: {
        path: "/organizations"
    },
    organizationDetails: {
        path: "/organizations/:orgId"
    },
    reportDetails: {
        path: "/report/:reportId"
    },
    signUp: {
        path: "/signup"
    },
    createPassword: {
        path: "/create-password/:encodedString"
    },
    publicSurveyAnswer: {
        path: "/survey-answer/:token"
    },
    signUpSuccess: {
        path: "/signUp-success"
    },
    subscribedSuccessfully: {
        path: "/subscription-success"
    },

    customReport: {
        path: "/custom-report"
    },

    updateOrgLevel: {
        path: "/assign-new-role/:encodedString"
    },


    forgetPassword: {
        path: "/forgetPassword"
    }
    ,
    customCriteria: {
        path: "/triggers"
    },
    setting: {
        path: "/setting"
    },
    surveyDetails: {
        path: "/surveyDetails"
    }
    ,
    surveyName: {
        path: "/surveyName"
    }
    ,
    newSurvey: {
        path: "/newSurvey"
    }
    ,


    result: {
        path: "/result"
    },

    details: {
        path: "/details",
    },

    members: {
        path: "/members",
    },
    answerReflection:{
        path:"/members/answerReflection/:memberId/:surveyId"
    },
    menteeAnswerReflection:{
        path:"/mentee/attempt/:memberId/:surveyId"
    },
    reflectionResult:{
        path:"/members/reflectionResult/:surveyId/:attemptSurveyId"
    },
    reflectionResultMentee:{
        path:"/mentee/reflectionResult/:surveyId/:attemptSurveyId"
    },
    reflectionResultTeam:{
        path:"/teams/:teamId/reflectionResult/:surveyId/:attemptSurveyId"
    },
    attempts:{
        path:"/attempts"
    },
    teams:{
        path:"/teams"
    },
    teamDetail:{
        path:"/teams/:teamId"
    },
    answerReflectionTeam:{
        path:"/teams/:teamId/answerReflection/:memberId/:surveyId"
    },

    reflections: {
        path: "/reflections",
    },
    memberDetails: {
        path: "/members/:userId",
    },

    reports: {
        path: "/reports",
    },
    subOrganizations: {
        path: "/subOrganizations",
    },
    subOrganizationsDetails: {
        path: "/subOrganizations/:subOrgId",
    },

    createSubOrganizations: {
        path: "/subOrganizations/create",
    },
    createOrganizations: {
        path: "/organizations/create",
    },
    creationReflection: {
        path: "/creationReflection"
    },
    updateReflection: {
        path: "/reflections/:surveyId"
    },
    assignedReflectionsMentee: {
        path: "/mentee/reflections"
    },
    savedReflection: {
        path: "/savedReflection"
    },
    plans: {
        path: "/plans"
    },
    paymentDetails: {
        path: "/paymentDetails/:encodedString"
    },


    pageNotFound: {
        path: "/*"
    }


}