import {Card, Form} from "react-bootstrap"
import './Setting.css'
import {useEffect, useState} from "react"
import ResetPassword from "../authentication/modal/ResetPassword"
import {
    encryptData,
    getDataFromLocalStorage,
    isNullOrEmpty,
    isUnauthorized, showToast,
    toString
} from "../../helpers/utils";
import {useDispatch, useSelector} from "react-redux";
import {
    createOrganisationPreference,
    getOrganisationPreferenceByUserId,
    getOrgById
} from "../../reduxStore/orgSlices/orgSlices";
import {toast} from "react-toastify";
import {
    cancelSubscription,
    getSubscriptionData,
    getUserById, refreshToken, resetGetAllUsersWithoutPaginationReducer,
    updateUserById
} from "../../reduxStore/usersSlices/userSlice";
import {setLoggedInUserName} from "../../reduxStore/globalKeysSlice/globalKeysSlice"
import {Loader, TailSpinLoader} from "../layout/Loader";
import {
    FirstNameRequired,
    FirstNameTooLong,
    LastNameTooLong,
    SubscriptionPlans
} from "../../helpers/constants";
import {Permissions, roleHasAccess, Roles} from "../../helpers/accessControl";
import {hasPermission} from "../../helpers/accessControl";
import {useNavigate} from "react-router-dom";
import jwtDecode from "jwt-decode";
import ConfirmationModel from "../commons/ConfirmationModel";
import ViewHistoryModal from "./ViewHistoryModal";
import UpgradePlanModal from "./UpgradePlanModal";
import previewIcon from '../../images/preview.png'
import UserInfo from "../layout/UserInfo";


const Setting = () => {

    const [showModal, setShowModal] = useState(false);
    const [showHistory, setShowHistory] = useState(false)
    const navigate = useNavigate();
    const users = useSelector(state => state.users)
    const [currentActivePlan, setCurrentActivePlan] = useState(null)
    const org = useSelector(state => state.org)
    const [orgName, setOrgName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [showConfirmationBox, setShowConfirmationBox] = useState(false)
    const [showUpgradePlanModal, setShowUpgradePlanModal] = useState(false)
    const [logo, setLogo] = useState(null)
    const [colorValue, setColorValue] = useState(null)
    const [lastName, setLastName] = useState("");
    const [loggedInUserInfo, setLoggedInUserInfo] = useState(getDataFromLocalStorage("loggedInUserInfo"));
    const dispatch = useDispatch();
    const [tokenSubscriptionStatus, setTokenSubscriptionStatus] = useState(jwtDecode(getDataFromLocalStorage("token")).subscriptionStatus)
    // const [userPlan, setUserPlan] = useState(jwtDecode(getDataFromLocalStorage("token")).planName)

    useEffect(() => {
        dispatch(getUserById(getDataFromLocalStorage("loggedInUserInfo").userId))
            .then(res => {

                if (isUnauthorized(res)) {
                    navigate("/")
                }
                if (res.meta.requestStatus === "fulfilled") {
                    setFirstName(res.payload.data.firstName);
                    setLastName(res.payload.data.lastName)
                }
            })
        if (roleHasAccess([Roles.ORG_ADMIN]) ) {
            dispatch(getOrgById(loggedInUserInfo?.orgId)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                if (res.meta.requestStatus === "fulfilled") {
                    setOrgName(res.payload.data.name)
                }
            })
        }
        dispatch(getOrganisationPreferenceByUserId(getDataFromLocalStorage("loggedInUserInfo").orgId))

    }, [])


    const getSubscriptionsData = () => {
        dispatch(getSubscriptionData()).then(res => {
            if (isUnauthorized(res)) {
                navigate("/")
            } else if (res.meta.requestStatus === "fulfilled") {
                let currentActivePlan = res.payload.data.filter(plans => plans.subscriptionStatus === SubscriptionPlans.SUBSCRIBED || plans.subscriptionStatus === SubscriptionPlans.TRIAL || plans.subscriptionStatus === SubscriptionPlans.CANCELED)
                setCurrentActivePlan(currentActivePlan[0])

            }
            setShowConfirmationBox(false)

        })
    }

    const handleCancelPlan = () => {
        const userId = jwtDecode(getDataFromLocalStorage("token")).userid
        dispatch(cancelSubscription(userId)).then(res => {
            if (isUnauthorized(res)) {
                navigate("/")
            } else if (res.meta.requestStatus === "fulfilled") {
                // getSubscriptionsData();
                dispatch(refreshToken({userId:userId})).then(res => {
                    if (isUnauthorized(res)) {
                        navigate("/")
                    } else if (res.meta.requestStatus === "fulfilled") {
                        localStorage.setItem('token', encryptData(res.payload.data));
                        const refreshTokenStatus = jwtDecode(res.payload.data).subscriptionStatus
                        setTokenSubscriptionStatus(refreshTokenStatus)
                        if (refreshTokenStatus === SubscriptionPlans.SUBSCRIBED || refreshTokenStatus === SubscriptionPlans.CANCELED) {
                            setShowConfirmationBox(false)
                        } else {
                            navigate("/plans")
                        }
                    }

                })
            }
        })

    }
    const handleOnCancel = (e) => {
        e.preventDefault();
        setFirstName(users.getUserByIdReducer?.data.firstName)
        setLastName(users.getUserByIdReducer?.data.lastName)
    }
    const handleOnSubmit = () => {
        //     Validate Form Data
        let message;
        if (isNullOrEmpty(firstName)) {
            message = FirstNameRequired
        } else if (firstName.length > 25) {
            message = FirstNameTooLong
        } else if (!isNullOrEmpty(lastName) && lastName.length > 25) {
            message = LastNameTooLong
        }
        if (message) {
            showToast(message,"error")
        } else {
            dispatch(updateUserById({
                userId: loggedInUserInfo.userId,
                firstName: firstName,
                lastName: lastName,
                orgId: jwtDecode(getDataFromLocalStorage("token"))?.orgId,
                enable: true,
                roles:[jwtDecode(getDataFromLocalStorage("token"))?.roles[0]]
            })).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                } else if (res.meta.requestStatus === "fulfilled") {
                    dispatch(resetGetAllUsersWithoutPaginationReducer())
                    dispatch(getUserById(getDataFromLocalStorage("loggedInUserInfo").userId)).then(res => {
                        if (isUnauthorized(res)) {
                            navigate("/")
                        }
                        if (res.meta.requestStatus === "fulfilled") {
                            localStorage.setItem("loggedInUserInfo", encryptData(res.payload.data))
                            setFirstName(res.payload.data.firstName);
                            setLastName(res.payload.data.lastName);
                            dispatch(setLoggedInUserName(res.payload.data.firstName + " " + res.payload.data.lastName));


                        }
                    })
                }
            })
        }
    }





    const handleFileChange = (e) => {

        if(e.target.files[0] !== undefined){
            const selectedFile = e.target.files[0];
            console.log()
            const fileName = selectedFile.name;
            const fileExtension = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
            if(fileExtension === "xlsx" || fileExtension === "csv" || fileExtension === "pdf"){
                return toast.error("only pics extension are allowed!", {toastId: "add-user-error"})
            }else{
                setLogo(e.target.files[0]);

            }
        }else{
            setLogo(null)
        }
    };

    useEffect( ()=>{
        if(org?.getOrganisationPreferenceByUserIdReducer?.data !== undefined){

            if(org?.getOrganisationPreferenceByUserIdReducer?.data?.logo !== undefined){
                const byteCharacters = atob(org?.getOrganisationPreferenceByUserIdReducer?.data?.logo);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'image/png' })
                setLogo(blob)
            }
            setColorValue(org?.getOrganisationPreferenceByUserIdReducer?.data?.colorName)
            localStorage.setItem("logo",org?.getOrganisationPreferenceByUserIdReducer?.data?.logo)
            localStorage.setItem("color",org?.getOrganisationPreferenceByUserIdReducer?.data?.colorName)
        }

    },[org?.getOrganisationPreferenceByUserIdReducer?.data])


    function handlePreferenceSubmit(e) {
        e.preventDefault()
        const data ={
            colorValue :colorValue,
            file:logo
        }
        dispatch(createOrganisationPreference(data)).then((res) => {
            if (isUnauthorized(res)) {
                navigate("/")
            }
            if (res.meta.requestStatus === "fulfilled") {
                setColorValue(null)
                setLogo(null)
                dispatch(getOrganisationPreferenceByUserId(res?.payload?.data?.orgId))
                    localStorage.setItem("logo",res?.payload?.data?.logo)
                    localStorage.setItem("color",res?.payload?.data?.colorName)



            }
        })

    }


    return (users.getUserByIdReducer.loading || org?.getOrgByIdDataReducer?.loading || users.getSubscriptionDataReducer?.loading) ?
        <Loader/> : (
            <>

                <section>
                    <div className="cmn_header">
                        <h2 className="mediumFontSize  org-heading m-0">Settings</h2> <UserInfo/>
                    </div>
                    <div className="setting_outer">
                        <div className="row main_content ">

                            <div className="col-md-12">

                                <div className={"row mt-3"}>

                                    <div className={ roleHasAccess([Roles.ORG_ADMIN ,Roles.SUB_ORG_ADMIN]) ? "col-xl-6" :"col-xl-12"}>
                                        <h5 className={"mediumFontSize"}>Profile Settings</h5>
                                        <Form className="detail_form">

                                            <div className={"row"}>

                                                <div className={"col-lg-6"}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label htmlFor="" className="mediumFontsize  ">First
                                                            Name </Form.Label>
                                                        <Form.Control disabled={!hasPermission([Permissions.USER_WRITE], true)} type="text" placeholder={"Enter first name"}
                                                                      value={firstName}
                                                                      onChange={(e) => {
                                                                          if (hasPermission([Permissions.USER_WRITE], true)) {
                                                                              setFirstName(e.target.value)
                                                                          }
                                                                      }}/>
                                                    </Form.Group>

                                                </div>
                                                <div className={"col-lg-6"}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label htmlFor="" className="mediumFontsize ">Last
                                                            Name </Form.Label>
                                                        <Form.Control disabled={!hasPermission([Permissions.USER_WRITE], true)} type="text" placeholder={"Enter last name"}
                                                                      value={lastName}
                                                                      onChange={(e) => {
                                                                          if (hasPermission([Permissions.USER_WRITE], true)) {
                                                                              setLastName(e.target.value)
                                                                          }
                                                                      }}/>
                                                    </Form.Group>
                                                </div>


                                            </div>


                                            <div className={"row mt-2"}>
                                                <div className={"col-lg-6"}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label htmlFor=""
                                                                    className="mediumFontsize ">Email </Form.Label>
                                                        <Form.Control type="email" disabled={true}
                                                                      value={loggedInUserInfo.email}/>
                                                    </Form.Group>
                                                </div>

                                                <div className={"col-lg-6"}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label htmlFor=""
                                                                    className="mediumFontsize ">Role </Form.Label>
                                                        <Form.Control type="text" disabled={true}
                                                                      value={toString(jwtDecode(getDataFromLocalStorage("token"))?.roles[0])}/>
                                                    </Form.Group>
                                                </div>

                                            </div>


                                            { roleHasAccess([Roles.ORG_ADMIN]) &&

                                                <div className={"row mt-2"}>
                                                    <div className={"col-lg-6"}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label htmlFor=""
                                                                        className="mediumFontsize cmn_fontStyle">Organization
                                                                Name </Form.Label>
                                                            <Form.Control disabled={true} value={orgName} type="text"/>
                                                        </Form.Group>
                                                    </div>

                                                </div>
                                            }









                                                <div className={"setting-user"}>

                                                    { hasPermission([Permissions.USER_WRITE], true) &&
                                                        <button style={{background : localStorage.getItem("color") === null || localStorage.getItem("color") === "undefined" ? "":localStorage.getItem("color")} } type="button"
                                                                className="reset_password_btn mediumFontSize cmn_background_color"
                                                                onClick={() => setShowModal(true)}>Reset Password
                                                        </button>}



                                                    <button style={{background : localStorage.getItem("color") === null || localStorage.getItem("color") === 'undefined' ? "":localStorage.getItem("color")} }
                                                            disabled={users.updateUserByIdReducer?.loading}
                                                            className="cmn_setting_btn cmn_background_color mediumFontSize"
                                                            onClick={handleOnSubmit}>Save
                                                        {users.updateUserByIdReducer?.loading && <TailSpinLoader/>}
                                                    </button>

                                                </div>





                                        </Form>
                                    </div>




                                    { roleHasAccess([Roles.ORG_ADMIN,Roles.SUB_ORG_ADMIN]) &&

                                        <div className={"col-xl-6"}>

                                            <h5 className={"mediumFontSize"}>Organization Preferences</h5>

                                            <form onSubmit={handlePreferenceSubmit} className={"orgPef"}>

                                                <div className={"org-pref"}>

                                                    <div className={"profile-choose"}>

                                                        <div className={"icon-stage"}>
                                                            <i onClick={()=>{
                                                                setLogo(null)
                                                            }} className="fa fa-close"/>
                                                        </div>



                                                        { org?.getOrganisationPreferenceByUserIdReducer?.data?.logo !== undefined && logo !== null ?
                                                            <img   src={"data:image/png;base64,"+org?.getOrganisationPreferenceByUserIdReducer?.data?.logo}/>

                                                            :
                                                            <img   src={previewIcon}/>
                                                        }



                                                    </div>

                                                    <div className="form-group mt-1 mb-3">

                                                        <div className={"moving-data"}>
                                                            <label className='userInfo_label mediumFontSize' form="inputAddress">Choose Logo</label>
                                                            <input type="file" accept={".jpg ,.png, .jpeg"}  onChange={handleFileChange} className="form-control userInfo_input"  />
                                                        </div>




                                                    </div>

                                                    <div className="form-group mt-3">
                                                        <label className='userInfo_label mediumFontSize' form="inputAddress">Choose Color</label>
                                                        <div className={"choose-color"}>

                                                            <div className={"icon2-stage"}>
                                                                <i onClick={()=>{
                                                                    setColorValue("")
                                                                }} className="fa fa-close"/>
                                                            </div>
                                                            <div className={"divided-inputs"}>


                                                                <input
                                                                    type="color"
                                                                    onChange={(e) => {
                                                                        setColorValue(e.target.value);
                                                                    }}
                                                                    className="form-control userInfo_input"
                                                                />


                                                                <div className={"second-color"} style={{background: org?.getOrganisationPreferenceByUserIdReducer?.data?.colorName === undefined || colorValue === "" ? "#e2e2e2" :org?.getOrganisationPreferenceByUserIdReducer?.data?.colorName}}>

                                                                </div>

                                                            </div>


                                                        </div>


                                                    </div>

                                                </div>

                                                <div className={"orgPef-button mb-3"}>

                                                    <button style={{background : localStorage.getItem("color") === null || localStorage.getItem("color") === 'undefined' ? "":localStorage.getItem("color")} } type="submit" disabled={ org?.createOrganisationPreferenceReducer?.loading === true}
                                                            className={colorValue === null && logo === null ? "modal_reset_btn cmn_background_color_disable cmn_modal_btn mediumFontSize " :" modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize "}>Submit
                                                        {org?.createOrganisationPreferenceReducer?.loading && (
                                                            <TailSpinLoader/>)}
                                                    </button>

                                                </div>




                                            </form>

                                        </div>

                                    }



                                </div>









                                        {/*<div className={"col-lg-6"}>*/}

                                        {/*    <Card className='question-card question-list'>*/}

                                        {/*        <Card.Body>*/}
                                        {/*            <div className={"plan-price-outer"}>*/}

                                        {/*                <div*/}
                                        {/*                    className={"plan-name"}>{toString(currentActivePlan?.planName)}</div>*/}
                                        {/*                <div className={"plan-price"}>*/}
                                        {/*                    <div className={"horizontal-divider"}/>*/}
                                        {/*                    <div className={"price-txt"}>*/}
                                        {/*                        {userPlan === PulsePlans.INDIVIDUAL.value*/}
                                        {/*                            ? "Free"*/}
                                        {/*                            : `$${currentActivePlan?.amount?.toFixed(2)} /${currentActivePlan?.interval === "month" ? "mo" : "yr"}`}*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*            <div className={"days-left-outer days-left-border mt-4"}>*/}
                                        {/*                <div className={"plan-interval-txt sub-txt"}>Subscription*/}
                                        {/*                    Status:*/}
                                        {/*                </div>*/}
                                        {/*                <div className={" ms-auto sub"}>*/}
                                        {/*                    <div*/}
                                        {/*                        className={"plan-interval plan-status "+(currentActivePlan?.subscriptionStatus===SubscriptionPlans.CANCELED?"red-color":"green-color")}> {toString(currentActivePlan?.subscriptionStatus)}</div>*/}
                                        {/*                </div>*/}

                                        {/*            </div>*/}
                                        {/*            {*/}
                                        {/*                userPlan !== PulsePlans.INDIVIDUAL.value &&*/}
                                        {/*                <div className={"days-left-border mt-3"}>*/}
                                        {/*                    <div className={"other-info-txt"}>Other information</div>*/}
                                        {/*                    <div className={"other-plan-info-outer"}>*/}
                                        {/*                        <div className={"plan-interval-txt font-light"}>Plan*/}
                                        {/*                            Interval:*/}
                                        {/*                        </div>*/}
                                        {/*                        <div*/}
                                        {/*                            className={"plan-interval ms-auto other-info-data"}> {toString(currentActivePlan?.interval)}</div>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div className={"other-plan-info-outer"}>*/}
                                        {/*                        <div className={"plan-interval-txt font-light"}>Days*/}
                                        {/*                            Left:*/}
                                        {/*                        </div>*/}
                                        {/*                        <div*/}
                                        {/*                            className={"plan-interval ms-auto other-info-data"}> {getDaysLeft(currentActivePlan?.createdDate, currentActivePlan?.expiredDate)}</div>*/}
                                        {/*                    </div>*/}
                                        {/*                    {*/}

                                        {/*                        tokenSubscriptionStatus === SubscriptionPlans.CANCELED &&*/}
                                        {/*                        <div className={"cancel-plan-date mt-1"}>Your plan will*/}
                                        {/*                            be*/}
                                        {/*                            cancelled*/}
                                        {/*                            on {formatDate(currentActivePlan?.expiredDate)}</div>*/}
                                        {/*                    }*/}
                                        {/*                    {*/}

                                        {/*                        tokenSubscriptionStatus === SubscriptionPlans.SUBSCRIBED &&*/}
                                        {/*                        <div className={"renew-plan-txt mt-1"}>Your plan will be*/}
                                        {/*                            renewed*/}
                                        {/*                            on {formatDate(currentActivePlan?.expiredDate)}</div>*/}
                                        {/*                    }*/}
                                        {/*                </div>*/}
                                        {/*            }*/}


                                        {/*            <div className={""}>*/}
                                        {/*                <div className={"cancel-plan-btn-outer mt-3"}>*/}
                                        {/*                    <button*/}
                                        {/*                        onClick={() => {*/}
                                        {/*                            setShowHistory(true)*/}
                                        {/*                        }}*/}
                                        {/*                        className={" cmn_setting_btn mediumFontSize plan-btn me-2 plans_btn"}>View*/}
                                        {/*                        History*/}
                                        {/*                    </button>*/}
                                        {/*                    {*/}

                                        {/*                    }*/}
                                        {/*                    {*/}
                                        {/*                        tokenSubscriptionStatus !== SubscriptionPlans.CANCELED && <>*/}
                                        {/*                            <button*/}
                                        {/*                                className={" cmn_setting_btn mediumFontSize plans_btn me-2"}*/}
                                        {/*                                onClick={() => {*/}
                                        {/*                                    setShowConfirmationBox(true)*/}
                                        {/*                                }}>Cancel Plan*/}
                                        {/*                            </button>*/}
                                        {/*                            {*/}
                                        {/*                                validatePlanVisibility(userPlan, PulsePlans.PULSE_PLUS.value) &&*/}
                                        {/*                                <button*/}
                                        {/*                                    className={" cmn_setting_btn mediumFontSize plans_btn "}*/}
                                        {/*                                    onClick={() => {*/}
                                        {/*                                        setShowUpgradePlanModal(true)*/}
                                        {/*                                    }}>Upgrade Plan*/}
                                        {/*                                </button>*/}
                                        {/*                            }*/}
                                        {/*                        </>*/}

                                        {/*                    }*/}


                                        {/*                </div>*/}

                                        {/*            </div>*/}
                                        {/*        </Card.Body>*/}
                                        {/*    </Card>*/}
                                        {/*</div>*/}




                                </div>


                            </div>

                        </div>

                </section>
                {
                    showConfirmationBox &&
                    <ConfirmationModel
                        isLoading={users.cancelSubscriptionReducer?.loading || users.refreshTokenReducer?.loading}
                        title={"Cancel Plan"}
                        btnText={"Proceed"}
                        bodyMessage={"Are you sure you want to cancel your subscription plan? Please note that cancelling your subscription will result in the loss of access to our premium features. If you proceed, your subscription will be terminated at the end of your current billing cycle."}
                        showUserAcknowledgementCheckBox={true}
                        userAcknowledgementCheckBoxText={"Yes, I Agree"}
                        action={handleCancelPlan} show={showConfirmationBox}
                        setShow={setShowConfirmationBox}/>
                }
                {
                    showHistory && <ViewHistoryModal show={showHistory} setShow={setShowHistory}/>
                }
                {
                    showUpgradePlanModal &&
                    <UpgradePlanModal show={showUpgradePlanModal} setShow={setShowUpgradePlanModal}/>
                }
                {showModal && <ResetPassword show={showModal} setShow={setShowModal}/>}

            </>
        )
}
export default Setting