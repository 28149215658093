import React, {useRef, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {getDataFromLocalStorage, isNullOrEmpty, isUnauthorized} from "../../helpers/utils";
import {hasPermission, Permissions, Roles} from "../../helpers/accessControl";
import Skeleton from "react-loading-skeleton";
import {
    getAllUsersWithoutPaginationAddReflectionBtn
} from "../../reduxStore/usersSlices/userSlice";
import {
    assignSurveyAttemptToMentee,
     getAllAttemptSurveyByUserId,
    getAllSurveysWithoutPaginationForAddReflectionBtn
} from "../../reduxStore/surveySlices/surveySlices";
import {useDispatch, useSelector} from "react-redux";
import jwtDecode from "jwt-decode";
import {PulsePlans, SurveyAttemptStatus} from "../../helpers/constants";
import {toString} from "../../helpers/utils";
import {TailSpinLoader} from "./Loader";

const AddReflectionWindow = ({setShow}) => {
    const divRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedMemberId, setSelectedMemberId] = useState("");
    const [selectedMember, setSelectedMember] = useState(null);
    const [assignReflectionToMentee, setAssignReflectionToMentee] = useState(null);
    const [selectedSurvey, setSelectedSurvey] = useState("");
    const [surveyData, setSurveyData] = useState(null);
    const users = useSelector(state => state.users)
    const survey = useSelector(state => state.survey)
    const [planName, setPlanName] = useState(jwtDecode(getDataFromLocalStorage("token")).planName)
    const color = localStorage.getItem("color")
    const {userId} = useParams();

    useEffect(() => {
        if (selectedMemberId !== null && selectedMemberId !== undefined && selectedMemberId) {
            const selectedMember = users?.getAllUsersWithoutPaginationAddReflectionBtnReducer?.data?.filter(c => c.uid === selectedMemberId)?.[0]
            setSelectedMember(selectedMember)
        }
    }, [selectedMemberId])

    useEffect(() => {
        if (assignReflectionToMentee !== null) {
            setAssignReflectionToMentee(null)
            dispatch(assignSurveyAttemptToMentee({
                surveyId: selectedSurvey,
                surveyeeId: selectedMemberId,
                surveyAttemptStatus: SurveyAttemptStatus.ASSIGNED
            })).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                } else if (res.meta.requestStatus === "fulfilled") {
                    setShow(false)
                    if (window.location.pathname.startsWith("/members/") && userId !== undefined && hasPermission([Permissions.ATTEMPT_REFLECTION_READ])) {
                        dispatch(getAllAttemptSurveyByUserId({
                            userId: userId, pageNumber: 1, sortBy: "createdAt", sortOrder: "desc"
                        })).then(res => {
                            if (isUnauthorized(res)) {
                                navigate("/")
                            }
                        })
                    }

                }
            })
        }
    }, [assignReflectionToMentee]);


    useEffect(() => {
        if (hasPermission([Permissions.USER_READ, Permissions.REFLECTION_READ], true)) {
            dispatch(getAllUsersWithoutPaginationAddReflectionBtn(
                {
                    roles: Object.keys(Roles)?.filter(role => role !== Roles.SYS_ADMIN && role !== Roles.ORG_ADMIN && role !== Roles.SUB_ORG_ADMIN && role !== Roles.ANONYMOUS_USER)
                }
            )).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
            dispatch(getAllSurveysWithoutPaginationForAddReflectionBtn()).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                } else if (res.meta.requestStatus === "fulfilled") {
                    planName === PulsePlans.INDIVIDUAL.value ? setSelectedSurvey(res.payload.data[0].surveyId) : setSurveyData(res.payload.data)
                }
            })
        }

    }, []);


    useEffect(() => {
        // Function to handle clicks outside of the div
        const handleClickOutside = event => {
            if ((divRef.current && !divRef.current.contains(event.target)) &&
                event.target.id !== "addReflectionModalButton") {
                // Action to perform when clicked outside of the div
                setShow(false)
            }
        };

        // Add event listener to the document when the component mounts
        document.addEventListener('click', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (<>
            {
                (survey?.getAllSurveysWithoutPaginationForAddReflectionBtnReducer?.loading || users?.getAllUsersWithoutPaginationAddReflectionBtnReducer?.loading) ?
                    <div ref={divRef} className={"add-reflection-window-outer "}>
                        <h2 className={"mediumFontSize add-reflection-txt mb-3"}>Add Reflection</h2>
                        <div className={"choose-mentee-txt mb-2 mt-3"}>Choose member</div>
                        <div><Skeleton height={40}/></div>
                        {
                            planName !== PulsePlans.INDIVIDUAL.value && <>
                                <div className={"mb-2 mt-3 choose-mentee-txt"}>Choose the reflection</div>
                                <div><Skeleton height={40}/></div>
                            </>
                        }

                        <button
                            style={{background: color !== null && color !== undefined ? color : ""}}
                            className={"cmn_background_color  add-reflection-submit-btn w-100 mt-4"}>Attempt now
                        </button>
                        <button
                            disabled={true}
                            style={{
                                background: color !== null && color !== undefined ? color : "",
                                opacity: ".7"
                            }}
                            className={"cmn_background_color  add-reflection-submit-btn w-100 mt-2"}>Assign to mentee
                        </button>
                    </div> :
                    <div ref={divRef} className={"add-reflection-window-outer "}>
                        <h2 className={"mediumFontSize add-reflection-txt mb-3"}>Add Reflection</h2>
                        <div className={"choose-mentee-txt mb-2 mt-3"}>Choose member</div>
                        <select value={selectedMemberId} onChange={(e) => {
                            setSelectedMemberId(e.target.value)
                        }} className="form-select add-reflection-window-select" aria-label="Default select example">
                            <option value="">Select</option>
                            {
                                users?.getAllUsersWithoutPaginationAddReflectionBtnReducer?.data?.map((memberData, index) => {
                                    return (
                                        <option key={index}
                                                value={memberData.uid}>{(memberData.firstName + " " + memberData.lastName) + " (" + (memberData?.roleObjects?.map(role => toString(role.name)).join(" / ")) + ")"}</option>
                                    );
                                })
                            }
                        </select>
                        {
                            planName !== PulsePlans.INDIVIDUAL.value && <>
                                <div className={"mb-2 mt-3 choose-mentee-txt"}>Choose the reflection</div>
                                <select value={selectedSurvey} onChange={(e) => {
                                    setSelectedSurvey(e.target.value)
                                }} className="form-select add-reflection-window-select" aria-label="Default select example">
                                    <option value="">Select</option>
                                    {
                                        surveyData?.map((survey, index) => {
                                            return (
                                                <option key={index} value={survey.surveyId}>{survey.name}</option>
                                            );
                                        })
                                    }
                                </select></>
                        }
                        <button
                            style={{background: color !== null && color !== undefined ? color : ""}}
                            onClick={() => {
                                if (!isNullOrEmpty(selectedMemberId) && !isNullOrEmpty(selectedSurvey)) {
                                    navigate(`/members/answerReflection/${selectedMemberId}/${selectedSurvey}`)
                                    setShow(false)
                                }
                            }} className={"cmn_background_color  add-reflection-submit-btn w-100 mt-4"}>Attempt now
                        </button>
                        <button
                            disabled={selectedMember === null || selectedMember?.roleObjects[0]?.name !== Roles.MENTEE || survey.assignSurveyAttemptToMenteeDataReducer?.loading}
                            style={{
                                background: color !== null && color !== undefined ? color : "",
                                opacity: selectedMember?.roleObjects[0]?.name !== Roles.MENTEE ? ".7" : "1"
                            }}
                            onClick={() => {
                                if (selectedMember !== null && selectedMember?.roleObjects[0]?.name === Roles.MENTEE && !survey.assignSurveyAttemptToMenteeDataReducer?.loading) {
                                    setAssignReflectionToMentee(new Date().getMilliseconds())
                                }
                            }} className={"cmn_background_color  add-reflection-submit-btn w-100 mt-2"}>Assign to
                            mentee {
                                survey.assignSurveyAttemptToMenteeDataReducer?.loading &&
                                <TailSpinLoader></TailSpinLoader>
                            }
                        </button>
                    </div>

            }
        </>

    )
        ;
}
export default AddReflectionWindow;