import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getAxiosConfig, serialize, showToast} from "../../helpers/utils";
import {toast} from "react-toastify";
import {createOrganizationPreference, SubOrgCreatedSuccessfully} from "../../helpers/constants";

export const getOrgById = createAsyncThunk('org/getOrgById', async (orgId, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/org/${orgId}`, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})
export const getAllOrg = createAsyncThunk('org/getAllOrg', async (data, thunkAPI) => {
    const serializedData = serialize({subOrgId: data?.subOrgId, orgId: data?.orgId});
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/org/search?${serializedData}`, data, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})
export const getAllSubOrgByOrgId = createAsyncThunk('org/getAllSubOrgByOrgId', async (data, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/org/find-all-sub_org/${data.orgId}`, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
});
export const createOrganisationPreference = createAsyncThunk('org/createOrganisationPreference', async (data, thunkAPI) => {

    const serializedData = serialize({colorName: data?.colorValue});
    const formData = new FormData();
        formData.append("file", data.file);
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/org-preference?${serializedData}`,
        headers: getAxiosConfig().headers,
        data: formData
    };
    return await axios.request(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return thunkAPI.rejectWithValue(error.response);
        });
})

export const getOrganisationPreferenceByUserId = createAsyncThunk('org/getOrganisationPreferenceByUserId', async (userId, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/org-preference/${userId}`, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})
export const getAllOrgAsList = createAsyncThunk('org/getAllOrgAsList', async (arg, thunkAPI) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/org/find-by-criteria`,{} ,getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})

export const getAllCustomerOrgAsList = createAsyncThunk('org/getAllCustomerOrgAsList', async (data, thunkAPI) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/org/find-by-criteria`, data,getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})

export const createSubOrg = createAsyncThunk('org/createSubOrg', async (data, thunkAPI) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/org/create_sub_org`, data, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})
export const EnableOrDisableOrg = createAsyncThunk('org/EnableOrDisableOrg', async (data, thunkAPI) => {
    const serializedData = serialize(data)
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/org/upsert-org?${serializedData}`, data, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})


const orgSlices = createSlice({
    name: "org",
    initialState: {
        getOrgByIdDataReducer: {loading: false},
        getOrganisationPreferenceByUserIdReducer: {loading: false},
        getAllOrgDataReducer: {loading: false},
        getAllSubOrgByOrgIdReducer: {loading: false},
        createOrganisationPreferenceReducer: {loading: false},
        create: {loading: false},
        createSubOrgReducer: {loading: false},
        getAllOrgAsListReducer: {loading: false},
        getAllCustomerOrgAsListReducer: {loading: false},
        enableOrDisableOrgReducer: {loading: false},
    },
    reducers: {
        resetGetOrgByIdDataReducer: (state) => {
            state.getOrgByIdDataReducer = {loading: false, data: null}
        },
        resetGetAllSubOrgByOrgIdReducer:(state)=>{
            state.getOrgByIdDataReducer = {loading: false}
        }
    },
    extraReducers: {
        // Get Org By Id
        [getOrgById.pending]: (state) => {
            state.getOrgByIdDataReducer = {loading: true};
        },
        [getOrgById.fulfilled]: (state, action) => {
            state.getOrgByIdDataReducer = {loading: false, data: action.payload.data};
        },
        [getOrgById.rejected]: (state, action) => {
            state.getOrgByIdDataReducer = {loading: false};
            showToast(action?.payload?.detailDesc || action?.payload?.statusText,"error")
        },

        [getOrganisationPreferenceByUserId.pending]: (state) => {
            state.getOrganisationPreferenceByUserIdReducer = {loading: true};
        },
        [getOrganisationPreferenceByUserId.fulfilled]: (state, action) => {
            state.getOrganisationPreferenceByUserIdReducer = {loading: false, data: action.payload.data};
        },
        [getOrganisationPreferenceByUserId.rejected]: (state, action) => {
            state.getOrganisationPreferenceByUserIdReducer = {loading: false};
            showToast(action?.payload?.detailDesc || action?.payload?.statusText,"error")
        },
        // Get All Org
        [getAllOrg.pending]: (state) => {
            state.getAllOrgDataReducer = {loading: true};
        },
        [getAllOrg.fulfilled]: (state, action) => {
            state.getAllOrgDataReducer = {loading: false, data: action.payload.data};
        },
        [getAllOrg.rejected]: (state, action) => {
            state.getAllOrgDataReducer = {loading: true};
            showToast(action?.payload?.detailDesc || action?.payload?.statusText,"error")
        },

        // Get All Org
        [getAllSubOrgByOrgId.pending]: (state) => {
            state.getAllSubOrgByOrgIdReducer = {loading: true};
        },
        [getAllSubOrgByOrgId.fulfilled]: (state, action) => {
            state.getAllSubOrgByOrgIdReducer = {loading: false, data: action.payload.data};
        },
        [getAllSubOrgByOrgId.rejected]: (state, action) => {
            state.getAllSubOrgByOrgIdReducer = {loading: true};
            showToast(action?.payload?.detailDesc || action?.payload?.statusText,"error")
        },


        // Create Sub Org
        [createSubOrg.pending]: (state) => {
            state.createSubOrgReducer = {loading: true};
        },
        [createSubOrg.fulfilled]: (state, action) => {
            state.createSubOrgReducer = {loading: false, data: action.payload.data};
            showToast(SubOrgCreatedSuccessfully,"success")
        },
        [createSubOrg.rejected]: (state, action) => {
            state.createSubOrgReducer = {loading: false};
            showToast(action?.payload?.detailDesc || action?.payload?.statusText,"error")
        },
        [createOrganisationPreference.pending]: (state) => {
            state.createOrganisationPreferenceReducer = {loading: true};
        },
        [createOrganisationPreference.fulfilled]: (state, action) => {
            state.createOrganisationPreferenceReducer = {loading: false, data: action.payload.data};
            showToast(createOrganizationPreference,"success")
        },
        [createOrganisationPreference.rejected]: (state, action) => {
            state.createOrganisationPreferenceReducer = {loading: false};
            showToast(action?.payload?.detailDesc || action?.payload?.statusText,"error")
        },
        // Update Org Level
        [getAllOrgAsList.pending]: (state) => {
            state.getAllOrgAsListReducer = {loading: true};
        },
        [getAllOrgAsList.fulfilled]: (state, action) => {
            state.getAllOrgAsListReducer = {loading: false, data: action.payload.data};
            showToast(action.payload.data || action?.payload?.statusText,"success")
        },
        [getAllOrgAsList.rejected]: (state, action) => {
            state.getAllOrgAsListReducer = {loading: false};
            showToast(action?.payload?.detailDesc || action?.payload?.statusText,"error")
        },

        // get all Org wrt org level
        [getAllCustomerOrgAsList.pending]: (state) => {
            state.getAllCustomerOrgAsListReducer = {loading: true};
        },
        [getAllCustomerOrgAsList.fulfilled]: (state, action) => {
            state.getAllCustomerOrgAsListReducer = {loading: false, data: action.payload.data};
            showToast(action.payload.data || action?.payload?.statusText,"success")
        },
        [getAllCustomerOrgAsList.rejected]: (state, action) => {
            state.getAllCustomerOrgAsListReducer = {loading: false};
            showToast(action?.payload?.detailDesc || action?.payload?.statusText,"error")
        },


        // Update Org Level
        [EnableOrDisableOrg.pending]: (state) => {
            state.enableOrDisableOrgReducer = {loading: true};
        },
        [EnableOrDisableOrg.fulfilled]: (state, action) => {
            state.enableOrDisableOrgReducer = {loading: false, data: action.payload.data};
            showToast(action.payload.data || action?.payload?.statusText,"success")
        },
        [EnableOrDisableOrg.rejected]: (state, action) => {
            state.enableOrDisableOrgReducer = {loading: false};
            showToast(action?.payload?.detailDesc || action?.payload?.statusText,"error")
        },

    }
})

export const {resetGetOrgByIdDataReducer,resetGetAllSubOrgByOrgIdReducer,} = orgSlices.actions

export default orgSlices.reducer;
