import Modal from "react-bootstrap/Modal";
import "./SurveyAttempt.css"
import {RxCross2} from "react-icons/rx";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {hexToRgb, Survey, SurveyAttemptStatus} from "../../../helpers/constants";
import {
    assignSurveyAttemptToMentee,
    getAllAttemptSurveyByUserId,
    searchSurveys
} from "../../../reduxStore/surveySlices/surveySlices";
import {
    formatDate,
    isNullOrEmpty,
    isUnauthorized,
    tableCustomStyling,
    TableSkeletonLoading, trim
} from "../../../helpers/utils";
import Pagination from "../../commons/Pagination";
import DataTable from "react-data-table-component";
import {hasPermission,Permissions} from "../../../helpers/accessControl";
import {IoIosClose} from "react-icons/io";
import {TailSpinLoader} from "../../layout/Loader";
import {Roles} from "../../../helpers/accessControl";


const SurveyAttempt = ({show, setShow, memberId,memberDetails}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [applySearch,setApplySearch]=useState(false)
    const [timer, setTimer] = useState(null)
    const [selectedSurveyId, setSelectedSurveyId] = useState("");
    const [assignReflectionToMentee, setAssignReflectionToMentee] = useState(null);

    const [pageNumber, setPageNumber] = useState(1);
    const [sortBy,setSortBy]=useState("createdAt");
    const [sortOrder,setSortOrder]=useState("desc");

    const surveys = useSelector(state => state.survey)

    const [searchData, setSearchData] = useState({
        surveyName: "",
        tag: "",
        goal: "",
        page: "1",
        status: Survey.SURVEY_STATUS_PUBLISHED,
        enable: true,
        sortBy:sortBy,
        sortOrder:sortOrder
    })
    const columns = [
        {
            name: "Reflection",
            selector: row => row.name,
            sortable: true,
            sortField: 'name',
            style: {
                fontFamily: 'General Sans Medium',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "-0.03em",
                color: "#171919",
            },
            cell: row => <div title={row.name} onClick={() => {
                setSelectedSurveyId(row.surveyId)
            }} className={"wrap-text"}>{trim(row.name, 40)}</div>

        },
        {
            name: "Date",
            selector: row => row.createdAt,
            sortField: 'createdAt',
            cell: row =>  <div>{(formatDate(row.createdAt))}</div>,
            sortable: true,
            style: {
                opacity: ".7"
            },
            width: "120px"
        }]
    const conditionalRowStyle = [
        {
            when: row => row.surveyId === selectedSurveyId,
            style: {
                backgroundColor: (localStorage.getItem("color") === null || localStorage.getItem("color") === undefined || localStorage.getItem("color") === 'undefined') ? '#f4956c': hexToRgb(localStorage.getItem("color"),0.1),

            },
        },
    ]

    const handleClose = () => setShow(false);
    const handleSort =  (column, sortDirection,) => {
        setSortBy(column.sortField)
        setSortOrder(sortDirection)
    };





    console.log("memberDEtaials===>",memberDetails?.roles[0]?.name===Roles.MENTEE)
    console.log("selectedSurveyId===>",selectedSurveyId)

    const handleOnSubmit = (e) => {
        e.preventDefault();
        dispatch(searchSurveys(searchData)).then(res => {
            if (isUnauthorized(res)) {
                navigate("/")
            }
        })
    }

    const handleOnChange = (e) => {
        setSearchData({...searchData, [e.target.name]: e.target.value})
    }

    useEffect(() => {
        if(applySearch){
            if (timer) {
                clearTimeout(timer)
            }
            setTimer(setTimeout(() => {
                callApiFunction()
            }, 1000))
        }

    }, [searchData])

    const callApiFunction = () => {
        const updatedSearchData = {...searchData, page: pageNumber,sortOrder:sortOrder,sortBy:sortBy}
        dispatch(searchSurveys(updatedSearchData)).then(res => {
            if (isUnauthorized(res)) {
                navigate("/")
            }
        });


    };

    useEffect(() => {
        callApiFunction();
        setApplySearch(true)
    }, [pageNumber,sortOrder,sortBy])
    useEffect(()=>{
        if(assignReflectionToMentee!==null){
            setAssignReflectionToMentee(null)
            dispatch(assignSurveyAttemptToMentee({
                surveyId: selectedSurveyId,
                surveyeeId: memberId,
                surveyAttemptStatus: SurveyAttemptStatus.ASSIGNED
            })).then(res=>{
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                if(res.meta.requestStatus==="fulfilled"){
                    setShow(false)
                    dispatch(getAllAttemptSurveyByUserId({
                        userId: memberId, pageNumber: 1, sortBy: "createdAt", sortOrder: "desc"
                    })).then(res => {
                        if (isUnauthorized(res)) {
                            navigate("/")
                        }
                    })

                }
            })
        }
    },[assignReflectionToMentee])

    return (
        <>
            <Modal show={show} centered>
                <Modal.Header className={"d-flex"}>
                    <Modal.Title className='add_member_heading mediumFontSize'>Reflections</Modal.Title>
                    <IoIosClose className={"close_btn"} onClick={handleClose} size={24}/>
                </Modal.Header>


                <Modal.Body className="modal_outer">

                    <div className={"attempt-survey-search-box search-box text-center"}>
                        <form className={"search-box-form"} onSubmit={handleOnSubmit}>
                            <input type={"text"} className={"form-control search-box-input-name height"}
                                   name={"surveyName"}
                                   onChange={handleOnChange}
                                   value={searchData.surveyName}
                                   placeholder={"Name..."}/>
                            <div className={"cross-btn-outer"}><RxCross2
                                className={"survey-search-clr-btn cursor-pointer"} onClick={() => {
                                setSearchData({...searchData, surveyName: ""})
                            }}/></div>

                            <input type={"text"} className={"form-control search-box-input-tag height"}
                                   name={"tag"}
                                   onChange={handleOnChange}
                                   value={searchData.tag}
                                   placeholder={"Tag..."}/>
                            <div className={"cross-btn-outer"}><RxCross2
                                className={"survey-search-clr-btn cursor-pointer"} onClick={() => {
                                setSearchData({...searchData, tag: ""})
                            }}/></div>
                            <input type={"text"} className={"form-control search-box-input-goal search-box-input-goal-modal height"}
                                   placeholder={"Goal..."}
                                   value={searchData.goal}
                                   onChange={handleOnChange}
                                   name={"goal"}/>
                            <div className={"cross-btn-outer"}><RxCross2
                                className={"survey-search-clr-btn cursor-pointer"} onClick={() => {
                                setSearchData({...searchData, goal: ""})
                            }}/></div>
                        </form>
                    </div>
                    <div className={"attempt-reflection-table-outer mt-3"}>
                        <DataTable className={" "+(surveys?.getSearchSurveyDataReducer?.data?.totalPages > 1 ?" table_design2":" table_design1")}
                                   noDataComponent={<div className={"lightFontSize cmn_fontStyle mt-2 mb-2"}>No
                                       Reflection Found</div>}
                                   persistTableHead={true} responsive={true}
                                   progressPending={surveys.getSearchSurveyDataReducer?.loading}
                                   progressComponent={<div
                                       className={"w-100 attempted-reflection-skeleton-loader-modal"}>
                                       <TableSkeletonLoading columns={2} rows={5}/></div>}
                                   customStyles={{
                                       ...tableCustomStyling, rows: {
                                           ...tableCustomStyling.rows, style: {
                                               ...tableCustomStyling.rows.style, '&:hover': {
                                                   cursor: 'pointer'
                                               }
                                           }
                                       }
                                   }
                                   }
                                   onRowClicked={(row) => {
                                       setSelectedSurveyId(row.surveyId)
                                   }}
                                   columns={columns}
                                   data={surveys?.getSearchSurveyDataReducer?.data?.data}
                                   conditionalRowStyles={conditionalRowStyle}
                                   onSort={handleSort} sortServer={true}
                        />
                        {
                            !surveys.getSearchSurveyDataReducer?.loading && surveys?.getSearchSurveyDataReducer?.data && surveys?.getSearchSurveyDataReducer?.data?.totalPages > 1 &&
                            <Pagination totalPages={surveys?.getSearchSurveyDataReducer?.data?.totalPages}
                                        pageNumber={surveys?.getSearchSurveyDataReducer?.data.pageNumber}
                                        setPageNumber={setPageNumber}/>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal_footer">
                    <button className="modal_cancel_btn cmn_modal_btn mediumFontSize" onClick={handleClose}>Cancel
                    </button>
                    {
                        hasPermission([Permissions.REFLECTION_READ,Permissions.ATTEMPT_REFLECTION_READ, Permissions.ATTEMPT_REFLECTION_CREATE], true) &&
                        <button style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color"):""} } type="submit"
                                className="modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize"
                                onClick={() => {
                                    if (!isNullOrEmpty(selectedSurveyId)) {
                                        navigate(`/members/answerReflection/${memberId}/${selectedSurveyId}`)
                                    }
                                }}
                        >Attempt now

                        </button>
                    }
                    {
                        hasPermission([ Permissions.ATTEMPT_REFLECTION_CREATE], true) &&
                        <button style={{background : (localStorage.getItem("color") !== null && localStorage.getItem("color") !== undefined) ? localStorage.getItem("color"):""} } type="submit"
                                disabled={isNullOrEmpty(selectedSurveyId) || memberDetails?.roles[0]?.name!==Roles.MENTEE}
                                className={"modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize "+(memberDetails?.roles[0]?.name!==Roles.MENTEE ?" opacity7":" ") }
                                onClick={() => {
                                    if (!isNullOrEmpty(selectedSurveyId) && memberDetails?.roles[0]?.name===Roles.MENTEE) {
                                        setAssignReflectionToMentee(new Date().getMilliseconds())
                                    }
                                }}
                        >Assign To Mentee
                            {
                                surveys.assignSurveyAttemptToMenteeDataReducer?.loading &&
                                <TailSpinLoader></TailSpinLoader>
                            }
                        </button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default SurveyAttempt;