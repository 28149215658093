import {useLocation, useNavigate, useParams} from "react-router-dom";
import Surveys from "../surveys/Surveys";
import "../subOrganizations/SubOrganizations.css"
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Member from "../members/Member";
import jwtDecode from "jwt-decode";
import {getDataFromLocalStorage, isUnauthorized} from "../../helpers/utils";
import SubOrganizations from "./SubOrganizations";
import {useState, useEffect, useRef} from "react";
import {getOrgById, resetGetOrgByIdDataReducer} from "../../reduxStore/orgSlices/orgSlices";
import {resetGetUserByIdReducer} from "../../reduxStore/usersSlices/userSlice";
import {useDispatch, useSelector} from "react-redux";
import Skeleton from "react-loading-skeleton";
import CustomReport from "../customReport/CustomReport";
import Attempts from "../attempts/Attempts";
import {hexToRgb} from "../../helpers/constants";

const SubOrganizationDetails = ({orgId = null}) => {

    const navigate = useNavigate();
    const {subOrgId} = useParams();
    const {pathname} = useLocation();
    const dispatch = useDispatch();
    const [orgName, setOrgName] = useState(null)
    const [isCreateSubOrg, setIsCreateSubOrg] = useState(pathname.startsWith("/subOrganizations"))
    const org = useSelector(state => state.org)
    const [activeTabIndex, setActiveTabIndex] = useState(0)


    useEffect(() => {

        if (subOrgId !== undefined) {
            dispatch(getOrgById(subOrgId)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                if (res.meta.requestStatus === "fulfilled") {
                    setOrgName(res.payload?.data?.name)
                    // dispatch(getUserById(res.payload.data.ownerUserId)).then(res => {
                    //     if (isUnauthorized(res)) {
                    //         navigate("/")
                    //     }
                    // })
                }
            })
        }


        return () => {
            dispatch(resetGetOrgByIdDataReducer())
            dispatch(resetGetUserByIdReducer())
        }
    }, [])

    const handleSelect = (activeIndex) => {
        setActiveTabIndex(activeIndex)
    }

    return (
        <>
            <div className={orgId === null ? "row main_content box_design1" : ""}>
                {
                    orgId === null &&

                    <>
                        <div className="sub-organization-details mt-2 mb-3">

                            <div
                                style={{color: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                className={"sub-org-data mediumFontSize "} onClick={() => {
                                navigate(-1)
                            }}>
                                <span className={"sub-org-back-btn "} style={{background: (localStorage.getItem("color") !== "undefined" && localStorage.getItem("color") !== null && localStorage.getItem("color") !== undefined) ? localStorage.getItem("color") : "#fc6620"}}>
                                    <i className="fa fa-solid fa-arrow-left"/>&nbsp;&nbsp;
                                </span>


                                {org.getOrgByIdDataReducer.loading ?

                                    <>
                                        <Skeleton width={120} height={20}/>
                                    </>

                                    :
                                    <>
                                        {orgName === null || orgName === undefined ? "" : orgName}
                                    </>

                                }

                            </div>

                        </div>

                    </>

                }
                <div className={"tabs-container px-0"}>
                    <Tabs className={"tabs-outer"} onSelect={handleSelect}>
                        <TabList>
                            <Tab
                                style={activeTabIndex === 0 ? {backgroundColor: (localStorage.getItem("color") !== "undefined" && localStorage.getItem("color") !== undefined && localStorage.getItem("color") !== null ) ? localStorage.getItem("color") : "#fc6620"} : {}}>Members</Tab>
                            <Tab
                                style={activeTabIndex === 1 ? {backgroundColor: (localStorage.getItem("color") !== "undefined" && localStorage.getItem("color") !== undefined && localStorage.getItem("color") !== null ) ? localStorage.getItem("color") : "#fc6620"} : {}}>Reflections</Tab>
                            <Tab
                                style={activeTabIndex === 2 ? {backgroundColor: (localStorage.getItem("color") !== "undefined" && localStorage.getItem("color") !== undefined && localStorage.getItem("color") !== null ) ? localStorage.getItem("color") : "#fc6620"} : {}}>Attempts</Tab>
                            <Tab
                                style={activeTabIndex === 3 ? {backgroundColor: (localStorage.getItem("color") !== "undefined" && localStorage.getItem("color") !== undefined && localStorage.getItem("color") !== null ) ? localStorage.getItem("color") : "#fc6620"} : {}}>Reports</Tab>
                            {((jwtDecode(getDataFromLocalStorage("token"))?.orgLevel !== "CUSTOMER") && !isCreateSubOrg) &&
                                <Tab
                                    style={activeTabIndex === 4 ? {backgroundColor:(localStorage.getItem("color") !== "undefined" && localStorage.getItem("color") !== undefined && localStorage.getItem("color") !== null ) ? localStorage.getItem("color") : "#fc6620"} : {}}>Sub-Organizations</Tab>
                            }

                        </TabList>


                        <TabPanel>
                            <div style={{borderTop: `2px solid ${(localStorage.getItem("color") !== "undefined" && localStorage.getItem("color") !== undefined && localStorage.getItem("color") !== null ) ? localStorage.getItem("color") : "#fc6620"}`}}>
                                <Member isSubOrg={true} subOrgId={orgId === null ? subOrgId : orgId}
                                        isChildSubOrg={true}/>
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div style={{borderTop: `2px solid ${(localStorage.getItem("color") !== "undefined" && localStorage.getItem("color") !== undefined && localStorage.getItem("color") !== null ) ? localStorage.getItem("color") : "#fc6620"}`}}>
                                <Surveys isSubOrg={true} subOrgId={orgId === null ? subOrgId : orgId}/>
                            </div>

                        </TabPanel>

                        <TabPanel>
                            <div style={{borderTop: `2px solid ${(localStorage.getItem("color") !== "undefined" && localStorage.getItem("color") !== undefined && localStorage.getItem("color") !== null ) ? localStorage.getItem("color") : "#fc6620"}`}}>
                                <Attempts isSubOrg={true} subOrgId={orgId === null ? subOrgId : orgId}/>
                            </div>

                        </TabPanel>

                        <TabPanel>
                            <div style={{borderTop: `2px solid ${(localStorage.getItem("color") !== "undefined" && localStorage.getItem("color") !== undefined && localStorage.getItem("color") !== null ) ? localStorage.getItem("color") : "#fc6620"}`}}>
                                <CustomReport isSubOrg={true} subOrgId={orgId === null ? subOrgId : orgId}/>
                            </div>

                        </TabPanel>

                        <TabPanel>
                            <div style={{borderTop: `2px solid ${(localStorage.getItem("color") !== "undefined" && localStorage.getItem("color") !== undefined && localStorage.getItem("color") !== null ) ? localStorage.getItem("color") : "#fc6620"}`}}>
                                <SubOrganizations isSubOrg={true} subOrgId={orgId === null ? subOrgId : orgId}/>
                            </div>

                        </TabPanel>

                    </Tabs>
                </div>
            </div>


        </>
    )
}
export default SubOrganizationDetails;
